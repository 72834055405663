import React from "react";
import { Store } from "./store";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CardBuilder, MedicalRecordDetailsCards } from "~/components";
import { Button, Flex} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

export const DetailsView: React.FC = observer(() => {
	const pageStrings = strings.pages.patients.details;
	const { authStore } = useGlobalStore();

	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;

	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || "", isAdmin));


	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};
	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 3, sm: 16 }}
		>
			{store.modelItem && (
				<CardBuilder
					title={pageStrings.title}
					cards={[
						{
							content:  <MedicalRecordDetailsCards.FirstCard medicalRecord={store.modelItem} isAdmin={isAdmin} />,
						},
						{
							content: <MedicalRecordDetailsCards.SecondCard medicalRecord={store.modelItem} />,
						},
						{
							content: <MedicalRecordDetailsCards.ThirdCard medicalRecord={store.modelItem} />,
						},
						{
							content: <MedicalRecordDetailsCards.FourthCard medicalRecord={store.modelItem} />,
						},
						{
							content: <MedicalRecordDetailsCards.FifthCard medicalRecord={store.modelItem} />,
						},
					]}

				/>
			)}
			<Button
				onClick={() => onSuccess()}
				w={{
					base: "50%",
					md: "15%",
				}}
				my={5}
				variant="outline"

			>
				{strings.common.buttons.backButton}
			</Button>
		</Flex>
	);
});
