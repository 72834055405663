import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	AdminHome,
	MedicalRecords,
	MultidisciplinaryVisit,
	SafetyHuddle,
} from "~/pages";
import { useAuthAdminEmployeeRoute } from "~/hooks/useAuthAdminEmployeeRoute";
import { TableCellMedicalRecord, TableCellMultidisciplinary } from "~/components";
import api from "~/resources/api";

const EmployeeRoutes = observer(() => {
	useAuthAdminEmployeeRoute();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<AdminHome />}
				/>

				<Route path="medicalRecords">

					<Route
						index
						element={
							<MedicalRecords.TableView
								TableCell={TableCellMedicalRecord}
								method={api.getAllMedicalRecordsByFilterOptionsForNonAdmin}
								hasDetails
								hasEdit
							/>
						}
					/>
					<Route
						path="details/:id"
						element={<MedicalRecords.DetailsView />}
					/>
					<Route
						path="create"
						element={<MedicalRecords.Create />}
					/>
					<Route
						path="edit/:id"
						element={<MedicalRecords.Edit />}
					/>
				</Route>

				<Route path="safetyHuddles">
					<Route
						index
						element={<SafetyHuddle.TableView />}
					/>
					<Route
						path="create"
						element={<SafetyHuddle.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<SafetyHuddle.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<SafetyHuddle.DetailsView />}
					/>
				</Route>

				<Route path="multidisciplinaryVisit">
					<Route
						index
						element={
							<MultidisciplinaryVisit.TableView
								method={api.getAllMultidisciplinaryVisitByFilterOptionsForNonAdmin}
								TableCell={TableCellMultidisciplinary}
								hasEdit
								hasDetails
							/>
						}
					/>
					<Route
						path="edit/:id"
						element={<MultidisciplinaryVisit.Edit />}
					/>
					<Route
						path="details/:id"
						element={<MultidisciplinaryVisit.DetailsView />}
					/>
				</Route>

			</Routes>
		</MainLayout>
	);
});

export default EmployeeRoutes;
