import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import FormSafetyHuddle from "../CreateOrEdit/shelves/formSafetyHuddle";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.SafetyHuddle>;
	public loader = new LoaderShelf();

	public formSafetyHuddle = new FormSafetyHuddle();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, api.getSafetyHuddleById,
			{
				onFetchError,
			},
		) ;

		if (id) {
			this.fetchModelShelf.fetchModel();
		}
	}
}
