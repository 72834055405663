import { makeAutoObservable } from "mobx";
import { AttributeShelf, FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";
import { onFetchError } from "~/resources/fetchError";

export default class Store {
	public fetchModelShelf: FetchModelShelf<api.MultidisciplinaryVisit | api.MultidisciplinaryVisitWithoutPatient>;

	public multidisciplinaryVisit = new MultidisciplinaryVisitFormShelf();
	public loader = new LoaderShelf();

	public id = new AttributeShelf<string>("");
	public isAdmin = new AttributeShelf(false);

	constructor(id: string, isAdmin?: boolean) {
		makeAutoObservable(this);
		this.id.setValue(id);
		this.isAdmin.setValue(isAdmin || false);
		this.fetchModelShelf = new FetchModelShelf(
			this.id.value,
			() => this.getMultidisciplinary(id, isAdmin),
			{
				onFetchError,
				onAfterFetch: this.multidisciplinaryVisit.setInitValues,
			},
		);

		if (id) {
			this.fetchModelShelf.fetchModel();
		}
	}

	public editMultidisciplinaryVisit = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.multidisciplinaryVisit.createEditMultidisciplinaryVisit();

			if (this.isAdmin.value) {
				await api.editMultidisciplinaryVisit(this.id.value, data);
			} else {
				await api.editMultidisciplinaryVisitForNonAdmin(this.id.value, {...data});
			}

			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.multidisciplinaryVisit.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};


	public getMultidisciplinary = (id: string, isAdmin?: boolean) => {
		if (isAdmin) {
			return api.getMultidisciplinaryVisitById(id);
		}
		return api.getMultidisciplinaryVisitByIdForNonAdmin(id);
	};

}

