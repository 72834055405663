import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Grid, GridProps, Text, TextProps } from "@chakra-ui/react";
import { DetailsRow } from "~/components";
import { IDetailsProps } from "~/components/DetailsRow";

interface IProps {
	detailsRows: IDetailsProps[];
	label?: string;
	description?: string;
	labelProps?: TextProps;
	gridProps?: GridProps;
}

export const DescriptionDetails: React.FC<IProps> = observer((props) => {
	const { detailsRows,  label, description, labelProps, gridProps } = props;

	return (
		<Box>
			<Box w="100%">
				<Text
					px={1}
					color="primary.500"
					fontSize="sm"
					fontWeight="bold"
					{...labelProps}
				>
					{label}
				</Text>
			</Box>
			<Box
				w="100%"
			>
				<Text
					px={1}
					color="gray.500"
					fontWeight="bold"
					maxWidth="100%"
					textAlign={{ base: "center", md: "left" }}
				>
					{description}
				</Text>
			</Box>
			<Grid
				templateColumns={{
					base: "minmax(250px, 480px)",
					md: "repeat(2, minmax(150px, 350px))",
					lg: "repeat(2, minmax(150px, 450px))",
					xl: "repeat(3, minmax(100px, 470px))",
				}}
				gap={15}
				paddingX={{ base: 5, md: 0 }}
				paddingY={5}
				{...gridProps}
			>
				{detailsRows.map((item, index) => (
					<DetailsRow
						key={index}
						label={item.label}
						value={item.value}
						isOutline={item.isOutline}
						height={10}
						textProps={{ w: "100%", marginY: 3,color: "gray.600" }}
						boxProps={item.boxProps}
						isCyanBorder={item.isCyanBorder}
						prefix={item.prefix}
					/>
				))}
			</Grid>
		</Box>
	);
});
