import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";

export default class Store<DataType> {
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<DataType>;
	public startDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public finalDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public searchInput = new AttributeShelf<string>("");
	public typeSearch = new AttributeShelf<"Leito" | "ID">("Leito");

	constructor(getAllMedicalRecord: (page: number, filterOptions: api.FilterOptions) => Promise<DataType[]>) {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => getAllMedicalRecord(page, this.filterOptions),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public get filterOptions (): api.FilterOptions {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
			bedNumber: this.typeSearch.value === "Leito" ? this.searchInput.value : null,
			code: this.typeSearch.value === "ID" ? this.searchInput.value : null,
		};
	}

	public exportCsv = async (onSuccess: (url: string) => void) => {
		this.loader.tryStart();
		try {
			const csvUrl = await api.exportCsvMedicalRecord(this.filterOptions);
			onSuccess(csvUrl);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
