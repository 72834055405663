import { FirstCard } from "./FirstCard";
import { SecondCard } from "./SecondCard";
import { ThirdCard } from "./ThirdCard";
import { FourthCard } from "./FourthCard";
import { FifthCard } from "./FifthCard";

export const MedicalRecordDetailsCards = {
	FirstCard,
	SecondCard,
	ThirdCard,
	FourthCard,
	FifthCard,
};
