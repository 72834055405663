import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { DescriptionDetails } from "~/components/DescriptionDetails";

interface IProps {
	tenthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const TenthCard: React.FC<IProps> = observer((props) => {
	const { tenthCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<DescriptionDetails
			label={strings.components.multidisciplinaryVisit.labels.prophylaxis}
			description={tenthCard.form.prophylaxisDescription}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: tenthCard.form.prophylaxisDailyGoal,
					boxProps: {
						gridColumnStart: { base: "auto", xl: 2 },
					},
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: tenthCard.form.prophylaxisResults,
					boxProps: {
						gridColumnStart: { base: "auto", xl: 3 },
					},
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
			]}
		/>
	);
});
