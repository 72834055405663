const cyan = {
	50: "#EBF7F9",
	100: "#C8E8EE",
	200: "#A5DAE4",
	300: "#82CBD9",
	400: "#5FBDCE",
	500: "#3BAEC4",
	600: "#2F8C9D",
	700: "#246975",
	800: "#18464E",
	900: "#0C2327",
};

const purple = {
	50: "#EDECF9",
	100: "#CCC9EE",
	200: "#AAA6E3",
	300: "#8983D8",
	400: "#6860CD",
	500: "#372F96",
	550: "#473DC2",
	600: "#39319B",
	700: "#2B2574",
	800: "#1C184E",
	900: "#0E0C27",
};

export const colors = {
	primary: purple,
	secondary: cyan,
	purple,
};
