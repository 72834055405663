import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr, Td } from "@chakra-ui/react";
import strings from "~/resources/strings";
import { SearchInput, Table, TableCellWithActionButtons } from "~/components";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.beds.table;
	const history = useHistory();

	const { dialog } = useGlobalStore();

	const onGoToCreateBed= () => {
		history.push("create");
	};

	const openDialog = (bed: api.Bed) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(bed.code),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteBed(bed.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateBed}
				addButtonText={pageStrings.addButtonText}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<SearchInput
						value={store.code.value}
						onChange={store.code.setValue}
						onClickSearch={() => store.paginetedListShelf.fetchPage(0)}
						placeholder={pageStrings.placeHolder}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.code}</Td>
						<Td>{pageStrings.available(item.available)}</Td>
						<TableCellWithActionButtons
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
