import { FirstCard } from "./FirstCard";
import { SecondCard } from "./SecondCard";
import { ThirdCard } from "./ThirdCard";
import { ForthCard } from "./ForthCard";
import { FifthCard } from "./FifthCard";

export const MedicalRecordEditCards = {
	FirstCard,
	SecondCard,
	ThirdCard,
	ForthCard,
	FifthCard,
};
