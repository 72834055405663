import React from "react";
import { observer } from "mobx-react-lite";
import { TextInput } from "~/components";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
}

export const SecondCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text fontWeight="bold" color="primary.500">{commonStrings.titleCard2}</Text>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md:"repeat(3,1fr)",
				}}
				gap={5}
			>
				<TextInput
					display="none"
					isDisabled
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("whatNeedsToBeDischargedDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("whatNeedsToBeDischargedDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("whatNeedsToBeDischargedResults").error}
					{...multidisciplinaryVisit.formShelf.field("whatNeedsToBeDischargedResults")}
				/>
			</Grid>
		</Flex>
	);
});
