import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Text: ComponentStyleConfig = {
	defaultProps: {
		variant: "solid",
	},
	variants: {
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			borderWidth: "2px",
			border: "solid",
		}),
	},
};
