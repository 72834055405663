import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";
import strings from "~/resources/strings";

interface IProps extends FlexProps {
	label: string;
	question: string | number | null;
	labelProps?: TextProps;
}

export const QuestionDetails: React.FC<IProps> = observer((props) => {
	const { question, label, labelProps, ...rest } = props;
	const componentString = strings.components.questionsDetails;

	return (
		<Flex w={{ base: "100%", lg: "45%" }} mb={5} {...rest}>
			<Flex flex={1} flexDirection="column">
				<Text
					px={1}
					color="primary.500"
					fontSize="sm"
					fontWeight="bold"
					{...labelProps}
				>
					{label}
				</Text>
				<Flex justifyContent="space-between" alignItems="center">
					<Box>
						<Text position={{ md: "static", lg: "relative" }}
							marginRight={{
								base: 8,
								lg: 0,
							}}
							marginTop={{
								base: 3,
								lg: 0,
							}}
							top={2}
						>
							{componentString.isConditionForAmount(question)}
						</Text>
					</Box>
					<Box pr={2}>
						<Text
							color="gray.600"
							fontSize="sm"
							fontWeight="bold"
						>
							{strings.common.fields.amount}
						</Text>
						<Text
							borderColor="primary.500"
							variant="outline"
							fontSize="md"
							color="gray.600"
							p={1}
							borderRadius="lg"
							maxW={300}
							w={{
								base: "4rem",
								sm: 150,
								md: 220,
								xl: 300,
							}}
						>
							{componentString.isConditionForQuestion(question)}
						</Text>
					</Box>
				</Flex>
			</Flex>
		</Flex>
	);
});
