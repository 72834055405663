import React from "react";
import { observer } from "mobx-react-lite";
import { Box, BoxProps, HStack, TextProps } from "@chakra-ui/react";
import { Radio, Label } from "~/components";
import strings from "~/resources/strings";
import { AttributeShelf } from "@startapp/mobx-utils";

export interface IProps extends BoxProps {
	isLoading?: boolean;
	attributeShelf: AttributeShelf<boolean>;
	label?: string;
	labelProps?: TextProps;
}

export const RadioBoolean: React.FC<IProps> = observer((props) => {
	const {
		isLoading,
		label,
		attributeShelf,
		labelProps,
		...rest
	} = props;

	const commonStrings = strings.common;

	return (
		<Box {...rest}>
			{label && (
				<Label
					color="primary.500"
					fontWeight="bold"
					fontSize="sm"
					mx={{ base: "auto", sm: 0 }}
					flexDirection={{ base: "column", md: "row" }}
					{...labelProps}
				>
					{label}
				</Label>
			)}

			<HStack
				spacing={{ base: 4, lg: 5, xl: 10}}
				mb={{ base: 4, md: 2 }}
				minW="150px"
				mr={{ base: 0, sm: 2, md: 5 }}
			>
				<Radio
					pt={5}
					label={commonStrings.yes}
					isSelected={attributeShelf.value}
					onClick={!isLoading ? () => attributeShelf.setValue(true) : undefined}
					cursor={!isLoading ? "default" : "pointer"}
				/>
				<Radio
					pt={5}
					label={commonStrings.no}
					isSelected={!attributeShelf.value}
					onClick={!isLoading ? () => attributeShelf.setValue(false) : undefined}
					cursor={!isLoading ? "default" : "pointer"}
				/>
			</HStack>
		</Box>
	);
});

