import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";
import { CustomError } from "~/resources/customError";
import strings from "~/resources/strings";
import { onFetchError } from "~/resources/fetchError";

export default class Store {

	public medicalRecordFormShelf = new MedicalRecordFormShelf();
	public loader = new LoaderShelf();
	public bedsPaginatedList: PaginatedListShelf<api.Bed>;

	constructor(){
		makeAutoObservable(this);
		this.bedsPaginatedList = new PaginatedListShelf((page) => api.getAllAvailableBeds(page),
			{
				fetchOnConstructor: true,
				onFetchError,
			});
	}

	public createMedicalRecord = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			this.checkIfBedNumberOrCpfWereFilledUp();
			const data = this.medicalRecordFormShelf.createNewMedicalRecord();
			await api.createMedicalRecord(data);
			onSuccess();
			showSuccessToast(strings.pages.patients.create.success);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public checkIfBedNumberOrCpfWereFilledUp = () => {
		const hasNotFilledOut = !this.medicalRecordFormShelf.bedId.value || !this.medicalRecordFormShelf.cpf.value;
		if (hasNotFilledOut) {
			throw new CustomError(api.ErrorType.InvalidArgument, strings.error.fieldsMustBeFilled);
		}
	};

	public getPatientByCpf = async (documentNumber: string) => {
		this.medicalRecordFormShelf.isBlocked.setValue(true);
		this.loader.start();

		this.medicalRecordFormShelf.setPatientInitialValues();
		try {
			const patient = await api.getPatientByDocumentNumber(documentNumber);
			this.medicalRecordFormShelf.setPatientInitialValues(patient);
		} catch (e) {
			this.medicalRecordFormShelf.isBlocked.setValue(false);
		} finally {
			this.loader.end();
			this.medicalRecordFormShelf.isBlocked.setValue(false);
		}
	};
}

