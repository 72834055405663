import React from "react";
import { observer } from "mobx-react-lite";
import { Box, BoxProps, Flex, HStack } from "@chakra-ui/react";
import { Radio, Label } from "~/components";
import strings from "~/resources/strings";

export interface IProps extends BoxProps {
	isLoading?: boolean;
	onClickYes?: () => void;
	onClickNo?: () => void;
	isYesSelected?: boolean;
	isNoSelected?: boolean;
	label?: string;
	children?: React.ReactNode;
}

export const QuestonInput: React.FC<IProps> = observer((props) => {
	const {
		isLoading,
		onClickYes,
		onClickNo,
		isYesSelected,
		isNoSelected,
		label,
		children,
		...rest
	} = props;

	const commonStrings = strings.common;

	return (
		<Box {...rest}>
			{label && (
				<Label
					color="primary.500"
					fontWeight="bold"
					mx={{ base: "auto", sm: 0 }}
					flexDirection={{ base: "column", md: "row" }}
				>
					{label}
				</Label>
			)}
			<Flex
				w="100%"
				justifyContent={{  lg: "space-between" }}
				alignItems="center"
				flexWrap="wrap"
				flexDirection={{ base: "column", sm: "row" }}
				mt={2}
			>
				<HStack
					spacing={{ base: 4, lg: 5, xl: 10}}
					mb={{ base: 4, md: 2 }}
					minW="150px"
					mr={{ base: 0, sm: 2, md: 5 }}
				>
					<Radio
						pt={5}
						label={commonStrings.yes}
						isSelected={isYesSelected}
						onClick={!isLoading ? onClickYes : undefined}
						cursor={!isLoading ? "default" : "pointer"}
					/>
					<Radio
						pt={5}
						label={commonStrings.no}
						isSelected={isNoSelected}
						onClick={!isLoading ? onClickNo : undefined}
						cursor={!isLoading ? "default" : "pointer"}
					/>
				</HStack>
				{children}
			</Flex>
		</Box>
	);
});

