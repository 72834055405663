import React from "react";
import { Text, Flex, StackProps, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react-lite";
import strings from "~/resources/strings";
import { DatePicker } from "~/components";
import { Image } from "@chakra-ui/react";

interface IProps extends StackProps {
	selectedStartDate: Date | null;
	selectedFinalDate: Date | null;
	onChangeStartDate: (date: Date | null) => void;
	onChangeFinalDate: (date: Date | null) => void;
}

export const PeriodFilter: React.FC<IProps> = observer((props) => {
	const { selectedStartDate, selectedFinalDate, onChangeStartDate, onChangeFinalDate, ...rest } = props;
	const componentStrings = strings.components.checkinPeriodFilter;

	return (
		<Stack
			w="100%"
			maxW="3xl"
			direction="row"
			wrap="wrap"
			px={3}
			alignItems="center"
			justifyContent={{ base: "space-evenly", md: "space-around" }}
			borderRadius={15}
			mb={{base: 5, lg: 0 }}
			bg="white"
			{...rest}
		>
			<Flex>
				<Image src="/IconPeriodFilter.svg" />
				<Text
					px={2}
					textColor="primary.600"
					fontWeight="bold"
					textAlign="center"
					fontSize="md"
				>
					{componentStrings.filterDate}
				</Text>
			</Flex>

			<Flex
				wrap="wrap"
				alignItems="center"
				justifyContent="center"
			>
				<Flex
					alignItems="center"
					my={2}
				>
					<Text
						textColor="gray.600"
						fontSize="md"
						px={2}
					>
						{componentStrings.from}
					</Text>
					<DatePicker
						onChangeDate={onChangeStartDate}
						selectedDate={selectedStartDate}
						calendarIcon
						inputProps={{ h: "35px", textAlign: "center" }}
						maxW="200"
						borderRight={{base: "none", md: "1px solid"}}
						borderColor={{base: "none", md: "gray.300"}}
						pr={{base: 0, md: 4}}
					/>
				</Flex>
				<Flex
					alignItems="center"
					my={2}
					ml={{base: 0, md: 2}}
				>
					<Text
						textColor="gray.700"
						fontSize="md"
						px={2}
					>
						{componentStrings.until}
					</Text>
					<DatePicker
						onChangeDate={onChangeFinalDate}
						selectedDate={selectedFinalDate}
						inputProps={{ h: "35px", textAlign: "center" }}
						maxW="180"
					/>
				</Flex>
			</Flex>

		</Stack>
	);
},
);
