import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr, Td } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { PeriodFilter, SearchInput, Table, TableCellWithActionButtons } from "~/components";
import { useHistory } from "~/hooks/useHistory";

export const TableView: React.FC = observer(() => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.routineManagement.table;
	const history = useHistory();

	const onGoToDetails = (medicalRecordId: string) => history.push(`details/${medicalRecordId}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				headers={pageStrings.header}
				tableTitle={pageStrings.title}
				headerFilter={
					<>
						<SearchInput
							placeholder={strings.components.searchInput.placeholder}
							select={{
								onSelect: store.typeSearch.setValue,
								optionsValues: ["Leito", "ID"],
								selectedValue: store.typeSearch.value,
							}}
							value={store.searchInput.value}
							onChange={store.searchInput.setValue}
							onClickSearch={()=> store.paginetedListShelf.fetchPage(0)}
						/>
						<PeriodFilter
							selectedStartDate={store.startDate.value}
							onChangeStartDate={store.startDate.setValue}
							selectedFinalDate={store.finalDate.value}
							onChangeFinalDate={store.finalDate.setValue}
						/>
					</>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.medicalRecord.code}</Td>
						<Td>{item.medicalRecord.bed.code}</Td>
						<Td>{strings.format.utcDate(item.date)}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.medicalRecord.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
});
