import React from "react";
import {
	Box,
	Stack,
	Text,
	Button,
	BoxProps,
	TextProps,
} from "@chakra-ui/react";

export interface IDetailsProps {
	label: string | null;
	value: string | string[] | number | null | undefined;
	isOutline?: boolean;
	height?: string | number;
	textProps?: TextProps;
	boxProps?: BoxProps;
	labelProps?: TextProps;
	isCyanBorder?: boolean;
	prefix?: string;
}

export const DetailsRow: React.FC<IDetailsProps> = (props) => {
	const { value, label, isOutline, height, textProps, labelProps, boxProps, isCyanBorder, prefix } = props;
	return (
		<Box {...boxProps}>
			{!Array.isArray(value) ?
				(
					<Box px={1}>
						<Text
							px={1}
							color="primary.500"
							fontSize="sm"
							fontWeight="bold"
							{...labelProps}
						>
							{label}
						</Text>
						<Text
							borderColor={isCyanBorder ? "cyan.700" : "primary.500"}
							variant={isOutline ?  "outline" : "none"}
							fontSize="sm"
							color="gray.700"
							h={height || "100%"}
							p={2}
							borderRadius="lg"
							{...textProps}
						>
							{
								prefix &&
									<Text
										display="inline"
										marginRight={2}
										color="gray.600"
										fontWeight="bold"
									>
										{prefix}
									</Text>
							}
							{value}
						</Text>
					</Box>
				) : (
					<Stack spacing={1}>
						<Text fontSize="md" fontWeight="bold">{label}</Text>
						<Box>
							{Array.isArray(value) && value.map((name) => (
								<Button
									m={1}
									fontWeight="initial"
									key={name}
									cursor="initial"
								>
									{name}
								</Button>
							))}
						</Box>
					</Stack>
				)}
		</Box>
	);
};
