import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	thirdCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const ThirdCard: React.FC<IProps> = observer((props) => {
	const { thirdCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.sedationDelirium}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: thirdCard.form.sedationDeliriumRassTarget,
					prefix: componentStrings.placeholders.targetRass,
				},
				{
					isOutline: true,
					label: null,
					value: thirdCard.form.sedationDeliriumDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: thirdCard.form.sedationDeliriumResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
			]}
		/>
	);
});
