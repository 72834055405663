import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex } from "@chakra-ui/react";

import Store from "./store";

import { CentralizedCard, TextInput } from "~/components";

import strings from "~/resources/strings";

import { useHistory } from "~/hooks/useHistory";

const Create: React.FC = () => {

	const commonStrings = strings.common;
	const pageStrings = strings.pages.beds.create;

	const history = useHistory();

	const store = useLocalObservable(() => new Store());

	const onSuccess = () => {
		history.goBack();
	};

	const onSubmit = () => {
		store.createBed(onSuccess);
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{strings.common.buttons.confirmButton(false)}
				</Button>
			)}
		>
			<Flex justifyContent="center" gap={5}>
				<TextInput
					labelText={commonStrings.fields.code}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					mask="999"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("code").error}
					{...store.formShelf.field("code")}
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(Create);
