import React from "react";
import { observer } from "mobx-react-lite";
import { FormControlProps, Grid, InputProps, Text } from "@chakra-ui/react";
import { TextInput } from "~/components";
import strings from "~/resources/strings";
import FormSafetyHuddle from "~/pages/SafetyHuddle/CreateOrEdit/shelves/formSafetyHuddle";
import { LoaderShelf } from "@startapp/mobx-utils";
import { Label } from "../../Label";

interface IProps {
	loader: LoaderShelf;
	formSafetyHuddle: FormSafetyHuddle;
	isDisabled?: boolean;
}

export const PositionSection: React.FC<IProps> = observer((props) => {
	const { formSafetyHuddle, loader, isDisabled } = props;
	const commonStrings = strings.common;

	const formControlProps: FormControlProps = {
		display: {  lg: "flex" },
		alignItems: "center",
		justifyContent:"space-between",
	};
	const labelProps = { fontWeight: "bold", color: "gray.600", mr: 2, fontSize: "sm" };
	const inputProps: InputProps = { w: { base: "100%", lg: "70%" }};

	return (
		<>
			<Label
				color="primary.500"
				fontWeight="bold"
				flexDirection={{ base: "column", md: "row" }}
			>
				{strings.pages.safetyHuddle.createOrEdit.positionSection.title1}
				<Text
					fontWeight="normal"
					ml={2}
				>
					{strings.pages.safetyHuddle.createOrEdit.positionSection.title2}
				</Text>
			</Label>
			<Grid templateColumns={{ base: "1fr", md: "1fr 1fr", xl:"1fr 1fr 1fr"}} gap={5}>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.dayLaborer}
					labelColor="primary"
					type="text"
					{...inputProps}
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionDayLaborer")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					{...inputProps}
					labelText={commonStrings.fields.nurses}
					labelColor="primary"
					type="text"
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionNurses")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					{...inputProps}
					labelText={commonStrings.fields.pyschology}
					labelColor="primary"
					type="text"
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionPsychologist")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.onDuty}
					labelColor="primary"
					type="text"
					{...inputProps}
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionOnDuty")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.physicalTherapists}
					labelColor="primary"
					type="text"
					{...inputProps}
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionPhysicalTherapists")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.pharmacy}
					labelColor="primary"
					{...inputProps}
					type="text"
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionPharmacy")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.technical}
					labelColor="primary"
					{...inputProps}
					type="text"
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionTechnical")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.adm}
					labelColor="primary"
					type="text"
					{...inputProps}
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionAdm")}
				/>
				<TextInput
					formControlProps={formControlProps}
					labelProps={labelProps}
					labelText={commonStrings.fields.others}
					labelColor="primary"
					{...inputProps}
					type="text"
					isDisabled={loader.isLoading || isDisabled}
					{...formSafetyHuddle.formShelf.field("positionOthers")}
				/>
			</Grid>
		</>
	);
});

