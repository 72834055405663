import { DetailsView } from "./DetailsView";
import { TableView } from "./TableView";
import { Create } from "./Create";
import { Edit } from "./Edit";

export const MultidisciplinaryVisit = {
	TableView,
	Create,
	DetailsView,
	Edit,
};

