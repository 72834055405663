import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	ButtonProps,
} from "@chakra-ui/react";

interface IProps extends ButtonProps {
	label: string;
	onClick?: () => void;
	isChecked?: boolean;
}

export const RoutineCheckButton: React.FC<IProps> = observer((props) => {
	const { label, onClick, isChecked, ...rest } = props;
	return (
		<Button
			rounded="full"
			onClick={onClick}
			bg={isChecked ? "secondary.400" : "gray.400"}
			{...rest}
		>
			{label}
		</Button>
	);
});
