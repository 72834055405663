import React from "react";
import RDatePicker from "react-datepicker";
import {
	Input,
	InputProps,
	FormLabel,
	FormControl,
	FormControlProps,
	Image,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import { InputGroup } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import format from "~/resources/format";
import strings from "~/resources/strings";

interface IProps extends FormControlProps {
	onChangeDate: (date: Date) => void;
	selectedDate: Date | null;
	isDisabled?: boolean;
	minDate?: Date;
	inputProps?: InputProps;
	label?: string;
	calendarIcon?: boolean;
	selectedValueFormat?: (currentDate?: Date | null) => string;
}

export const DatePicker: React.FC<IProps> = observer((props) => {
	const {
		onChangeDate,
		selectedDate,
		inputProps,
		minDate,
		label,
		selectedValueFormat,
		isDisabled,
		...rest
	} = props;
	const formatedDate = selectedDate ? format.date(selectedDate) : strings.common.mask.date;

	return (
		<FormControl cursor="pointer" isDisabled={isDisabled} {...rest}>
			{label && (
				<FormLabel
					fontSize="sm"
					fontWeight="bold"
					color="primary.500"
					noOfLines={1}
				>
					{label}
				</FormLabel>
			)}

			<RDatePicker
				locale={ptBR}
				dateFormat="dd/MM/yyyy"
				selected={selectedDate}
				minDate={minDate}
				onChange={onChangeDate}
				disabled={isDisabled}
				customInput={
					<InputGroup>
						<Input
							color="gray.600"
							value={selectedValueFormat ?selectedValueFormat(selectedDate) : formatedDate}
							letterSpacing="2px"
							readOnly
							cursor="pointer"
							{...inputProps}
							marginRight={2}
						/>
						<Image src="/calendar.svg" />
					</InputGroup>
				}
			/>
		</FormControl>
	);

});
