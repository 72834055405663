import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { DescriptionDetails } from "~/components/DescriptionDetails";

interface IProps {
	fifteenthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const FifteenthCard: React.FC<IProps> = observer((props) => {
	const { fifteenthCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<DescriptionDetails
			label={strings.components.multidisciplinaryVisit.labels.familyComunication}
			description={fifteenthCard.form.familyCommunicationDescription}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(fifteenthCard.form.familyCommunicationHomeCare),
					prefix: componentStrings.placeholders.homeCare,
				},
				{
					isOutline: true,
					label: null,
					value: fifteenthCard.form.whatNeedsToBeDischargedDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: fifteenthCard.form.whatNeedsToBeDischargedResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(fifteenthCard.form.familyCommunicationPalliation),
					prefix: componentStrings.placeholders.palliation,
				},
			]}
		/>
	);
});
