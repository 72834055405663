import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, GridProps, Text, TextProps } from "@chakra-ui/react";
import { DetailsRow } from "~/components";
import { IDetailsProps } from "~/components/DetailsRow";

interface IProps {
	detailsRows: IDetailsProps[];
	label?: string;
	gridProps?: GridProps;
	position?: boolean;
	labelProps?: TextProps;
}

export const CardDetails: React.FC<IProps> = observer((props) => {
	const { detailsRows, gridProps, label, labelProps, position } = props;

	return (
		<Grid
			templateColumns={{
				base: "minmax(250px, 480px)",
				md: "repeat(2, minmax(150px, 350px))",
				lg: "repeat(2, minmax(150px, 450px))",
				xl: "repeat(3, minmax(100px, 470px))",
			}}
			gap={15}
			paddingY={position ? 5 : 0}
			position={position ? "relative" : "static"}
			{...gridProps}
		>
			{
				label &&
					<Text
						px={1}
						color="primary.500"
						fontSize="sm"
						fontWeight="bold"
						{...labelProps}
					>
						{label}
					</Text>
			}
			{detailsRows.map((item, index) => (
				<DetailsRow
					key={index}
					label={item.label}
					value={item.value}
					isOutline={item.isOutline}
					height={10}
					isCyanBorder={item.isCyanBorder}
					textProps={{ color: "gray.600" }}
					prefix={item.prefix}
				/>
			))}
		</Grid>
	);
});
