import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	seventhCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const SeventhCard: React.FC<IProps> = observer((props) => {
	const { seventhCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.renal}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(seventhCard.form.renalBhLast24Hours),
					prefix: componentStrings.placeholders.renalBhLast24Hours,
				},
				{
					isOutline: true,
					label: null,
					value: seventhCard.form.renalDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: seventhCard.form.renalResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: seventhCard.form.renalBhTarget,
					prefix: componentStrings.placeholders.renalBhTarget,
				},
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(seventhCard.form.renalhd),
					prefix: componentStrings.placeholders.renalBhTarget,
				},
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(seventhCard.form.renalDiuretic),
					prefix: componentStrings.placeholders.renalDiuretic,
				},
			]}
		/>
	);
});
