import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { CardDetails } from "~/components/CardDetails";
import { IDetailsProps } from "~/components/DetailsRow";

interface IProps {
	firstCard: api.MultidisciplinaryVisit | api.MultidisciplinaryVisitWithoutPatient;
	currentUser: api.AdminType | undefined;
}

export const FirstCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { firstCard, currentUser } = props;

	const getDetailsRow = (): IDetailsProps[] => {
		if (currentUser === api.AdminType.master) {
			const multidisciplinaryVisit = firstCard as api.MultidisciplinaryVisit;
			return [
				{
					label: componentStrings.fields.patient,
					value: multidisciplinaryVisit.medicalRecord.patient.fullname,
				},
				{
					label: componentStrings.fields.date,
					value: format.date(multidisciplinaryVisit.date),
				},
				{
					label: componentStrings.fields.bed,
					value: multidisciplinaryVisit.medicalRecord.bed.code,
				},
				{
					label: componentStrings.fields.id,
					value: multidisciplinaryVisit.medicalRecord.code,
				},
				{
					label: componentStrings.fields.birthDay,
					value: format.date(multidisciplinaryVisit.medicalRecord.patient.birthdate),
				},
				{
					label: componentStrings.fields.age,
					value: multidisciplinaryVisit.medicalRecord.patient.age,
				},
				{
					label: componentStrings.fields.genre,
					value: multidisciplinaryVisit.medicalRecord.patient.genre,
				},
			];
		}

		return [
			{
				label: componentStrings.fields.date,
				value: format.date(firstCard.createdAt),
			},
			{
				label: componentStrings.fields.bed,
				value: firstCard.medicalRecord.bed.code,
			},
			{
				label: componentStrings.fields.id,
				value: firstCard.medicalRecord.code,
			},
		];
	};

	return (
		<CardDetails
			detailsRows={getDetailsRow()}
		/>
	);
});
