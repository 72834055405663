import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		enum: {
			AdminType: {
				master: "Admin",
				employee: "Funcionário",
				supervisor: "Supervisor",
			},
			TevAdmission: {
				highRisk: "Alto risco",
				moderate: "Moderado",
				lowRisk: "Baixo Risco",
			},
			Plan: {
				planservQuali: "Planserv_quali",
				cnuUnimed: "Cnu - unimed",
				cassi: "Cassi",
				geapSaude:"Geap saude",
				sulamerica: "Sulamerica",
				amilInter: "Amil inter",
				postalsaudeEct: "Postalsaude-Ect",
				petrobrasAps: "Petrobras Aps",
				apubSsind: "apubSsind",
			},
			Genre:{
				male: "Homem",
				female: "Mulher",
			},
			Unit: {
				none: " ",
				internacao2D: "Internação 2D",
				internacao6D: "Internação 6D",
				semiIntensiva6C: "Semi-intensiva 6C",
				semiIntensiva7C: "Semi-intensiva 7C",
				semiIntensiva8C: "Semi-intensiva 8C",
				emergencia: "Emergência",
				utiAdulto1B :"UTI adulto 1B",
				utiAdulto1C: "UTI adulto 1C",
				utiAdulto2B: "UTI adulto 2B",
				utiAdulto2C: "UTI adulto 2C",
				utiAdulto3C: "UTI adulto 3C",
			},
			O2SupportAct: {
				AA: "AA",
				O2: "O2",
				VNI: "VNI",
				CNAF: "CNAF",
				VMI: "VMI",
			},
			ExitLocation: {
				none: " ",
				dischargedUi: "Alta ui",
				dischargedHospital: "Alta hospitalar",
				dischargedSemi: "Alta semi",
			},
		},
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			cpfFilter: {
				cpfFilter: "Filtrar por CPF",
				searchButton: "Buscar",
				incompleteCpf: "Digite o CPF completo para realizarmos a busca",
			},
			autoCompleteModal: {
				back: "Voltar",
			},
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			searchInput: {
				search: "Buscar por",
				placeholder: "Digite aqui...",
			},
			checkinPeriodFilter: {
				filterDate: "Filtrar por data:",
				from: "De:",
				until: "Até:",
			},
			questionsSection: {
				labels: {
					amountOfwatcherPatientsOnTheUnit: "Há pacientes Watcher na unidade?",
					amountOfSeverePatientInTheUnit: "Paciente de maior gravidade na unidade?",
					watcherPatients: "",
					newtherapiesimplementedInTheUti: "Há terapias novas implementadas na UTI?",
					amountOfDischargedPatientsThatDidnNotLeave: "Há pacientes de alta da UTI que não saíram ontem?",
					amountOfPatientsWithOrganDysfunction24Hours: "Houve algum paciente com disfunção orgânica nas últimas 24h?",
					amountOfInvasiveProceduresThatDidNotHappenYesterday: "Há procedimentos invasivos a serem realizados que não foram feitos ontem?",
					amountOfCommunicationProblemsWithyFamilyOrUti: "Há problemas de comunicação entre familiares, UTI ou médicos assistentes?",
					amountOfImagingOrLabTestsThatDidNotHappen: "Há exames de imagem ou laboratório que não foram feitos ontem?",
					amountOfAdverseEventsInTheLast24Hours: "Houve eventos adversos nas últimas 24h? Terapias novas implementadas na UTI?",
				},
			},
			multidisciplinaryVisit: {
				labels: {
					thePatientNeedToBeDischargedFromTheUTI: "O que o paciente precisa para receber alta da UTI?",
					sedationDelirium: "Analgesia e Sedação delirium",
					cardiovascular: "Cardiovascular",
					pulmonar: "Pulmonar",
					mobilization: "Mobilização",
					renal: "Renal",
					hematousAndInfectious: "Hemato e Infeccioso",
					glucose: "Glicemia e Nutrição",
					prophylaxis: "Profilaxias",
					skinIntegrity: "Integridade Cutânea",
					devices: "Dispositivos",
					consultations: "Interconsultas",
					medicalExames: "Exames a realizar",
					familyComunication: "Comunicação com familiares",
					watcherPatient: "Paciente Watcher?",

				},
				isCondition: (condition: boolean) => condition ? "Sim" : "Não",
			},
			questionsDetails: {
				isConditionForAmount: (condition: string | number | null) => condition ? "Sim" : "Não",
				isConditionForQuestion: (condition: string | number | null) => condition ? condition : "0",
			},
			cardDetails: {
				placeholders: {
					dailyGoal: "Meta diária:",
					result: "Resultado:",
					targetRass: "Rass Alvo:",
					Lactate: "Lactato:",
					hemodynamicMonitoring: "Monitorização Hemodinâmica:",
					pas: "PAS:",
					pad: "PAD:",
					pam: "PAM:",
					ce30: "CE a 30º:",
					o2TargetSaturation: "Saturação de O2 Alvo:",
					o2ActSaturation: "Suporte de O2 atual:",
					disarm: "Desmame:",
					isOpen: "Liberado:",
					renalBhLast24Hours: "BH nas ultimas 24 horas: ",
					renalBhTarget: "BH Alvo:",
					renalHD: "HD:",
					renalDiuretic: "Diurético:",
					fever: "Febre:",
					doseATB: "Dose ATB:",
					hbFall: "Queda de Hb:",
					glucose140To180: "Glicemias 140 a 180:",
					liberatedDiet: "Dieta Liberada:",
					cvc: "CVC:",
					svd: "SVD:",
					pami: "PAMI:",
					phd: "P/HD:",
					deviceRemove: "Restirar:",
					homeCare: "Home Care:",
					palliation: "Paliação:",
				},
			},
		},
		nav: {
			home: "Home",
			adminMaster: "Funcionários",
			patients: "Gerir Pacientes",
			routineManagement: "Gestão de Rotina",
			beds: "Leitos",
			acessLogs: "Lista de Acessos",
			users: "Usuários",
			pendingUsers: "Usuários Pendentes",
			safetyHuddle: "Safety Huddle",
			multidisciplinaryVisit: "Visitas Multidisciplinares",
			logout: "Sair",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			createPatient: "Criar novo prontuário",
			cancel: "Cancelar",
			select: "Selecionar",
			exportCsv: "Exportar CSV",
			back: "Voltar",
			add: "Adicionar",
			send: "Enviar",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
			selectImage:"É necessário selecionar uma imagem para o banner",
			currentUserIsNotSuperAdmin: "Sem Permissão para acessar rota. Não é super admin. Faça um novo login.",
			currentUserIsNotSupervisor: "Sem Permissão para acessar rota. Não é um Supervisor para acessar a rota",
			currentUserIsNotEmployee: "Sem Permissão para acessar rota. Precisa ser um funcionario para acessar a rota",
			fieldsMustBeFilled: "Campos leito e cpf precisam ser preenchidos",
		},
		common: {
			yes: "Sim",
			no: "Não",
			date: "Data",
			create: "Criar",
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			approve: "Clique para aprovar ou recusar",
			edit: "Clique para editar",
			cancel: "Cancelar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			documentPhoto: "Foto:",
			documentProof: "Comprovante:",
			documentProofNoResult: "Comprovante não encontrado",
			close: "Fechar",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				saveOrEdit: (isEdit?: boolean) => isEdit ? "Editar" : "Salvar",
				backButton: "Voltar",
			},
			empty: "Vazio",
			infoUser: "Informações do Usuaŕio:",
			fields: {
				which: "Quais",
				creator: "Realizador",
				date: "Data:",
				id: "ID:",
				name: "Nome:",
				email: "E-mail:",
				password: "Senha:",
				cpf: "CPF:",
				function: "Função:",
				phone: "Celular:",
				address: "Endereço:",
				disable: "Desabilitar:",
				approvedBy: "Aprovado por:",
				photo: "Foto:",
				mobilePhoto:"Foto Mobile:",
				account: "Conta:",
				typeAccount: "Tipo da Conta:",
				accountDv: "Dígito da Conta:",
				agency: "Agência:",
				agencyDv: "Dígito da Agência:",
				bankCode: "Código do Banco:",
				bankName: "Banco:",
				documentPhoto:"Comprovante de residência:",
				receiptImage: "Comprovante de transferência:",
				value: "Valor:",
				createAt: "Criado em:",
				acceptedAt: "Aceito em:",
				acceptedAtHour: "Horário:",
				status: "Situação:",
				approvedValue: "Valor aprovado:",
				pendingValue: "Valor a ser aprovado:",
				approvedAt: "Data de confirmação:",
				amount: "Quantidade",
				bed: "Leito:",
				hour: "Horário:",
				concatData: (left: string, right: string | null) =>
					right ? `${left} - ${right}` : left,
				pix: "Pix:",
				code: "Número:",
				available: "Disponível: ",
				dayLaborer: "Diarista",
				nurses: "Enfermeiras",
				pyschology: "Psicologia",
				onDuty: "Plantonista",
				physicalTherapists: "Fisioterapeutas",
				pharmacy: "Farmacia",
				technical: "Tecnicos",
				adm: "ADM",
				others: "Outros",
				howToImproveTheQualityOfPatient: "O que podemos fazer para melhorar a qualidade de segurança dos pacientes?",

			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				titleBlock: (isBlock: boolean) => isBlock ? "Desbloquear" : "Bloquear",
				titleApproveOrRefuse: "Aprovar ou Recusar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionRemove: (itemName: string) => `Tem certeza que deseja remover ${itemName}?`,
				descriptionBlock: (itemName: string, isBlock: Date | null) => isBlock ? `Tem certeza que deseja desbloquear ${itemName}?` : `Tem certeza que deseja bloquear ${itemName}?`,
				descriptionApproveOrRefuse: (ItemName: string) => `Deseja aprovar ou recusar ${ItemName}?`,
				descriptionApproveOrRefuseUser: (approve: boolean | null) => approve ? "tem certeza que deseja aprovar ?" : "tem certeza que deseja recusar ?",
				statusUser: (isUserBlocked: boolean) => `O usuário ${isUserBlocked ? "foi bloqueado" : "foi desbloqueado"}`,
				button: {
					approve: "Aprovar",
					refuse: "Recusar",
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
					block: "Bloquear",
					disable: "Bloquear",
					enable: "Desbloquear",
					confirm: "Confirmar",
				},
			},
		},
		home: {
			message: (userName: string) => `Bem vindo ao Dashboard,  ${userName}!`,
			occupacy: "Porcentagem de ocupação de leitos",
		},
		pages: {
			adminUserTable: {
				table: {
					title: "Lista de Funcionários",
					header: ["Nome do Funcionário", "Email", "Função", "Data", ""],
					delete: (title: string) => `Administrador ${title} foi deletado!`,
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Funcionário",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Administrador" : "Cadastrar Administrador",
					success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
				},
				logAcess: {
					table: {
						header: ["Data", "Hora", "Usuário", "Ação"],
					},
				},
			},
			routineManagement: {
				table: {
					title: "Gestão de Rotina",
					header: ["ID", "Leito", "Data", "", ""],
					headerDetails: ["Data", "Gerenc. Paciente", "Safety", "Visita Multidisciplinar", ""],
					delete: (title: string) => `Administrador ${title} foi deletado!`,
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Funcionário",
				},
				details: {
					title: "Gestão de Rotina do Paciente",
					header: ["Data", "Gerenc.Paciente", "Safety Huddle", "Visita Multidisciplinar"],
					safetyHuddleError: "Safety Huddle desse dia ainda não foi cadastrado",
					medicalRecord: "Todos prontuários desse dia têm que ser verificados antes de criar o safety huddle do dia",
				},
			},
			patients: {
				table: {
					title: "Gerir Pacientes",
					header: (patient: boolean) => patient ? ["Nome do paciente", "ID", "Leito", "Data"] : ["ID", "Leito", "Data"],
					delete: (userName: string) => `Paciente ${userName} foi deletado!`,
				},
				create:{
					bedLabel: "Selecione um dos leitos abaixos",
					title: "Paciente",
					success: "Cadastro realizado com sucesso!",
				},
				edit:{
					success: "Paciente editado com sucesso!",
				},
				nsa: "NSA",
				fields: {
					fullname: "Nome Completo*",
					bed: "Leito*",
					id: "ID",
					cpf: "CPF*",
					birthdate: "Data de nascimento*",
					age: "Idade",
					genre: "Sexo",
					plan: "Plano",
					unit: "Unidade",
					internmentDateInHospital: "Data de internamento no hospital",
					internmentDateInUTI: "Data de internamento na UTI",
					patientOrigin: "Origem do paciente",
					reasonForAdmission: "Motivo de admissão na UTI",
					apacheII: "Apache II",
					fragility: "Fragilidade",
					charlson: "Charlson",
					sofaday: (day: string) => `SOFA dia ${day}`,
					sofa: "SOFA dia alta",
					sapsIII: "SAPS III",
					eag: "EAG",
					nas: "NAS",
					tev: "TEV/Admissão",
					watcher: "Watcher",
					palliation: "Paliação",
					protocolOpeningDate: "Data de abertura de protocolo",
					protocolOpeningUnit: "Unidade de abertura de protocolo Sepse",
					cvcInstDate: "Data CVC/INST (1)",
					cvcRetDate: "Data CVC/RET (1)",
					totInstDate: "Data TOT/INST (2)",
					totRetDate: "Data CVC/RET (2)",
					totIDate: "Data CVC/RET",
					svdInstDate: "Data SVD/INST (2)",
					svdRetDate: "Data SVD/RET (2)",
					LPPOnAdmission: "LPP na admissão",
					LPPDuringHospitalization: "LPP durante internação",
					departureDate: "Data de saída",
					exitLocation: "Local de saída",
					outputUnit: "Unidade de saída",
					death: "Óbito",
					escore: "Escore",
					isSurgeryCenter: "Centro Cirúrgico",
				},
				placeholder: {
					fullname: "Nome completo do paciente",
					bed: "Número do Leito",
					id: "Número do Paciente",
					unitSelect: "Selecione uma unidade",
					patientOrigin: "Nome da Origem do paciente",
				},
				details: {
					title: "Paciente",
					fields: {
						patient: "Paciente",
						date: "Data",
						bed: "Leito",
						id: "ID",
						birthDay: "Data de nascimento",
						age: "Idade",
						genre: "Sexo",
						plan: "Plano",
						units: "Unidades",
						admissionDateOfHospital: "Data de internamento no Hospital",
						admissionDateOfUTI: "Data de internamento na UTI",
						patientOrigin: "Origem do paciente",
						reasonForAdmissionOnTheUTI: "Motivo de admissão na UTI",
						apache2: "Apache II",
						fragility: "Fragilidade",
						charlson: "Charlson",
						sofa1: "SOFA dia 1",
						sofa2: "SOFA dia 2",
						sofa3: "SOFA dia 3",
						sofaDischarged: "Sofa dia alta",
						saps3: "SAPS III",
						eag: "EAG",
						tevAdmission: "TEV/Admissão",
						watcher: "Watcher",
						palliation: "Paliação",
						protocolOpeningDate: "Data de abertura de protocolo",
						openingUnitSepsisProtocol: "Unidade de Abertura Protocolo Sepse",
						cvcInstDate: "Data CVC/INST (1)",
						cvcRetDate: "Data CVC/RET (1)",
						totInstDate: "Data TOT/INST (2)",
						totRetDate: "Data TOT/RET (2)",
						svdInstDate: "Data SVD/INST (3)",
						svdRetDate: "Data SVD/RET (3)",
						isLPPOnAdmission: "LPP na admissão",
						isLPPDuringHospitalization: "LPP durante internamento",
						departureDate: "Data de saída",
						outputLocation: "Local de saída",
						outputUnit: "Unidade de saída",
						death: "Óbito",
						escore: "Escore",
						nsa: "NAS",
						isSurgeryCenter: "Centro cirúrgico",

					},
					isCondition: (condition: boolean) => condition ? "Sim" : "Não",
				},
			},
			safetyHuddle: {
				table: {
					title: "Gerir Safety Huddles",
					header: ["Realizador", "Data"],
					addButtonText: "Cadastrar Safety Huddle",
				},
				createOrEdit: {
					title: "Safety Huddle",
					positionSection: {
						title1: "Participantes -",
						title2: "Cargos | Quantidade de cada cargo",
					},
					success: (isEdit?: boolean) => isEdit ? "Safety Huddle editado com sucesso" : "Safety Huddle criado com sucesso",
				},
				details: {
					title: "Detalhes do Safety Huddle",
				},
			},
			multidisciplinaryVisit: {
				details: {
					title: "Visita Multidisciplinar",
					titlePatient: "Dados do Paciente",
				},
				table: {
					title: "Gerir Visitas Muldisciplinares",
					header: (patient: boolean) => patient ? ["Nome do paciente", "ID", "Leito", "Data"] : ["ID", "Leito", "Data"],
					delete: "Visita multidisciplinar foi deletado!",
					modalDescription: (code: string) => `Tem certeza que deseja excluir a visita multidisciplinar do paciente ${code}`,
				},
				create: {
					titleCard1: "Paciente",
					titleCard2: "O que o paciente precisa para receber alta da UTI?",
					titleCard3: "Analgesia e Sedação delirium",
					titleCard4: "Cardiovascular",
					titleCard5: "Pulmonar",
					titleCard6: "Mobilização",
					titleCard7: "Renal",
					titleCard8: "Hemato e infeccioso",
					titleCard9: "Glicemias e Nutrição",
					titleCard10: "Profilaxias",
					titleCard11: "Integridade Cutânea",
					titleCard12: "Dispositivos",
					titleCard13: "Interconsultas",
					titleCard14: "Exames a realizar",
					titleCard15: "Comunicação com familiares",
					titleCard16: "Paciente Watcher?",
					fields: {
						dailyGoal: "Meta diária:",
						date: "Data:",
						fullname: "Nome completo:",
						CEa30: "CE a 30°:",
						result: "Resultado:",
						rass: "Rass Alvo:",
						cardiovascularLactate: "Lactato:",
						cardiovascularPas: "PAS:",
						cardiovascularPam: "PAM:",
						cardiovascularPad: "PAD:",
						cardiovascularHemodynamicMonitoring: "Monitorização Hemodinâmica:",
						pulmonaryTargetO2Saturation: "Saturação de 02 Alvo:",
						pulmonaryO2SupportActs: "Suporte de 02 Atual:",
						pulmonaryDisarm: "Desmame:",
						mobilizationReleased: "Liberado:",
						renalBhLast24Hours: "BH nas ultimas 24 horas:",
						renalBhTarget: "BH Alvo:",
						renalhd: "HD:",
						renalDiuretic: "Diurético:",
						hematousFever: "Febre:",
						hematousDoseOfAtb: "Dose ATB:",
						hematousHbFall: "Queda de Hb:",
						glucose140To180: "Glicemias 140 a 180:",
						glucoseLiberatedDiet: "Dieta liberada:",
						cvc: "CVC:",
						pami: "PAMI:",
						svd: "SVD:",
						phd: "P/HD:",
						deviceRemove: "Retirar:",
						homeCare: "Home Care:",
						palliation: "Paliação:",
					},
				},
			},
			login: {
				loginIn: "Login",
				recoveryPassword: "Recuperar Senha",
				success: (userName: string) => `Bem vindo ${userName}.`,
				fields: {
					email: "E-mail",
					password: "Senha",
				},
			},
			recoveryPage: {
				success: "Bem vindo!",
				title: "Recuperação de Senha",
				newAccess: "Novo Acesso",
				sendSuccess: "E-mail enviado com sucesso!",
				recoverPasswordButton: "Enviar e-mail",
				newPasswordButton: "Alterar Senha",
				email: "Email",
				token: "Token",
				validToken: "Token válido!",
				confirmTokenButton: "Confirmar Token",
				tokenButton: "Já possui um token?",
				noTokenButton: "Não possuo token",
				newPassword: "Nova senha",
				confirmNewPassword: "Confirme sua nova senha",
				samePasswordError: "As senhas não correspondem",
				successPassword: "Senha alterada com sucesso",
				shortPassword: "Senha muito curta",
			},
			beds: {
				table: {
					title: "Lista de Leitos",
					header: ["Número", "Disponibilidade"],
					addButtonText: "Cadastrar Leito",
					delete: (title: string) => `Leito ${title} foi deletado!`,
					available: (available: boolean) => available ? "Disponível" : "Ocupado",
					placeHolder: "Digitar Número do Leito",
				},
				create: {
					title: "Cadastrar Leito",
					success: "Leito criado com sucesso",
				},
			},
		},
		format,
	},

});

export default strings;
