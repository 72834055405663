import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	sixteenthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const SixteenthCard: React.FC<IProps> = observer((props) => {
	const { sixteenthCard } = props;

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.watcherPatient}
			detailsRows={[
				{
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(sixteenthCard.form.isPatientWatcher),
				},
			]}
		/>
	);
});
