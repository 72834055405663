import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CardBuilder, MedicalRecordCreateCards } from "~/components";
import { Button, Flex } from "@chakra-ui/react";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

export const Create: React.FC = observer(() => {
	const pageStrings = strings.pages.patients;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();

	const onSuccess = () => history.goBack();

	const save = () => {
		store.createMedicalRecord(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 3, sm: 16 }}
		>
			<CardBuilder
				title={pageStrings.create.title}
				cards={[
					{
						content: (
							<MedicalRecordCreateCards.FirstCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
								getPatientByCpf={store.getPatientByCpf}
								paginatedListShelf={store.bedsPaginatedList}
							/>
						),
					},
					{
						content: (
							<MedicalRecordCreateCards.SecondCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordCreateCards.ThirdCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordCreateCards.ForthCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordCreateCards.FifthCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},

				]}
			/>

			<Button
				minW="150px"
				my={25}
				onClick={save}
			>
				{strings.common.buttons.saveOrEdit()}
			</Button>
		</Flex>
	);
});
