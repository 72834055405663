import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { CardDetails } from "~/components/CardDetails";
import { IDetailsProps } from "~/components/DetailsRow";

interface IProps {
	medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient;
	isAdmin: boolean;
}

export const FirstCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { medicalRecord, isAdmin } = props;

	const getDetailsRow = (): IDetailsProps[] => {
		if (isAdmin) {
			const medicalRecordWithPatient = medicalRecord as api.MedicalRecord;

			return [
				{
					label: componentStrings.fields.patient,
					value: medicalRecordWithPatient.patient.fullname,
				},
				{
					label: componentStrings.fields.date,
					value: format.date(medicalRecordWithPatient.createdAt),
				},
				{
					label: componentStrings.fields.bed,
					value: medicalRecordWithPatient.bed.code,
				},
				{
					label: componentStrings.fields.id,
					value: medicalRecordWithPatient.code,
				},
				{
					label: componentStrings.fields.birthDay,
					value: format.date(medicalRecordWithPatient.patient.birthdate),
				},
				{
					label: componentStrings.fields.age,
					value: medicalRecordWithPatient.patient.age,
				},
				{
					label: componentStrings.fields.genre,
					value: medicalRecordWithPatient.patient.genre,
				},
				{
					label: componentStrings.fields.plan,
					value: medicalRecordWithPatient.form.plan,
				},
			];
		}

		return [
			{
				label: componentStrings.fields.date,
				value: format.date(medicalRecord.createdAt),
			},
			{
				label: componentStrings.fields.bed,
				value: medicalRecord.bed.code,
			},
			{
				label: componentStrings.fields.id,
				value: medicalRecord.code,
			},
			{
				label: componentStrings.fields.plan,
				value: medicalRecord.form.plan,
			},
		];
	};

	return (
		<CardDetails
			detailsRows={getDetailsRow()}
		/>
	);
});
