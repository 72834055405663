import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr, Td } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { PeriodFilter, Table, TableCellWithActionButtons } from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

export const TableView: React.FC = observer(() => {
	const store = useLocalObservable(() => new Store());
	const { authStore } = useGlobalStore();
	const pageStrings = strings.pages.safetyHuddle.table;
	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;
	const history = useHistory();

	const onGoToCreate = () => {
		history.push("create");
	};

	const onGoToEdit = (id: string) => {
		history.push(`edit/${id}`);
	};

	const onGoToDetails = (id: string) => {
		history.push(`details/${id}`);
	};

	const onSuccessExportCSV = (url: string) => {
		window.open(url, "blank");
	};

	const onExportCsv = () => {
		store.exportCsv(onSuccessExportCSV);
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={isAdmin ? onGoToCreate : undefined}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<PeriodFilter
						selectedStartDate={store.startDate.value}
						onChangeStartDate={store.startDate.setValue}
						selectedFinalDate={store.endDate.value}
						onChangeFinalDate={store.endDate.setValue}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.creator.name}</Td>
						<Td>{strings.format.utcDate(item.date)}</Td>
						<TableCellWithActionButtons
							onEdit={isAdmin ? () => onGoToEdit(item.id) : undefined}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				addButtonText={pageStrings.addButtonText}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
				exportCsv={
					isAdmin
						? {
							onClick: onExportCsv,
							isLoading: store.loader.isLoading,
						}
						: undefined
				}
			/>
		</Flex>
	);
});
