import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Grid,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";
import {
	EnumSelect,
	TextInput,
	RadioBoolean,
} from "~/components";

interface IProps {
	medicalRecordFormShelf: MedicalRecordFormShelf;
}

export const ThirdCard: React.FC<IProps> = observer((props) => {
	const { medicalRecordFormShelf } = props;
	const commonStrings = strings.pages.patients;

	return (
		<Flex direction="column" w="100%">
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
					lg:"repeat(3, 1fr)",
					xl: "repeat(4, 1fr)",
				}}
				gap={9}
				alignItems="center"
				mb={8}
			>
				<TextInput
					labelText={commonStrings.fields.apacheII}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("apache2").error}
					{...medicalRecordFormShelf.formShelf.field("apache2")}
				/>
				<TextInput
					labelText={commonStrings.fields.fragility}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("fragility").error}
					{...medicalRecordFormShelf.formShelf.field("fragility")}
				/>
				<TextInput
					labelText={commonStrings.fields.charlson}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("charlson").error}
					{...medicalRecordFormShelf.formShelf.field("charlson")}
				/>
				<TextInput
					labelText={commonStrings.fields.sofaday("1")}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("sofa1").error}
					{...medicalRecordFormShelf.formShelf.field("sofa1")}
				/>
				<TextInput
					labelText={commonStrings.fields.sofaday("2")}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("sofa2").error}
					{...medicalRecordFormShelf.formShelf.field("sofa2")}
				/>
				<TextInput
					labelText={commonStrings.fields.sofaday("3")}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("sofa3").error}
					{...medicalRecordFormShelf.formShelf.field("sofa3")}
				/>
				<TextInput
					labelText={commonStrings.fields.sofa}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("sofaDischarged").error}
					{...medicalRecordFormShelf.formShelf.field("sofaDischarged")}
				/>
				<TextInput
					labelText={commonStrings.fields.sapsIII}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("saps3").error}
					{...medicalRecordFormShelf.formShelf.field("saps3")}
				/>
			</Grid>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(3, 1fr)",
				}}
				alignItems="center"
				gap={10}
			>
				<RadioBoolean
					label={commonStrings.fields.eag}
					attributeShelf={medicalRecordFormShelf.isEag}
				/>

				<EnumSelect
					items={api.allValuesTevAdmission()}
					tranlateEnum={api.translateTevAdmission}
					onChangeEnum={medicalRecordFormShelf.tevAdmission.setValue}
					currentValue={medicalRecordFormShelf.tevAdmission.value}
					label={commonStrings.fields.tev}
					isOutiline
				/>
			</Grid>

		</Flex>
	);
});
