import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import FormSafetyHuddle from "./shelves/formSafetyHuddle";

const pageStrings = strings.pages.safetyHuddle.createOrEdit;

export default class Store {

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	public formSafetyHuddle = new FormSafetyHuddle();

	constructor(id?: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getSafetyHuddle(id);
		}
	}

	public getSafetyHuddle = async (id: string) => {
		this.loader.start();
		try {
			const safetyHuddle = await api.getSafetyHuddleById(id);
			this.formSafetyHuddle.setInitValues(safetyHuddle);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public createOrEditSafetyHuddle = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			if (this.id.value) {
				await api.editSafetyHuddle(this.id.value, this.formSafetyHuddle.createNewSafetyHuddle());
			} else {
				await api.createSafetyHuddle(this.formSafetyHuddle.createNewSafetyHuddle());
			}

			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {

			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formSafetyHuddle.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
