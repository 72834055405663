import CreateOrEdit from "./CreateOrEdit";
import TableView from "./TableView";
import Details from "./Details";

export const AdminUsers = {
	CreateOrEdit,
	TableView,
	Details,
};

