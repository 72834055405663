import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, useLocation } from "react-router-dom";
import { useAuthRoute } from "~/hooks/useAuthRoute";
import AdminRoutes from "./AdminRoutes";
import EmployeeRoutes from "./EmployeeRoutes";
import { Loading, Login, NewPassword, Recovery } from "~/pages";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
import SupervisorRoutes from "./SupervisorRoutes";

const App = observer(() => {
	const history = useHistory();
	const location = useLocation();

	const onSuccess = (currentAdmin: api.AdminUser) => {
		const routesToBeRedirect = ["/", "/login"];
		const hasToBeRedirected = routesToBeRedirect.includes(location.pathname);

		if (hasToBeRedirected) {
			switch (currentAdmin.adminType) {
				case api.AdminType.master:
					return history.push("/admin");
				case api.AdminType.employee:
					return history.push("/employee");
				case api.AdminType.supervisor:
					return history.push("/supervisor");
			}
		}
	};

	useAuthRoute(onSuccess);

	return (
		<Routes>
			<Route
				index
				element={<Loading />}
			/>
			<Route
				path="/admin/*"
				element={<AdminRoutes />}
			/>
			<Route
				path="/supervisor/*"
				element={<SupervisorRoutes />}
			/>
			<Route
				path="/employee/*"
				element={<EmployeeRoutes />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				path="/recovery"
				element={<Recovery />}
			/>
			<Route
				path="/recovery/token/:token"
				element={<NewPassword />}
			/>
		</Routes>
	);
});

export default App;
