import React from "react";
import { observer } from "mobx-react-lite";
import { VStack, StackProps, Heading, Flex } from "@chakra-ui/react";

import { Card, ICardProps } from "../Card";

interface ICards {
	title?: string;
	content: JSX.Element | null;
	props?: ICardProps;
}

interface IProps extends StackProps {
	cards: ICards[];
	title?: string;
}

export const CardBuilder: React.FC<IProps> = observer((props) => {
	const { cards, title, ...rest } = props;

	return (
		<VStack {...rest} w="100%">
			{title &&
				<Flex
					w="100%"
					maxW={{ base:"100%", md:"95%", lg: "80%" }}
					mb={5}
					alignItems="center"
					ml={3}
				>
					<Heading
						display="inline-block"
						size="lg"
						color="primary.500"
						mt={25}
					>
						{title}
					</Heading>
				</Flex>}
			{cards.map((item, index) => (
				<Card
					key={index}
					w="100%"
					maxW={{ base:"100%", md:"95%", lg:"80%" }}
					borderRadius={12}
					boxShadow="lg"
					display="flex"
					justifyContent="center"
					bg="white"
					p={5}
					{...item.props}
				>
					<Heading>{item.title}</Heading>
					{item.content}
				</Card>
			))}

		</VStack>
	);
});
