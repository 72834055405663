import React from "react";
import { observer } from "mobx-react-lite";
import { RadioBoolean, TextInput } from "~/components";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const NinthCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard9}
			</Text>
			<Grid
				gap={5}
				gridTemplateColumns={{
					base: "1fr",
					md:"repeat(3, 1fr)",
				}}
			>
				<RadioBoolean
					label={commonStrings.fields.glucose140To180}
					attributeShelf={multidisciplinaryVisit.glucose140To180}
					isLoading={!isAdmin}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("glucoseDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("glucoseDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("glucoseResults").error}
					{...multidisciplinaryVisit.formShelf.field("glucoseResults")}
				/>
				<RadioBoolean
					label={commonStrings.fields.glucoseLiberatedDiet}
					isLoading={!isAdmin}
					attributeShelf={multidisciplinaryVisit.glucoseLiberatedDiet}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
			</Grid>

		</Flex>
	);
});
