import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr, Td } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { Table, TableCellWithActionButtons } from "~/components";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.adminUserTable.table;
	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onGoToCreateAdminUser = () => {
		history.push("create");
	};

	const onGoToEditAdminUser = (id: string) => {
		history.push(`edit/${id}`);
	};

	const onGoToDetailsAdminUser = (id: string) => {
		history.push(`details/${id}`);
	};

	const openDialog = (admin: api.AdminUser) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(admin.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteAdminUser(admin.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateAdminUser}
				addButtonText={pageStrings.addButtonText}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<Td>{api.translateAdminType(item.adminType)}</Td>
						<Td>{strings.format.date(item.createdAt)}</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetailsAdminUser(item.id)}
							onEdit={() => onGoToEditAdminUser(item.id)}
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
