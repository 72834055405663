import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Grid,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";
import {
	RadioBoolean,
	CalendarPicker,
	EnumSelect,
	TextInput,
} from "~/components";

interface IProps {
	medicalRecordFormShelf: MedicalRecordFormShelf;
}

export const ForthCard: React.FC<IProps> = observer((props) => {
	const { medicalRecordFormShelf } = props;
	const componentStrings = strings.pages.patients;

	const formatDate = (date?: Date | null): string => {
		if (date) {
			return strings.format.date(date);
		}
		return componentStrings.nsa;
	};

	return (
		<Flex direction="column" w="100%">
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
					lg: "repeat(3, 1fr)",
					xl: "repeat(4, 1fr)",
				}}
				gap={9}
				alignItems="center"
				mb={8}
				w="100%"
			>
				<TextInput
					labelText={componentStrings.fields.watcher}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("watcher").error}
					{...medicalRecordFormShelf.formShelf.field("watcher")}
				/>
				<RadioBoolean
					label={componentStrings.fields.palliation}
					attributeShelf={medicalRecordFormShelf.palliation}
				/>

				<CalendarPicker
					label={componentStrings.fields.protocolOpeningDate}
					onChange={medicalRecordFormShelf.protocolOpeningDate.setValue}
					value={medicalRecordFormShelf.protocolOpeningDate.value}
					isOutiline
				/>

				<EnumSelect
					items={api.allValuesUnit()}
					tranlateEnum={api.translateUnit}
					onChangeEnum={medicalRecordFormShelf.protocolOpeningUnit.setValue}
					currentValue={medicalRecordFormShelf.protocolOpeningUnit.value || ""}
					label={componentStrings.fields.protocolOpeningUnit}
					isOutiline
				/>

				<CalendarPicker
					label={componentStrings.fields.cvcInstDate}
					onChange={medicalRecordFormShelf.cvcInstDate.setValue}
					value={medicalRecordFormShelf.cvcInstDate.value}
					showValue={formatDate}
				/>

				<CalendarPicker
					label={componentStrings.fields.cvcRetDate}
					onChange={medicalRecordFormShelf.cvcRetDate.setValue}
					value={medicalRecordFormShelf.cvcRetDate.value}
					showValue={formatDate}
				/>

				<CalendarPicker
					label={componentStrings.fields.totInstDate}
					onChange={medicalRecordFormShelf.totInstDate.setValue}
					value={medicalRecordFormShelf.totInstDate.value}
					showValue={formatDate}
				/>

				<CalendarPicker
					label={componentStrings.fields.totRetDate}
					onChange={medicalRecordFormShelf.totRetDate.setValue}
					value={medicalRecordFormShelf.totRetDate.value}
					showValue={formatDate}
				/>

				<CalendarPicker
					label={componentStrings.fields.svdInstDate}
					onChange={medicalRecordFormShelf.svdInstDate.setValue}
					value={medicalRecordFormShelf.svdInstDate.value}
					showValue={formatDate}
				/>

				<CalendarPicker
					label={componentStrings.fields.svdRetDate}
					onChange={medicalRecordFormShelf.svdRetDate.setValue}
					value={medicalRecordFormShelf.svdRetDate.value}
					showValue={formatDate}
				/>
				<RadioBoolean
					label={componentStrings.fields.LPPOnAdmission}
					attributeShelf={medicalRecordFormShelf.isLPPOnAdmission}
				/>
				<RadioBoolean
					label={componentStrings.fields.LPPDuringHospitalization}
					attributeShelf={medicalRecordFormShelf.isLPPDuringHospitalization}
				/>
			</Grid>
		</Flex>
	);
});
