import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Button,
	VStack,
	useToast,
} from "@chakra-ui/react";
import {
	Card,
	TextInput,
	Logo,
} from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";

export const Recovery: React.FC = observer(() => {
	const [hasToken, setHasToken] = React.useState(false);

	const toast = useToast();
	const { authStore } = useGlobalStore();
	const history = useHistory();

	const pageStrings = strings.pages.recoveryPage;
	const commonStrings = strings.common;

	const onValidateToken = () => {
		if (hasToken) {
			authStore.validateToken(() => {
				history.push(
					`/recovery/token/${authStore.receivedToken}`,
				);
			},
			(errorMessage: string) => toast({ status: "error", title: errorMessage }),
			);
		} else {
			authStore.sendNewPassword(() => {
				history.push("/recovery");
				setHasToken(!hasToken);
			},
			(errorMessage: string) => toast({ status: "error", title: errorMessage }),
			);
		}
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="gray.200"
		>
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base: "100vw", sm: "28rem" }}
				minH={{ base: "100vh", sm: "30rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				bgColor="white"
			>
				<VStack spacing={8} w="100%">
					<Logo />
					{!authStore.receivedToken && authStore.receivedToken.length === 0 && (
						<>
							{hasToken ? (
								<TextInput
									key={authStore.form.field("token").name}
									type="text"
									labelText={pageStrings.token}
									isDisabled={authStore.loader.isLoading}
									{...authStore.form.field("token")}
								/>
							) : (
								<TextInput
									key={authStore.form.field("email").name}
									type="email"
									labelText={pageStrings.email}
									isDisabled={authStore.loader.isLoading}
									{...authStore.form.field("email")}
								/>
							)}
							<Button
								isLoading={authStore.loader.isLoading}
								onClick={onValidateToken}
							>
								{hasToken
									? pageStrings.confirmTokenButton
									: pageStrings.recoverPasswordButton}
							</Button>
							<Button
								variant="link"
								colorScheme="primary"
								onClick={() => {
									setHasToken(!hasToken);
									authStore.form.fieldError.clearErrors();
								}}
							>
								{hasToken
									? pageStrings.noTokenButton
									: pageStrings.tokenButton}
							</Button>
							<Button
								variant="link"
								colorScheme="primary"
								onClick={() => history.push("/recovery")}
							>
								{commonStrings.buttons.backButton}
							</Button>
						</>
					)}
				</VStack>
			</Card>
		</Flex>
	);
});
