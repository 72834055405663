import React from "react";
import { observer } from "mobx-react-lite";
import { CardDetails } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	showMedicalRecord: api.ShowMedicalRecord | api.ShowMedicalRecordWithoutPatient | null;
	date?: Date | null;
}

export const FirstCard: React.FC<IProps> = observer((props) => {
	const { showMedicalRecord, date } = props;
	const commonStrings = strings.common;

	return (
		<CardDetails
			detailsRows={[
				{
					isOutline: false,
					label: commonStrings.fields.id,
					value: showMedicalRecord?.code,
				},
				{
					isOutline: false,
					label: commonStrings.fields.date,
					value: strings.format.date(date),
				},
			]}
		/>
	);
});
