import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	sixthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const SixthCard: React.FC<IProps> = observer((props) => {
	const { sixthCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.mobilization}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(sixthCard.form.mobilizationReleased),
					prefix: componentStrings.placeholders.isOpen,
				},
				{
					isOutline: true,
					label: null,
					value: sixthCard.form.mobilizationDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: sixthCard.form.mobilizationResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
			]}
		/>
	);
});
