import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.pages.beds;

export default class Store {

	public formShelf = new FormShelf({
		code: "",
	});

	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public setInitValues = (bed: api.Bed) => {
		this.formShelf = new FormShelf({
			code: bed.code,
		});
	};

	public createBed = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			const data = this.formShelf.getValues();
			await api.createANewBed({
				code: data.code,
			});

			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
