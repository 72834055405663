import React from "react";
import { observer } from "mobx-react-lite";
import { RadioBoolean } from "~/components";
import {
	Flex,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const SixteenCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard16}
			</Text>
			<RadioBoolean
				attributeShelf={multidisciplinaryVisit.isPatientWatcher}
				isLoading={!isAdmin}
				labelProps={{
					fontWeight: "bold",
					color: "gray.500",
				}}
			/>
		</Flex>
	);
});
