export { MedicalRecords } from "./MedicalRecords";
export { AdminUsers } from "./AdminsUsers";
export { AdminHome } from "./AdminHome";
export { Loading } from "./Loading";
export { Login } from "./Login";
export { Beds } from "./Beds";
export { AcessTable } from "./AccessMonitoring";
export { Recovery } from "./Recovery";
export { NewPassword } from "./NewPassword";
export { SafetyHuddle } from "./SafetyHuddle";
export { MultidisciplinaryVisit } from "./MultidisciplinaryVisit";
