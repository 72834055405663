import { Box, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";

interface IProps {
	label: string;
	value: string | number;
}

export const ShowInfoCard: React.FC<IProps> = observer((props) => {
	const { label, value } = props;

	return (
		<Box
			mb={5}
		>
			<Text
				color="primary.500"
				fontSize="md"
			>
				{label}
			</Text>
			<Box
				p={5}
				bg="white"
				color="secondary.500"
				borderWidth={1}
				borderColor="primary.500"
				rounded="lg"
			>
				<Text
					fontSize="lg"
					textAlign="center"
				>
					{value}
				</Text>
			</Box>
		</Box>
	);
});
