import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { NewSafetyHuddle } from "@startapp/healthsolutions-admin-api";

export default class FormSafetyHuddle {

	public formShelf = new FormShelf({
		date: "",
		positionDayLaborer: "",
		positionNurses: "",
		positionPsychologist: "",
		positionOnDuty: "",
		positionPhysicalTherapists: "",
		positionPharmacy: "",
		positionTechnical: "",
		positionAdm: "",
		positionOthers: "",
		amountOfwatcherPatientsOnTheUnit: "",
		watcherPatients: "",
		amountOfSeverePatientInTheUnit: "",
		newtherapiesimplementedInTheUti: "",
		amountOfDischargedPatientsThatDidnNotLeave: "",
		amountOfPatientsWithOrganDysfunction24Hours: "",
		amountOfInvasiveProceduresThatDidNotHappenYesterday: "",
		amountOfCommunicationProblemsWithyFamilyOrUti: "",
		amountOfImagingOrLabTestsThatDidNotHappen: "",
		amountOfAdverseEventsInTheLast24Hours: "",
		howToImproveTheQualityOfPatient: "",
	});

	public date = new AttributeShelf<Date>(new Date());
	public amountOfwatcherPatientsOnTheUnit = new AttributeShelf<number | null>(null);
	public watcherPatients = new AttributeShelf<string | null>(null);
	public amountOfSeverePatientInTheUnit = new AttributeShelf<number | null>(null);
	public newtherapiesimplementedInTheUti = new AttributeShelf<string | null>(null);
	public amountOfDischargedPatientsThatDidnNotLeave = new AttributeShelf<number | null>(null);
	public amountOfPatientsWithOrganDysfunction24Hours = new AttributeShelf<number | null>(null);
	public amountOfInvasiveProceduresThatDidNotHappenYesterday = new AttributeShelf<number | null>(null);
	public amountOfCommunicationProblemsWithyFamilyOrUti = new AttributeShelf<number | null>(null);
	public amountOfImagingOrLabTestsThatDidNotHappen = new AttributeShelf<number | null>(null);
	public amountOfAdverseEventsInTheLast24Hours = new AttributeShelf<number | null>(null);


	public creatorName = new AttributeShelf<string | null>(null);

	constructor(){
		makeAutoObservable(this);
	}

	public setInitValues = (safetyHuddle: api.SafetyHuddle) => {
		const data = this.formShelf.getValues();

		this.creatorName.setValue(safetyHuddle.creator.name);

		this.formShelf = new FormShelf({
			...data,
			watcherPatients: safetyHuddle.watcherPatients || "",
			positionAdm: safetyHuddle.positionAdm,
			positionDayLaborer: safetyHuddle.positionDayLaborer,
			positionNurses: safetyHuddle.positionNurses,
			positionOnDuty: safetyHuddle.positionOnDuty,
			positionOthers: safetyHuddle.positionOthers,
			positionPharmacy: safetyHuddle.positionPharmacy,
			positionPhysicalTherapists: safetyHuddle.positionPhysicalTherapists,
			positionPsychologist: safetyHuddle.positionPsychologist,
			positionTechnical: safetyHuddle.positionTechnical,
			howToImproveTheQualityOfPatient: safetyHuddle.howToImproveTheQualityOfPatient,
		});
		this.amountOfwatcherPatientsOnTheUnit.setValue(safetyHuddle.amountOfwatcherPatientsOnTheUnit);
		this.watcherPatients.setValue(safetyHuddle.watcherPatients);
		this.amountOfSeverePatientInTheUnit.setValue(safetyHuddle.amountOfSeverePatientInTheUnit);
		this.newtherapiesimplementedInTheUti.setValue(safetyHuddle.newtherapiesimplementedInTheUti);
		this.amountOfDischargedPatientsThatDidnNotLeave.setValue(safetyHuddle.amountOfDischargedPatientsThatDidnNotLeave);
		this.amountOfPatientsWithOrganDysfunction24Hours.setValue(safetyHuddle.amountOfPatientsWithOrganDysfunction24Hours);
		this.amountOfInvasiveProceduresThatDidNotHappenYesterday.setValue(safetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday);
		this.amountOfCommunicationProblemsWithyFamilyOrUti.setValue(safetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti);
		this.amountOfImagingOrLabTestsThatDidNotHappen.setValue(safetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen);
		this.amountOfAdverseEventsInTheLast24Hours.setValue(safetyHuddle.amountOfAdverseEventsInTheLast24Hours);
		this.date.setValue(safetyHuddle.date);
	};

	public createNewSafetyHuddle = (): NewSafetyHuddle => {
		const data = this.formShelf.getValues();

		return {
			date: this.date.value,
			positionDayLaborer: data.positionDayLaborer,
			positionNurses: data.positionNurses,
			positionPsychologist: data.positionPsychologist,
			positionOnDuty: data.positionOnDuty,
			positionPhysicalTherapists: data.positionPhysicalTherapists,
			positionPharmacy: data.positionPharmacy,
			positionTechnical: data.positionTechnical,
			positionAdm: data.positionAdm,
			positionOthers: data.positionOthers,
			amountOfwatcherPatientsOnTheUnit: this.amountOfwatcherPatientsOnTheUnit.value,
			watcherPatients: this.watcherPatients.value,
			amountOfSeverePatientInTheUnit: this.amountOfSeverePatientInTheUnit.value,
			newtherapiesimplementedInTheUti: this.newtherapiesimplementedInTheUti.value,
			amountOfDischargedPatientsThatDidnNotLeave: this.amountOfDischargedPatientsThatDidnNotLeave.value,
			amountOfPatientsWithOrganDysfunction24Hours: this.amountOfPatientsWithOrganDysfunction24Hours.value,
			amountOfInvasiveProceduresThatDidNotHappenYesterday: this.amountOfInvasiveProceduresThatDidNotHappenYesterday.value,
			amountOfCommunicationProblemsWithyFamilyOrUti: this.amountOfCommunicationProblemsWithyFamilyOrUti.value,
			amountOfImagingOrLabTestsThatDidNotHappen: this.amountOfImagingOrLabTestsThatDidNotHappen.value,
			amountOfAdverseEventsInTheLast24Hours: this.amountOfAdverseEventsInTheLast24Hours.value,
			howToImproveTheQualityOfPatient: data.howToImproveTheQualityOfPatient,
		};
	};
}
