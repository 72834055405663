import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.MultidisciplinaryVisit | api.MultidisciplinaryVisitWithoutPatient>;

	public loader = new LoaderShelf();

	constructor(id: string, isAdmin: api.AdminType | undefined) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, () => this.getMultidisciplinaryById(id,isAdmin),
			{
				onFetchError,
			},
		);

		if (id) {
			this.fetchModelShelf.fetchModel();
		}
	}

	public getMultidisciplinaryById = (id: string, isAdmin: api.AdminType | undefined) => {
		if (isAdmin === api.AdminType.master) {
			return api.getMultidisciplinaryVisitById(id);
		}
		return api.getMultidisciplinaryVisitByIdForNonAdmin(id);
	};

	public get modelItem() {
		return this.fetchModelShelf.model.value;
	}
}
