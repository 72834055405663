import { makeAutoObservable } from "mobx";
import { PaginatedListShelf, AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";

export default class Store {

	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.Log>;
	public startDate = new AttributeShelf(null, () => this.paginetedListShelf.fetchPage(0));
	public finalDate = new AttributeShelf(null, () => this.paginetedListShelf.fetchPage(0));

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllLogs(page, this.filterOptions),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	protected getDataItemsPerPage(page: number): Promise<api.Log[]> {
		return api.getAllLogs(page, this.filterOptions);
	}

	public get filterOptions(): api.FilterByPeriod {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
		};
	}

	public exportCsv = async (onSuccess: (url: string) => void) => {
		this.loader.tryStart();
		try {
			const csvUrl = await api.getExportCsvLogs(this.filterOptions);
			onSuccess(csvUrl);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
