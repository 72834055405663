import { Tr, Td } from "@chakra-ui/react";
import React from "react";
import { ITableCellAdmin } from "~/pages/MedicalRecords/TableView";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { TableCellWithActionButtons } from "../TableCellWithActionButtons";

export const TableCellMedicalRecord: React.FC<ITableCellAdmin<api.ShowMedicalRecord | api.ShowMedicalRecordWithoutPatient>> = (props) => {
	const {
		item,
		index,
		onGoToDetails,
		onGoToEdit,
		hasDetails,
		hasEdit,
	} = props;

	const getPatient = (): JSX.Element => {
		const isTypeShowMedicalRecord = "patient" in item;
		if (isTypeShowMedicalRecord) {
			return <Td>{item.patient.fullname}</Td>;
		}
		return <></>;
	};

	return (
		<Tr key={index}>
			{getPatient()}
			<Td>{item.code}</Td>
			<Td>{item.bed.code}</Td>
			<Td>{strings.format.date(item.createdAt)}</Td>
			<TableCellWithActionButtons
				{...hasDetails && { onView: () => onGoToDetails(item.id) }}
				{...hasEdit && { onEdit: () => onGoToEdit(item.id) }}
			/>
		</Tr>
	);
};
