import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showInfoToast } from "~/resources/toast";
import { useGlobalStore } from "../contexts/useGlobalContext";

export const useAuthAdminSupervisorRoute = (onSuccess?: () => void) => {
	const { authStore } = useGlobalStore();
	const history = useNavigate();
	useEffect(() => {
		if (!authStore.loader.isLoading && authStore.currentAdminUser) {
			if (authStore.currentAdminUser.adminType !== api.AdminType.supervisor) {
				history("/login", { replace: true });
				showInfoToast(strings.error.currentUserIsNotSupervisor);
				authStore.logout(() => {});
			}

			if (onSuccess) {
				onSuccess();
			}
		}
	}, [authStore.loader.isLoading, authStore.currentAdminUser]);

	return authStore.loader.isLoading;
};
