import React from "react";
import { Flex, Stack, Heading, FlexProps, StackProps } from "@chakra-ui/react";

import {
	Card,
	Loading,
} from "~/components";
import { ICardProps } from "~/components/Card";

export interface IProps extends Omit<FlexProps, "title"> {
	title?: {
		text: string;
		helper?: React.ReactElement;
		props?: FlexProps;
	};
	button?: React.ReactElement;
	isTable?: boolean;
	isLoading?: boolean;
	cardProps?: ICardProps;
	stackProps?: StackProps;
	children?: React.ReactNode;
}

export const CentralizedCard: React.FC<IProps> = (props) => {
	const {
		title,
		button,
		children,
		isTable,
		isLoading,
		cardProps,
		stackProps,
		...rest
	} = props;

	return (
		<Flex
			w="100%"
			flexDirection="column"
			alignItems="center"
			p={{ base: 3, sm: 16 }}
			{...rest}
		>
			{title && (
				<Flex
					w="100%"
					maxW={{ base:"100%", md:"75%", lg: "60%" }}
					mb={5}
					alignItems="center"
					{...title.props}
				>
					<Heading
						display="inline-block"
						size="lg"
						color="primary.500"
						mt={25}
					>
						{title.text}
					</Heading>
					{title.helper}
				</Flex>
			)}
			<Card
				w="100%"
				maxW={{ base:"100%", md:"75%", lg:"60%" }}
				p={{ base: isTable ? 0 : 6, sm: isTable ? 0 : 16 }}
				borderRadius={12}
				boxShadow={isTable ? 0 : "lg"}
				bg="white"
				{...cardProps}
			>
				{
					isLoading ? (
						<Loading />
					) : (
						<Stack w="100%" spacing={8} {...stackProps}>
							{children}
						</Stack>
					)
				}
			</Card>
			{button}
		</Flex>
	);
};
