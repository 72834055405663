import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { CardDetails } from "~/components/CardDetails";

interface IProps {
	medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient;
}

export const SecondCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { medicalRecord } = props;

	return (
		<CardDetails
			detailsRows={[
				{
					isOutline: true,
					label: componentStrings.fields.units,
					value: medicalRecord.form.unit,
				},
				{
					isOutline: true,
					label: componentStrings.fields.admissionDateOfHospital,
					value: format.date(medicalRecord.form.hospitalAdmissionDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.admissionDateOfUTI,
					value: format.date(medicalRecord.form.utiAdmissionDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.patientOrigin,
					value: medicalRecord.form.patientOrigin,
				},
				{
					label: componentStrings.fields.reasonForAdmissionOnTheUTI,
					value: medicalRecord.form.reasonForUTIAdmission,
				},
			]}
		/>
	);
});
