import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class MultidisciplinaryVisitFormShelf {
	public loader = new LoaderShelf();

	public idMultiVisit = new AttributeShelf<string>("");
	public cardiovascularHemodynamicMonitoring = new AttributeShelf<boolean>(false);
	public pulmonaryCe30 = new AttributeShelf<boolean>(false);
	public pulmonaryO2SupportActs = new AttributeShelf<api.O2SupportAct>(api.O2SupportAct.AA);
	public pulmonaryDisarm = new AttributeShelf<boolean>(false);
	public mobilizationReleased = new AttributeShelf<boolean>(false);
	public renalhd = new AttributeShelf<boolean>(false);
	public renalDiuretic = new AttributeShelf<boolean>(false);
	public renalBhLast24Hours = new AttributeShelf<boolean>(false);
	public hematousFever = new AttributeShelf<boolean>(false);
	public hematousDoseOfAtb = new AttributeShelf<boolean>(false);
	public hematousHbFall = new AttributeShelf<boolean>(false);
	public glucose140To180 = new AttributeShelf<boolean>(false);
	public glucoseLiberatedDiet = new AttributeShelf<boolean>(false);
	public devicesRemove = new AttributeShelf<boolean>(false);
	public familyCommunicationHomeCare = new AttributeShelf<boolean>(false);
	public familyCommunicationPalliation = new AttributeShelf<boolean>(false);
	public isPatientWatcher = new AttributeShelf<boolean>(false);
	public devicesCvcDate = new AttributeShelf<Date | null>(null);
	public dateCvcBlock = new AttributeShelf<boolean>(false);
	public devicesPamiDate = new AttributeShelf<Date | null>(null);
	public datePamiBlock = new AttributeShelf<boolean>(false);
	public devicesSvdDate = new AttributeShelf<Date | null>(null);
	public dateSvdBlock = new AttributeShelf<boolean>(false);
	public devicesPhdDate = new AttributeShelf<Date | null>(null);
	public datePhdBlock = new AttributeShelf<boolean>(false);

	public formShelf = new FormShelf({
		whatNeedsToBeDischargedDailyGoal: "",
		whatNeedsToBeDischargedResults: "",
		sedationDeliriumRassTarget: "",
		sedationDeliriumDailyGoal: "",
		sedationDeliriumResults: "",
		cardiovascularLactate: "",
		cardiovascularDailyGoal: "",
		cardiovascularResults: "",
		cardiovascularPam: "",
		cardiovascularPas: "",
		cardiovascularPad: "",
		pulmonaryDailyGoal: "",
		pulmonaryResults: "",
		pulmonaryTargetO2Saturation: "",
		mobilizationDailyGoal: "",
		mobilizationResults: "",
		renalBhTarget: "",
		renalDailyGoal: "",
		renalResults: "",
		hematousDailyGoal: "",
		hematousResults: "",
		glucoseDailyGoal: "",
		glucoseResults: "",
		prophylaxisDescription: "",
		prophylaxisDailyGoal: "",
		prophylaxisResults: "",
		skinIntegrityDescription: "",
		skinIntegrityDailyGoal: "",
		skinIntegrityResults: "",
		devicesDailyGoal: "",
		devicesResults: "",
		consultationsDescription: "",
		consultationsDailyGoal: "",
		consultationsResults: "",
		medicalExamsDescription: "",
		medicalExamsDailyGoal:  "",
		medicalExamsResults: "",
		familyCommunicationDescription: "",
		familyCommunicationDailyGoal: "",
		familyCommunicationResults: "",
	});

	constructor(){
		makeAutoObservable(this);
	}

	public setInitValues = (visit: api.MultidisciplinaryVisit) => {
		this.devicesPhdDate.setValue(visit.form.devicesPhdDate);
		this.devicesSvdDate.setValue(visit.form.devicesSvdDate);
		this.devicesPamiDate.setValue(visit.form.devicesPamiDate);
		this.devicesCvcDate.setValue(visit.form.devicesCvcDate);
		this.isPatientWatcher.setValue(visit.form.isPatientWatcher);
		this.familyCommunicationPalliation.setValue(visit.form.familyCommunicationPalliation);
		this.familyCommunicationHomeCare.setValue(visit.form.familyCommunicationHomeCare);
		this.devicesRemove.setValue(visit.form.devicesRemove);
		this.glucoseLiberatedDiet.setValue(visit.form.glucoseLiberatedDiet);
		this.glucose140To180.setValue(visit.form.glucose140To180);
		this.hematousHbFall.setValue(visit.form.hematousHbFall);
		this.hematousDoseOfAtb.setValue(visit.form.hematousDoseOfAtb);
		this.hematousFever.setValue(visit.form.hematousFever);
		this.renalBhLast24Hours.setValue(visit.form.renalBhLast24Hours);
		this.renalDiuretic.setValue(visit.form.renalDiuretic);
		this.mobilizationReleased.setValue(visit.form.mobilizationReleased);
		this.pulmonaryDisarm.setValue(visit.form.pulmonaryDisarm);
		this.pulmonaryO2SupportActs.setValue(visit.form.pulmonaryO2SupportActs);
		this.pulmonaryCe30.setValue(visit.form.pulmonaryCe30);
		this.cardiovascularHemodynamicMonitoring.setValue(visit.form.cardiovascularHemodynamicMonitoring);
		const data = this.formShelf.getValues();

		this.dateCvcBlock.setValue(!!this.devicesCvcDate);
		this.datePamiBlock.setValue(!!this.devicesPamiDate);
		this.dateSvdBlock.setValue(!!this.devicesSvdDate);
		this.datePhdBlock.setValue(!!this.devicesPhdDate);

		this.formShelf = new FormShelf({
			...data,
			whatNeedsToBeDischargedDailyGoal: visit.form.whatNeedsToBeDischargedDailyGoal,
			whatNeedsToBeDischargedResults: visit.form.whatNeedsToBeDischargedResults,
			sedationDeliriumRassTarget: visit.form.sedationDeliriumRassTarget,
			sedationDeliriumDailyGoal: visit.form.sedationDeliriumDailyGoal,
			sedationDeliriumResults: visit.form.sedationDeliriumResults,
			cardiovascularLactate: visit.form.cardiovascularLactate,
			cardiovascularDailyGoal: visit.form.cardiovascularDailyGoal,
			cardiovascularResults: visit.form.cardiovascularResults,
			cardiovascularPam: visit.form.cardiovascularPam,
			cardiovascularPas: visit.form.cardiovascularPas,
			cardiovascularPad: visit.form.cardiovascularPam,
			pulmonaryDailyGoal: visit.form.pulmonaryDailyGoal,
			pulmonaryResults: visit.form.pulmonaryResults,
			pulmonaryTargetO2Saturation: visit.form.pulmonaryTargetO2Saturation,
			mobilizationDailyGoal: visit.form.mobilizationDailyGoal,
			mobilizationResults: visit.form.mobilizationResults,
			renalBhTarget: visit.form.renalBhTarget,
			renalDailyGoal: visit.form.renalDailyGoal,
			renalResults: visit.form.renalResults,
			hematousDailyGoal: visit.form.hematousDailyGoal,
			hematousResults: visit.form.hematousResults,
			glucoseDailyGoal: visit.form.glucoseDailyGoal,
			glucoseResults: visit.form.glucoseResults,
			prophylaxisDescription: visit.form.prophylaxisDescription,
			prophylaxisDailyGoal: visit.form.prophylaxisDailyGoal,
			prophylaxisResults: visit.form.prophylaxisResults,
			skinIntegrityDescription: visit.form.skinIntegrityDescription,
			skinIntegrityDailyGoal: visit.form.skinIntegrityDailyGoal,
			skinIntegrityResults: visit.form.skinIntegrityResults,
			devicesDailyGoal: visit.form.devicesDailyGoal,
			devicesResults: visit.form.devicesResults,
			consultationsDescription: visit.form.consultationsDescription,
			consultationsDailyGoal: visit.form.consultationsDailyGoal,
			consultationsResults: visit.form.consultationsResults,
			medicalExamsDescription: visit.form.medicalExamsDescription,
			medicalExamsDailyGoal: visit.form.medicalExamsDailyGoal,
			medicalExamsResults: visit.form.medicalExamsResults,
			familyCommunicationDescription: visit.form.familyCommunicationDescription,
			familyCommunicationDailyGoal: visit.form.familyCommunicationDailyGoal,
			familyCommunicationResults: visit.form.familyCommunicationResults,
		});
	};

	public createEditMultidisciplinaryVisit = (): api.EditMultidisciplinaryVisit => {
		const data = this.formShelf.getValues();
		return {
			whatNeedsToBeDischargedDailyGoal: data.whatNeedsToBeDischargedDailyGoal,
			whatNeedsToBeDischargedResults: data.whatNeedsToBeDischargedResults,
			sedationDeliriumRassTarget: data.sedationDeliriumRassTarget,
			sedationDeliriumDailyGoal: data.sedationDeliriumDailyGoal,
			sedationDeliriumResults: data.sedationDeliriumResults,
			cardiovascularLactate: data.cardiovascularLactate,
			cardiovascularDailyGoal: data.cardiovascularDailyGoal,
			cardiovascularResults: data.cardiovascularResults,
			cardiovascularPam: data.cardiovascularPam,
			cardiovascularPas: data.cardiovascularPas,
			cardiovascularPad: data.cardiovascularPad,
			pulmonaryDailyGoal: data.pulmonaryDailyGoal,
			pulmonaryResults: data.pulmonaryResults,
			pulmonaryTargetO2Saturation: data.pulmonaryTargetO2Saturation,
			mobilizationDailyGoal: data.mobilizationDailyGoal,
			mobilizationResults: data.mobilizationResults,
			renalBhTarget: data.renalBhTarget,
			renalDailyGoal: data.renalDailyGoal,
			renalResults: data.renalResults,
			hematousDailyGoal: data.hematousDailyGoal,
			hematousResults: data.hematousResults,
			glucoseDailyGoal: data.glucoseDailyGoal,
			glucoseResults: data.glucoseResults,
			prophylaxisDescription: data.prophylaxisDescription,
			prophylaxisDailyGoal: data.prophylaxisDailyGoal,
			prophylaxisResults: data.prophylaxisResults,
			skinIntegrityDescription: data.skinIntegrityDescription,
			skinIntegrityDailyGoal: data.skinIntegrityDailyGoal,
			skinIntegrityResults: data.skinIntegrityResults,
			devicesDailyGoal: data.devicesDailyGoal,
			devicesResults: data.devicesResults,
			consultationsDescription: data.consultationsDescription,
			consultationsDailyGoal: data.consultationsDailyGoal,
			consultationsResults: data.consultationsResults,
			medicalExamsDescription: data.medicalExamsDescription,
			medicalExamsDailyGoal:  data.medicalExamsDailyGoal,
			medicalExamsResults: data.medicalExamsResults,
			familyCommunicationDescription: data.familyCommunicationDescription,
			familyCommunicationDailyGoal: data.familyCommunicationDailyGoal,
			familyCommunicationResults: data.familyCommunicationResults,
			cardiovascularHemodynamicMonitoring: this.cardiovascularHemodynamicMonitoring.value,
			pulmonaryCe30: this.pulmonaryCe30.value,
			pulmonaryO2SupportActs: this.pulmonaryO2SupportActs.value,
			pulmonaryDisarm: this.pulmonaryDisarm.value,
			mobilizationReleased: this.mobilizationReleased.value,
			renalhd: this.renalhd.value,
			renalDiuretic: this.renalDiuretic.value,
			renalBhLast24Hours: this.renalBhLast24Hours.value,
			hematousFever: this.hematousFever.value,
			hematousDoseOfAtb: this.hematousDoseOfAtb.value,
			hematousHbFall: this.hematousHbFall.value,
			glucose140To180: this.glucose140To180.value,
			glucoseLiberatedDiet: this.glucoseLiberatedDiet.value,
			devicesRemove: this.devicesRemove.value,
			familyCommunicationHomeCare: this.familyCommunicationHomeCare.value,
			familyCommunicationPalliation: this.familyCommunicationPalliation.value,
			isPatientWatcher: this.isPatientWatcher.value,
			devicesCvcDate: this.devicesCvcDate.value,
			devicesPamiDate: this.devicesPamiDate.value,
			devicesSvdDate: this.devicesSvdDate.value,
			devicesPhdDate: this.devicesPhdDate.value,
		};
	};

	public createMultidisciplinaryVisit = (medicalRecord: string): api.NewMultidisciplinaryVisit => {
		const data = this.formShelf.getValues();
		return {
			medicalRecordId: medicalRecord,
			form: {
				whatNeedsToBeDischargedDailyGoal: data.whatNeedsToBeDischargedDailyGoal,
				whatNeedsToBeDischargedResults: data.whatNeedsToBeDischargedResults,
				sedationDeliriumRassTarget: data.sedationDeliriumRassTarget,
				sedationDeliriumDailyGoal: data.sedationDeliriumDailyGoal,
				sedationDeliriumResults: data.sedationDeliriumResults,
				cardiovascularLactate: data.cardiovascularLactate,
				cardiovascularDailyGoal: data.cardiovascularDailyGoal,
				cardiovascularResults: data.cardiovascularResults,
				cardiovascularPam: data.cardiovascularPam,
				cardiovascularPas: data.cardiovascularPas,
				cardiovascularPad: data.cardiovascularPad,
				pulmonaryDailyGoal: data.pulmonaryDailyGoal,
				pulmonaryResults: data.pulmonaryResults,
				pulmonaryTargetO2Saturation: data.pulmonaryTargetO2Saturation,
				mobilizationDailyGoal: data.mobilizationDailyGoal,
				mobilizationResults: data.mobilizationResults,
				renalBhTarget: data.renalBhTarget,
				renalDailyGoal: data.renalDailyGoal,
				renalResults: data.renalResults,
				hematousDailyGoal: data.hematousDailyGoal,
				hematousResults: data.hematousResults,
				glucoseDailyGoal: data.glucoseDailyGoal,
				glucoseResults: data.glucoseResults,
				prophylaxisDescription: data.prophylaxisDescription,
				prophylaxisDailyGoal: data.prophylaxisDailyGoal,
				prophylaxisResults: data.prophylaxisResults,
				skinIntegrityDescription: data.skinIntegrityDescription,
				skinIntegrityDailyGoal: data.skinIntegrityDailyGoal,
				skinIntegrityResults: data.skinIntegrityResults,
				devicesDailyGoal: data.devicesDailyGoal,
				devicesResults: data.devicesResults,
				consultationsDescription: data.consultationsDescription,
				consultationsDailyGoal: data.consultationsDailyGoal,
				consultationsResults: data.consultationsResults,
				medicalExamsDescription: data.medicalExamsDescription,
				medicalExamsDailyGoal:  data.medicalExamsDailyGoal,
				medicalExamsResults: data.medicalExamsResults,
				familyCommunicationDescription: data.familyCommunicationDescription,
				familyCommunicationDailyGoal: data.familyCommunicationDailyGoal,
				familyCommunicationResults: data.familyCommunicationResults,
				cardiovascularHemodynamicMonitoring: this.cardiovascularHemodynamicMonitoring.value,
				pulmonaryCe30: this.pulmonaryCe30.value,
				pulmonaryO2SupportActs: this.pulmonaryO2SupportActs.value,
				pulmonaryDisarm: this.pulmonaryDisarm.value,
				mobilizationReleased: this.mobilizationReleased.value,
				renalhd: this.renalhd.value,
				renalDiuretic: this.renalDiuretic.value,
				renalBhLast24Hours: this.renalBhLast24Hours.value,
				hematousFever: this.hematousFever.value,
				hematousDoseOfAtb: this.hematousDoseOfAtb.value,
				hematousHbFall: this.hematousHbFall.value,
				glucose140To180: this.glucose140To180.value,
				glucoseLiberatedDiet: this.glucoseLiberatedDiet.value,
				devicesRemove: this.devicesRemove.value,
				familyCommunicationHomeCare: this.familyCommunicationHomeCare.value,
				familyCommunicationPalliation: this.familyCommunicationPalliation.value,
				isPatientWatcher: this.isPatientWatcher.value,
				devicesCvcDate: this.devicesCvcDate.value,
				devicesPamiDate: this.devicesPamiDate.value,
				devicesSvdDate: this.devicesSvdDate.value,
				devicesPhdDate: this.devicesPhdDate.value,
			},
		};
	};
}
