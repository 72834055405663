import React from "react";
import { observer } from "mobx-react-lite";
import { EnumSelect, RadioBoolean, TextInput } from "~/components";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";
import api from "~/resources/api";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const FifthCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard5}
			</Text>
			<Grid
				gap={5}
				gridTemplateColumns={{
					base: "1fr",
					md:"repeat(3, 1fr)",
				}}
			>
				<RadioBoolean
					label={commonStrings.fields.CEa30}
					attributeShelf={multidisciplinaryVisit.pulmonaryCe30}
					isLoading={!isAdmin}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("pulmonaryDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("pulmonaryDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("pulmonaryResults").error}
					{...multidisciplinaryVisit.formShelf.field("pulmonaryResults")}
				/>
				<TextInput
					labelText={commonStrings.fields.pulmonaryTargetO2Saturation}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					isDisabled={!isAdmin}
					errorText={multidisciplinaryVisit.formShelf.field("pulmonaryTargetO2Saturation").error}
					{...multidisciplinaryVisit.formShelf.field("pulmonaryTargetO2Saturation")}
				/>
				<EnumSelect
					color="gray.500"
					items={api.allValuesO2SupportAct()}
					tranlateEnum={api.translateO2SupportAct}
					isDisabled={!isAdmin}
					onChangeEnum={multidisciplinaryVisit.pulmonaryO2SupportActs.setValue}
					currentValue={multidisciplinaryVisit.pulmonaryO2SupportActs.value}
					label={commonStrings.fields.pulmonaryO2SupportActs}
					isOutiline
				/>
				<RadioBoolean
					label={commonStrings.fields.pulmonaryDisarm}
					isLoading={!isAdmin}
					attributeShelf={multidisciplinaryVisit.pulmonaryDisarm}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
			</Grid>

		</Flex>
	);
});
