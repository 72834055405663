import React from "react";
import { observer } from "mobx-react-lite";
import {
	Text,
	Flex,
	Grid,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";
import {
	Radio,
	RadioBoolean,
	EnumSelect,
	TextInput,
	CalendarPicker,
} from "~/components";

interface IProps {
	medicalRecordFormShelf: MedicalRecordFormShelf;
}

export const FifthCard: React.FC<IProps> = observer((props) => {
	const { medicalRecordFormShelf } = props;
	const commonStrings = strings.pages.patients;
	const alternativeBlockRadio = () => {
		if (medicalRecordFormShelf.death.value === null){
			medicalRecordFormShelf.death.setValue(new Date());
			return;
		}
		medicalRecordFormShelf.death.setValue(null);
	};

	return (
		<Flex direction="column" w="100%">
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
					lg: "repeat(3, 1fr)",
					xl: "repeat(4, 1fr)",
				}}
				gap={9}
			>
				<CalendarPicker
					label={commonStrings.fields.departureDate}
					onChange={medicalRecordFormShelf.departureDate.setValue}
					value={medicalRecordFormShelf.departureDate.value}
					isOutiline
				/>
				<EnumSelect
					items={api.allValuesExitLocation()}
					tranlateEnum={api.translateExitLocation}
					onChangeEnum={medicalRecordFormShelf.exitLocation.setValue}
					currentValue={medicalRecordFormShelf.exitLocation.value}
					label={commonStrings.fields.exitLocation}
					isOutiline
				/>
				<EnumSelect
					items={api.allValuesUnit()}
					tranlateEnum={api.translateUnit}
					onChangeEnum={medicalRecordFormShelf.outputUnit.setValue}
					currentValue={medicalRecordFormShelf.outputUnit.value}
					label={commonStrings.fields.outputUnit}
					isOutiline
				/>
				<Flex alignItems="flex-end" gap={3}>
					<Flex direction="column">
						<Text color="primary.500" fontWeight="bold">{commonStrings.fields.death}</Text>
						<Radio
							label={strings.common.no}
							isSelected={medicalRecordFormShelf.death.value === null ? true : false}
							pt={5}
							onClick={() => alternativeBlockRadio()}
						/>
					</Flex>
					<CalendarPicker
						isDisabled={medicalRecordFormShelf.death.value === null ? true : false}
						width="100%"
						onChange={medicalRecordFormShelf.death.setValue}
						value={medicalRecordFormShelf.death.value}
					/>
				</Flex>

				<TextInput
					labelText={commonStrings.fields.escore}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("escore").error}
					{...medicalRecordFormShelf.formShelf.field("escore")}
				/>
				<TextInput
					labelText={commonStrings.fields.nas}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="number"
					onChange={(e) => medicalRecordFormShelf.nas.setValue(parseInt(e.target.value))}
				/>
				<RadioBoolean
					label={commonStrings.fields.isSurgeryCenter}
					attributeShelf={medicalRecordFormShelf.isSurgeryCenter}
				/>
			</Grid>
		</Flex>
	);
});
