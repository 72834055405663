import { Tr, Td } from "@chakra-ui/react";
import React from "react";
import { ITableCellAdmin } from "~/pages/MultidisciplinaryVisit/TableView";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { TableCellWithActionButtons } from "../TableCellWithActionButtons";

export const TableCellMultidisciplinary: React.FC<ITableCellAdmin<api.ShowMultidisciplinaryVisit | api.ShowMultidisciplinaryVisitWithoutPatient>> = (props) => {
	const {
		item,
		index,
		openDialog,
		onGoToDetails,
		onGoToEdit,
		hasDelete,
		hasDetails,
		hasEdit,
	} = props;

	const getPatient = (): JSX.Element => {
		const medicalRecord = item.medicalRecord;
		const isTypeShowMedicalRecord = "patient" in medicalRecord;
		if (isTypeShowMedicalRecord) {
			return <Td>{medicalRecord.patient.fullname}</Td>;
		}
		return <></>;
	};

	return (

		<Tr key={index}>
			{getPatient()}
			<Td>{item.medicalRecord.code}</Td>
			<Td>{item.medicalRecord.bed.code}</Td>
			<Td>{strings.format.date(item.createdAt)}</Td>
			<TableCellWithActionButtons
				{...hasDelete && { onDelete: () => openDialog(item) }}
				{...hasDetails && { onView: () => onGoToDetails(item.id) }}
				{...hasEdit && { onEdit: () => onGoToEdit(item.id) }}
			/>
		</Tr>
	);
};
