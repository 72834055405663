import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	fourthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const FourthCard: React.FC<IProps> = observer((props) => {
	const { fourthCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.cardiovascular}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularLactate,
					prefix: componentStrings.placeholders.Lactate,
				},
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(fourthCard.form.cardiovascularHemodynamicMonitoring),
					prefix: componentStrings.placeholders.hemodynamicMonitoring,
				},
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularPas,
					prefix: componentStrings.placeholders.pas,
				},
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularPad,
					prefix: componentStrings.placeholders.pad,
				},
				{
					isOutline: true,
					label: null,
					value: fourthCard.form.cardiovascularPam,
					prefix: componentStrings.placeholders.pam,
				},
			]}
		/>
	);
});
