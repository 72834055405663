import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";

interface IProps {
	secondCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const SecondCard: React.FC<IProps> = observer((props) => {
	const { secondCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<CardDetails
			labelProps={{ position: "relative", top: -10 }}
			label={strings.components.multidisciplinaryVisit.labels.thePatientNeedToBeDischargedFromTheUTI}
			gridProps={{
				gridColumnStart: 1,
				paddingTop: 8,
			}}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: secondCard.form.whatNeedsToBeDischargedDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: secondCard.form.whatNeedsToBeDischargedResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
			]}
		/>
	);
});
