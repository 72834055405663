import React from "react";
import { observer } from "mobx-react-lite";
import { TextInput } from "~/components";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const ThirdCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard3}
			</Text>
			<Grid
				gap={5}
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(3, 1fr)",
				}}
			>
				<TextInput
					labelText={commonStrings.fields.rass}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					isDisabled={!isAdmin}
					errorText={multidisciplinaryVisit.formShelf.field("sedationDeliriumRassTarget").error}
					{...multidisciplinaryVisit.formShelf.field("sedationDeliriumRassTarget")}
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("sedationDeliriumDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("sedationDeliriumDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("sedationDeliriumResults").error}
					{...multidisciplinaryVisit.formShelf.field("sedationDeliriumResults")}
				/>

			</Grid>

		</Flex>
	);
});
