import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";

export default class Store {
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.ShowSafetyHuddle>;
	public startDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public endDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllSafetyHuddlesByFilterOptions(page, this.filterPeriod),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public get filterPeriod(): api.FilterByPeriod{
		return {
			startDate: this.startDate.value,
			endDate: this.endDate.value,
		};
	}

	public exportCsv = async (onSuccess: (url: string) => void) => {
		this.loader.tryStart();
		try {
			const csvUrl = await api.exportCsvSafetyHuddle(this.filterPeriod);
			onSuccess(csvUrl);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
