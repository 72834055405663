import { FirstCard } from "./FirstCard";
import { SecondCard } from "./SecondCard";
import { ThirdCard } from "./ThirdCard";
import { ForthCard } from "./ForthCard";
import { FifthCard } from "./FifthCard";
import { SixthCard } from "./SixthCard";
import { SeventhCard } from "./SeventhCard";
import { Eighth } from "./EighthCard";
import { NinthCard } from "./NinthCard";
import { TenthCard } from "./TenthCard";
import { EleventhCard } from "./EleventhCard";
import { TwelfthCard } from "./TwelfthCard";
import { ThirteenthCard } from "./ThirteenthCard";
import { FourteenthCard } from "./FourteenthCard";
import { FifteenthCard } from "./FifteenthCard";
import { SixteenCard } from "./SixteenCard";

export const MultidisciplinaryVisitCards = {
	FirstCard,
	SecondCard,
	ThirdCard,
	ForthCard,
	FifthCard,
	SixthCard,
	SeventhCard,
	Eighth,
	NinthCard,
	TenthCard,
	EleventhCard,
	TwelfthCard,
	ThirteenthCard,
	FourteenthCard,
	FifteenthCard,
	SixteenCard,
};
