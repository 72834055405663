import React from "react";
import { observer } from "mobx-react-lite";
import {
	AutoCompleteModal,
	CalendarPicker,
	EnumSelect,
	TextInput,
} from "~/components";
import {
	Box,
	Flex,
	Grid,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";
import { PaginatedListShelf } from "@startapp/mobx-utils";
import { Label } from "~/components/Label";
import format from "~/resources/format";

interface IProps {
	medicalRecordFormShelf: MedicalRecordFormShelf;
	paginatedListShelf: PaginatedListShelf<api.Bed>;
	getPatientByCpf:  (documentNumber: string) => Promise<void>;
}

export const FirstCard: React.FC<IProps> = observer((props) => {
	const { medicalRecordFormShelf, paginatedListShelf, getPatientByCpf } = props;
	const commonStrings = strings.pages.patients;
	const {
		isOpen,
		onClose,
		onOpen,
	} = useDisclosure();

	const validateCpf = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const removeCharactersExceptLetterNumber = (character: string) => character.replace(/[^a-zA-Z0-9]/g, "");
		medicalRecordFormShelf.cpf.setValue(e.target.value);
		const value = medicalRecordFormShelf.cpf.value;
		if (removeCharactersExceptLetterNumber(value).length === 11) {
			getPatientByCpf(value);
		}
	};
	const ageCalculator = (birth: Date) => {
		const currentYear = new Date().getFullYear();
		const subtract = birth.getFullYear() - currentYear;
		const convertToPositive = Math.abs(subtract);
		medicalRecordFormShelf.age.setValue(convertToPositive);
		return convertToPositive.toString();
	};

	const cpfWithoutCharacters = format.removeCharactersExceptLetterNumber(medicalRecordFormShelf.cpf.value);

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
					lg: "repeat(3, 1fr)",
				}}
				gap={6}
			>
				<TextInput
					labelText={commonStrings.fields.bed}
					placeholder={commonStrings.placeholder.bed}
					onClick={onOpen}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
					}}
					value={medicalRecordFormShelf.searchBed.value}
				/>
				<AutoCompleteModal
					isOpen={isOpen}
					onClose={onClose}
					header={
						<Box w="100%" my={5}>
							<Label
								color="primary.500"
								fontSize="sm"
								textAlign="center"
							>
								{commonStrings.create.bedLabel}
							</Label>
						</Box>
					}
					listProps={{
						data: paginatedListShelf.items,
						loading: paginatedListShelf.loader.isLoading,
						renderItem: (item) => (
							<Text
								key={item.id}
								onClick={() => {
									medicalRecordFormShelf.searchBed.setValue(item.code);
									medicalRecordFormShelf.bedId.setValue(item.id);
									onClose();
								}}
								cursor="pointer"
							>
								{item.code}
							</Text>
						),
						paginantionProps: {
							currentPage: paginatedListShelf.page,
							nextPage: paginatedListShelf.nextPage,
							prevPage: paginatedListShelf.previousPage,
							hasNextPage: paginatedListShelf.hasNextPage,
						},
					}}
				/>
				<TextInput
					labelText={commonStrings.fields.cpf}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
					}}
					type="text"
					onChange={(e)=> validateCpf(e)}
					mask="999.999.999-99"
				/>
				{
					medicalRecordFormShelf.bedId.value && cpfWithoutCharacters.length === 11 &&
						<>
							<TextInput
								isDisabled={medicalRecordFormShelf.isBlocked.value}
								labelText={commonStrings.fields.fullname}
								placeholder={commonStrings.placeholder.fullname}
								labelProps={{
									fontWeight: "bold",
									color: "primary.500",
								}}
								type="text"
								errorText={medicalRecordFormShelf.formShelf.field("fullname").error}
								{...medicalRecordFormShelf.formShelf.field("fullname")}
							/>
							<CalendarPicker
								width="100%"
								label={commonStrings.fields.birthdate}
								onChange={medicalRecordFormShelf.birthdate.setValue}
								value={medicalRecordFormShelf.birthdate.value}
								isOutiline
							/>

							<TextInput
								isDisabled={medicalRecordFormShelf.isBlocked.value}
								labelText={commonStrings.fields.age}
								isReadOnly
								cursor="default"
								labelProps={{
									fontWeight: "bold",
									color: "primary.500",
									mb: "10px",
								}}
								value={ageCalculator(medicalRecordFormShelf.birthdate.value)}
								type="number"
							/>
							<EnumSelect
								items={api.allValuesGenre()}
								tranlateEnum={api.translateGenre}
								onChangeEnum={medicalRecordFormShelf.genre.setValue}
								currentValue={medicalRecordFormShelf.genre.value}
								label={commonStrings.fields.genre}
								isOutiline
							/>
							<EnumSelect
								items={api.allValuesPlan()}
								tranlateEnum={api.translatePlan}
								onChangeEnum={medicalRecordFormShelf.plan.setValue}
								currentValue={medicalRecordFormShelf.plan.value}
								label={commonStrings.fields.plan}
								isOutiline
							/>
						</>
				}
			</Grid>

		</Flex>
	);
});
