import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { DescriptionDetails } from "~/components/DescriptionDetails";

interface IProps {
	fourteenthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const FourteenthCard: React.FC<IProps> = observer((props) => {
	const { fourteenthCard } = props;
	const componentStrings = strings.components.cardDetails;

	return (
		<DescriptionDetails
			label={strings.components.multidisciplinaryVisit.labels.medicalExames}
			description={fourteenthCard.form.medicalExamsDescription}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: fourteenthCard.form.medicalExamsDailyGoal,
					boxProps: {
						gridColumnStart: { base: "auto", xl: 2 },
					},
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: fourteenthCard.form.medicalExamsResults,
					boxProps: {
						gridColumnStart: { base: "auto", xl: 3 },
					},
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
			]}
		/>
	);
});
