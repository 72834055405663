import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Button, VStack, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
	Card,
	TextInput,
	Logo,
} from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";

export const Login: React.FC = observer(() => {
	const pageStrings = strings.pages.login;
	const toast = useToast();
	const { authStore } = useGlobalStore();
	const history = useNavigate();

	const onSuccess = (userName: string) => {
		toast({status: "success", title: pageStrings.success(userName)});
		authStore.loginRefresh.setValue(!authStore.loginRefresh.value);
	};

	const onError = (errorMessage: string) => {
		toast({status: "error", title: errorMessage});
	};

	const login = () => {
		authStore.login(onSuccess, onError);
	};

	const toRecovery = () => {
		history("/recovery");
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="gray.200"
		>
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base:"100vw", sm: "28rem" }}
				minH={{ base: "100vh", sm: "30rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				bgColor="white"
			>
				<VStack spacing="8" w="100%">
					<Logo />
					<TextInput
						labelText={pageStrings.fields.email}
						type="email"
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("email")}
						bgColor="gray.100"
						minWidth={{ base: 250, sm: 300 }}
					/>
					<TextInput
						labelText={pageStrings.fields.password}
						type="password"
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("password")}
						bgColor="gray.100"
						minWidth={{ base: 250, sm: 300 }}
					/>
					<Button
						w="12rem"
						onClick={login}
						rounded="xl"
						isLoading={authStore.loader.isLoading}
					>
						{pageStrings.loginIn}
					</Button>
					<Button
						colorScheme="secondary"
						variant="link"
						onClick={toRecovery}
					>
						{pageStrings.recoveryPassword}
					</Button>
				</VStack>
			</Card>
		</Flex>
	);
});
