import React from "react";
import { observer } from "mobx-react-lite";
import { RadioBoolean, TextArea, TextInput } from "~/components";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const FifteenthCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard15}
			</Text>
			<TextArea
				formControlProps={{ mb: 5 }}
				labelProps={{
					fontWeight: "bold",
				}}
				isDisabled={!isAdmin}
				errorText={multidisciplinaryVisit.formShelf.field("familyCommunicationDescription").error}
				{...multidisciplinaryVisit.formShelf.field("familyCommunicationDescription")}
			/>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md:"repeat(3, 1fr)",
				}}
				gap={5}
			>
				<RadioBoolean
					label={commonStrings.fields.homeCare}
					attributeShelf={multidisciplinaryVisit.familyCommunicationHomeCare}
					isLoading={!isAdmin}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("familyCommunicationDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("familyCommunicationDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("familyCommunicationResults").error}
					{...multidisciplinaryVisit.formShelf.field("familyCommunicationResults")}
				/>
				<RadioBoolean
					label={commonStrings.fields.palliation}
					attributeShelf={multidisciplinaryVisit.familyCommunicationPalliation}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
			</Grid>
		</Flex>
	);
});
