import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Grid,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";
import {
	RadioBoolean,
	TextInput,
	CalendarPicker,
} from "~/components";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const TwelfthCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard12}
			</Text>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
				}}
				gap={5}
				alignItems="flex-end"
			>
				<Flex direction={{base: "column", md: "row"}}>
					<RadioBoolean
						label={commonStrings.fields.cvc}
						attributeShelf={multidisciplinaryVisit.dateCvcBlock}
						isLoading={!isAdmin}
						labelProps={{
							fontWeight: "bold",
							color: "gray.500",
						}}
					/>
					<CalendarPicker
						mt={6}
						isDisabled={!multidisciplinaryVisit.dateCvcBlock.value || !isAdmin}
						width="100%"
						onChange={multidisciplinaryVisit.devicesCvcDate.setValue}
						value={multidisciplinaryVisit.devicesCvcDate.value}
						isOutiline
					/>
				</Flex>
				<Flex direction={{ base: "column", md: "row" }}>
					<RadioBoolean
						label={commonStrings.fields.pami}
						isLoading={!isAdmin}
						attributeShelf={multidisciplinaryVisit.datePamiBlock}
						labelProps={{
							fontWeight: "bold",
							color: "gray.500",
						}}
					/>
					<CalendarPicker
						mt={6}
						width="100%"
						isDisabled={!multidisciplinaryVisit.datePamiBlock.value || !isAdmin}
						onChange={multidisciplinaryVisit.devicesPamiDate.setValue}
						value={multidisciplinaryVisit.devicesPamiDate.value}
						isOutiline
					/>
				</Flex>
				<Flex direction={{ base: "column", md:"row" }}>
					<RadioBoolean
						label={commonStrings.fields.svd}
						attributeShelf={multidisciplinaryVisit.dateSvdBlock}
						labelProps={{
							fontWeight: "bold",
							color: "gray.500",
						}}
					/>
					<CalendarPicker
						mt={6}
						width="100%"
						isDisabled={!multidisciplinaryVisit.dateSvdBlock.value || !isAdmin}
						onChange={multidisciplinaryVisit.devicesSvdDate.setValue}
						value={multidisciplinaryVisit.devicesSvdDate.value}
						isOutiline
					/>
				</Flex>
				<Flex direction={{base: "column", md: "row"}}>
					<RadioBoolean
						label={commonStrings.fields.phd}
						attributeShelf={multidisciplinaryVisit.datePhdBlock || !isAdmin}
						labelProps={{
							fontWeight: "bold",
							color: "gray.500",
						}}
					/>
					<CalendarPicker
						mt={6}
						width="100%"
						isDisabled={!multidisciplinaryVisit.datePhdBlock.value || !isAdmin}
						onChange={multidisciplinaryVisit.devicesPhdDate.setValue}
						value={multidisciplinaryVisit.devicesPhdDate.value}
						isOutiline
					/>
				</Flex>
			</Grid>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md:"repeat(3, 1fr)",
				}}
				gap={5}
			>
				<RadioBoolean
					label={commonStrings.fields.deviceRemove}
					attributeShelf={multidisciplinaryVisit.devicesRemove}
					isLoading={!isAdmin}
					labelProps={{
						fontWeight: "bold",
						color: "gray.500",
					}}
				/>
				<TextInput
					labelText={commonStrings.fields.dailyGoal}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("devicesDailyGoal").error}
					{...multidisciplinaryVisit.formShelf.field("devicesDailyGoal")}
				/>
				<TextInput
					labelText={commonStrings.fields.result}
					labelProps={{
						fontWeight: "bold",
					}}
					type="text"
					errorText={multidisciplinaryVisit.formShelf.field("devicesResults").error}
					{...multidisciplinaryVisit.formShelf.field("devicesResults")}
				/>
			</Grid>
		</Flex>
	);
});
