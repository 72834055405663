import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class MedicalRecordFormShelf {

	public code = new AttributeShelf<string>("");
	public searchBed = new AttributeShelf<string>("");
	public isBlocked = new AttributeShelf<boolean>(false);
	public bedId = new AttributeShelf<string>("");
	public cpf = new AttributeShelf<string>("");
	public birthdate = new AttributeShelf<Date>(new Date());
	public age = new AttributeShelf<number>(0);
	public genre = new AttributeShelf<api.Genre>(api.Genre.male);
	public nas = new AttributeShelf<number>(0);
	public unit = new AttributeShelf<api.Unit>(api.Unit.emergencia);
	public plan = new AttributeShelf<api.Plan>(api.Plan.amilInter);
	public tevAdmission = new AttributeShelf<api.TevAdmission>(api.TevAdmission.moderate);
	public openingUnitSepsisProtocol = new AttributeShelf<api.Unit>(api.Unit.emergencia);
	public hospitalAdmissionDate = new AttributeShelf<Date>(new Date());
	public utiAdmissionDate = new AttributeShelf<Date>(new Date());
	public protocolOpeningDate = new AttributeShelf<Date>(new Date());
	public protocolOpeningUnit = new AttributeShelf<api.Unit>(api.Unit.none);
	public cvcInstDate = new AttributeShelf<Date | null>(null);
	public cvcRetDate = new AttributeShelf<Date | null>(null);
	public totInstDate = new AttributeShelf<Date | null>(null);
	public totRetDate = new AttributeShelf<Date | null>(null);
	public svdInstDate = new AttributeShelf<Date | null>(null);
	public svdRetDate = new AttributeShelf<Date | null>(null);
	public isEag = new AttributeShelf<boolean>(false);
	public palliation = new AttributeShelf<boolean>(false);
	public isSurgeryCenter = new AttributeShelf<boolean>(false);
	public isLPPOnAdmission = new AttributeShelf<boolean>(false);
	public isLPPDuringHospitalization = new AttributeShelf<boolean>(false);
	public departureDate = new AttributeShelf<Date | null>(null);
	public isDeath = new AttributeShelf<string>("");
	public death = new AttributeShelf<Date | null>(null);
	public outputUnit = new AttributeShelf<api.Unit>(api.Unit.none);
	public exitLocation = new AttributeShelf<api.ExitLocation>(api.ExitLocation.none);

	public formShelf = new FormShelf({
		fullname: "",
		bedId: "",
		patientOrigin: "",
		reasonForUTIAdmission: "",
		fragility: "",
		apache2: "",
		charlson: "",
		sofa1: "",
		sofa2: "",
		sofa3: "",
		sofaDischarged: "",
		saps3: "",
		watcher: "",
		escore: "",
		exitLocation: "",
		id: "",
	});

	constructor(){
		makeAutoObservable(this);
	}

	public setPatientInitialValues = (patient?: api.Patient) => {
		this.age.setValue(patient?.age || 0);
		this.genre.setValue(patient?.genre || api.Genre.male);
		this.birthdate.setValue(patient?.birthdate || new Date());
		const data = this.formShelf.getValues();
		this.formShelf = new FormShelf({
			...data,
			id: patient?.id || "",
			fullname: patient?.fullname || "",
		});
	};

	public setInitValues = (medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient) => {
		const isTypeMedicalRecord = "patient" in medicalRecord;
		const data = this.formShelf.getValues();

		this.formShelf = new FormShelf({
			...data,
			apache2: medicalRecord.form.apache2,
			charlson: medicalRecord.form.charlson,
			escore: medicalRecord.form.escore,
			fragility: medicalRecord.form.fragility,
			sofa1: medicalRecord.form.sofa1,
			sofa2: medicalRecord.form.sofa2,
			sofa3: medicalRecord.form.sofa3,
			sofaDischarged: medicalRecord.form.sofaDischarged,
			saps3: medicalRecord.form.saps3,
			reasonForUTIAdmission: medicalRecord.form.reasonForUTIAdmission,
			watcher: medicalRecord.form.watcher,
			patientOrigin: medicalRecord.form.patientOrigin,
		});

		this.searchBed.setValue(medicalRecord.bed.code);
		this.code.setValue(medicalRecord.code);
		this.bedId.setValue(medicalRecord.bed.id);
		this.nas.setValue(medicalRecord.form.nsa);
		this.unit.setValue(medicalRecord.form.unit);
		this.plan.setValue(medicalRecord.form.plan);
		this.tevAdmission.setValue(medicalRecord.form.tevAdmission);
		this.openingUnitSepsisProtocol.setValue(medicalRecord.form.openingUnitSepsisProtocol);
		this.hospitalAdmissionDate.setValue(medicalRecord.form.hospitalAdmissionDate);
		this.utiAdmissionDate.setValue(medicalRecord.form.utiAdmissionDate);
		this.protocolOpeningDate.setValue(medicalRecord.form.protocolOpeningDate);
		this.protocolOpeningUnit.setValue(medicalRecord.form.openingUnitSepsisProtocol);
		this.cvcInstDate.setValue(medicalRecord.form.cvcInstDate);
		this.cvcRetDate.setValue(medicalRecord.form.cvcRetDate);
		this.totInstDate.setValue(medicalRecord.form.totInstDate);
		this.totRetDate.setValue(medicalRecord.form.totRetDate);
		this.svdInstDate.setValue(medicalRecord.form.svdInstDate);
		this.svdRetDate.setValue(medicalRecord.form.svdRetDate);
		this.isEag.setValue(medicalRecord.form.isEag);
		this.palliation.setValue(medicalRecord.form.palliation);
		this.isSurgeryCenter.setValue(medicalRecord.form.isSurgeryCenter);
		this.isLPPOnAdmission.setValue(medicalRecord.form.isLPPOnAdmission);
		this.isLPPDuringHospitalization.setValue(medicalRecord.form.isLPPDuringHospitalization);
		this.departureDate.setValue(medicalRecord.form.departureDate);
		this.death.setValue(medicalRecord.form.death);
		this.outputUnit.setValue(medicalRecord.form.outputUnit);

		if (isTypeMedicalRecord) {
			this.age.setValue(medicalRecord.patient.age);
			this.genre.setValue(medicalRecord.patient.genre);
			this.cpf.setValue(medicalRecord.patient.documentNumber);
			this.birthdate.setValue(medicalRecord.patient.birthdate);
			this.formShelf = new FormShelf({
				...data,
				id: medicalRecord.patient.id,
				fullname: medicalRecord.patient.fullname,
			});
		}
	};

	public createNewMedicalRecord = (): api.NewMedicalRecord => {
		const data = this.formShelf.getValues();
		return {
			patient: {
				id: data.id,
				age: this.age.value,
				fullname: data.fullname,
				documentNumber: this.cpf.value,
				genre: this.genre.value,
				birthdate: this.birthdate.value,
			},
			bedId: this.bedId.value,
			form: {
				patientOrigin: data.patientOrigin,
				reasonForUTIAdmission: data.reasonForUTIAdmission,
				fragility: data.fragility,
				apache2: data.apache2,
				exitLocation: this.exitLocation.value,
				charlson: data.charlson,
				sofa1: data.sofa1,
				sofa2: data.sofa2,
				sofa3: data.sofa3,
				sofaDischarged: data.sofaDischarged,
				saps3: data.saps3,
				watcher: data.watcher,
				escore: data.escore,
				unit: this.unit.value,
				plan: this.plan.value,
				tevAdmission: this.tevAdmission.value,
				hospitalAdmissionDate: this.hospitalAdmissionDate.value,
				utiAdmissionDate: this.utiAdmissionDate.value,
				protocolOpeningDate: this.protocolOpeningDate.value,
				cvcInstDate: this.cvcInstDate.value,
				cvcRetDate: this.cvcRetDate.value,
				totInstDate: this.totInstDate.value,
				totRetDate : this.totRetDate.value,
				svdInstDate : this.svdInstDate.value,
				svdRetDate : this.svdRetDate.value,
				openingUnitSepsisProtocol: this.openingUnitSepsisProtocol.value,
				isEag: this.isEag.value,
				palliation: this.palliation.value,
				isSurgeryCenter: this.isSurgeryCenter.value,
				isLPPOnAdmission: this.isLPPOnAdmission.value,
				isLPPDuringHospitalization: this.isLPPDuringHospitalization.value,
				departureDate: this.departureDate.value,
				death: this.isDeath.value !== "1" ? this.death.value : null,
				outputUnit: this.outputUnit.value,
				nsa: this.nas.value,
			},
		};
	};

	public createEditMedicalRecord = (): api.EditMedicalRecord => {
		const data = this.formShelf.getValues();
		return {
			form: {
				patientOrigin: data.patientOrigin,
				reasonForUTIAdmission: data.reasonForUTIAdmission,
				fragility: data.fragility,
				apache2: data.apache2,
				charlson: data.charlson,
				sofa1: data.sofa1,
				sofa2: data.sofa2,
				sofa3: data.sofa3,
				sofaDischarged: data.sofaDischarged,
				saps3: data.saps3,
				watcher: data.watcher,
				escore: data.escore,
				unit: this.unit.value,
				plan: this.plan.value,
				tevAdmission: this.tevAdmission.value,
				hospitalAdmissionDate: this.hospitalAdmissionDate.value,
				utiAdmissionDate: this.utiAdmissionDate.value,
				protocolOpeningDate: this.protocolOpeningDate.value,
				cvcInstDate: this.cvcInstDate.value,
				cvcRetDate: this.cvcRetDate.value,
				totInstDate: this.totInstDate.value,
				totRetDate : this.totRetDate.value,
				svdInstDate : this.svdInstDate.value,
				svdRetDate : this.svdRetDate.value,
				openingUnitSepsisProtocol: this.openingUnitSepsisProtocol.value,
				isEag: this.isEag.value,
				palliation: this.palliation.value,
				isSurgeryCenter: this.isSurgeryCenter.value,
				isLPPOnAdmission: this.isLPPOnAdmission.value,
				isLPPDuringHospitalization: this.isLPPDuringHospitalization.value,
				departureDate: this.departureDate.value,
				death: this.isDeath.value !== "1" ? this.death.value : null,
				outputUnit: this.outputUnit.value,
				exitLocation: this.exitLocation.value,
				nsa: this.nas.value,
			},
		};
	};

	public createEditMedicalRecordForAdmin = (): api.EditMedicalRecordForAdmin => {
		const data = this.formShelf.getValues();
		return {

			patient: {
				id: data.id,
				age: this.age.value,
				fullname: data.fullname,
				documentNumber: this.cpf.value,
				genre: this.genre.value,
				birthdate: this.birthdate.value,
			},
			form: this.createEditMedicalRecord().form,
		};
	};
}
