import { makeAutoObservable } from "mobx";
import { AttributeShelf, FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";
import { onFetchError } from "~/resources/fetchError";

export default class Store {
	public fetchModelShelf: FetchModelShelf<api.ShowRoutineManagement>;

	public multidisciplinaryVisit = new MultidisciplinaryVisitFormShelf();
	public loader = new LoaderShelf();

	public routineId = new AttributeShelf<string>("");

	constructor(routineId: string) {
		this.routineId.setValue(routineId);
		this.fetchModelShelf = new FetchModelShelf(
			this.routineId.value,
			api.getRoutineManagementById,
			{
				onFetchError,
				fetchOnConstructor: true,
			},
		) ;
		makeAutoObservable(this);
	}

	public createOrEditMultidisciplinaryVisit = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.multidisciplinaryVisit.createMultidisciplinaryVisit(this.fetchModelShelf.model.value?.medicalRecord.id || "");

			await api.createMultidisciplinaryVisit(data, this.routineId.value);

			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.multidisciplinaryVisit.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}

