import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export default class Store {
	public LoaderShelf = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.ShowRoutineManagement>;
	public startDate = new AttributeShelf<Date | null>(new Date(), () => this.paginetedListShelf.fetchPage(0));
	public finalDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public searchInput = new AttributeShelf<string>("");
	public typeSearch = new AttributeShelf<"Leito" | "ID">("Leito");

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllNotCompleteRoutineManagementByFilterOptions(page, this.getValueFilter()),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public getValueFilter = (): api.FilterOptions => ({
		startDate: this.startDate.value,
		endDate: this.finalDate.value,
		bedNumber: this.typeSearch.value === "Leito" ? this.searchInput.value : null,
		code: this.typeSearch.value === "ID" ? this.searchInput.value : null,
	});

}
