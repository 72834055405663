import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface ILinkMenu {
	text: string;
	path: string;
}

const useMainRoutes = (): ILinkMenu[] => {

	const routeAdmin = (path?: string) => `/admin${path ? "/" + path : ""}`;
	const routeSupervisor = (path?: string) => `/supervisor${path ? "/" + path : ""}`;
	const routeEmployee = (path?: string) => `/employee${path ? "/" + path : ""}`;

	const { authStore } = useGlobalStore();

	const adminRoutes = [
		{
			text: strings.nav.home,
			path: routeAdmin(),
		},
		{
			text: strings.nav.adminMaster,
			path: routeAdmin("adminUsers"),
		},
		{
			text: strings.nav.beds,
			path: routeAdmin("beds"),
		},
		{
			text: strings.nav.acessLogs,
			path: routeAdmin("acessLogs"),
		},
		{
			text: strings.nav.patients,
			path: routeAdmin("medicalRecords"),
		},
		{
			text: strings.nav.safetyHuddle,
			path: routeAdmin("safetyHuddles"),
		},
		{
			text: strings.nav.multidisciplinaryVisit,
			path: routeAdmin("multidisciplinaryVisit"),
		},
		{
			text: strings.nav.routineManagement,
			path: routeAdmin("routineManagement"),
		},
	];

	const employeeRoutes = [
		{
			text: strings.nav.home,
			path: routeEmployee(),
		},
		{
			text: strings.nav.patients,
			path: routeEmployee("medicalRecords"),
		},
		{
			text: strings.nav.safetyHuddle,
			path: routeEmployee("safetyHuddles"),
		},
		{
			text: strings.nav.multidisciplinaryVisit,
			path: routeEmployee("multidisciplinaryVisit"),
		},
	];

	const supervisorRoutes = [
		{
			text: strings.nav.home,
			path: routeSupervisor(),
		},
		{
			text: strings.nav.patients,
			path: routeSupervisor("medicalRecords"),
		},
		{
			text: strings.nav.safetyHuddle,
			path: routeSupervisor("safetyHuddles"),
		},
		{
			text: strings.nav.multidisciplinaryVisit,
			path: routeSupervisor("multidisciplinaryVisit"),
		},
	];

	if (!authStore.currentAdminUser) {
		return [];
	}

	switch (authStore.currentAdminUser.adminType) {
		case api.AdminType.master:
			return adminRoutes;
		case api.AdminType.supervisor:
			return supervisorRoutes;
		case api.AdminType.employee:
			return employeeRoutes;
		default:
			return [];
	}
};

export default useMainRoutes;
