import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";

export default class Store {

	public medicalRecordFormShelf = new MedicalRecordFormShelf();
	public loader = new LoaderShelf();

	public id = new AttributeShelf("");
	public checkParamRoutineId = new AttributeShelf("");
	public isAdmin = new AttributeShelf(false);

	constructor(id?: string, isAdmin?: boolean, checkParam?: string | null){
		makeAutoObservable(this);

		this.isAdmin.setValue(isAdmin || false);
		this.checkParamRoutineId.setValue(checkParam || "");

		if (id) {
			this.id.setValue(id);
			this.getMedicalRecord(id, isAdmin);
		}
	}

	public getEditMedicalRecord = (editMedicalRecord: api.EditMedicalRecordForAdmin): Promise<api.MedicalRecord> => {

		if (this.checkParamRoutineId.value) {
			return api.checkMedicalRecordRoutine(this.id.value, editMedicalRecord, this.checkParamRoutineId.value);
		}

		if (this.isAdmin.value) {
			return api.editMedicalRecord(this.id.value, editMedicalRecord);
		} else {
			return api.editMedicalRecordForEmployee(this.id.value, editMedicalRecord);
		}
	};

	public editMedicalRecord = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.medicalRecordFormShelf.createEditMedicalRecordForAdmin();

			await this.getEditMedicalRecord(data);

			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.medicalRecordFormShelf.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};

	public getMedicalRecord = async (id: string, isAdmin?: boolean) => {
		this.loader.start();
		this.medicalRecordFormShelf.isBlocked.setValue(false);
		try {
			const method = isAdmin ? api.getMedicalRecordById : api.getMedicalRecordByIdForNonAdmin;
			const medicalRecord = await method(id);
			this.medicalRecordFormShelf.setInitValues(medicalRecord);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};
}

