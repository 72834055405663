import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { CardDetails } from "~/components/CardDetails";

interface IProps {
	medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient;
}

export const FourthCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { medicalRecord } = props;

	return (
		<CardDetails
			gridProps={{ gap: 15 }}
			detailsRows={[
				{
					isOutline: true,
					label: componentStrings.fields.watcher,
					value: medicalRecord.form.watcher,
				},
				{
					isOutline: true,
					label: componentStrings.fields.palliation,
					value: componentStrings.isCondition(medicalRecord.form.palliation),
				},
				{
					isOutline: true,
					label: componentStrings.fields.protocolOpeningDate,
					value: format.date(medicalRecord.form.protocolOpeningDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.openingUnitSepsisProtocol,
					value: medicalRecord.form.openingUnitSepsisProtocol,
				},
				{
					isOutline: true,
					label: componentStrings.fields.cvcInstDate,
					value: format.date(medicalRecord.form.cvcInstDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.cvcRetDate,
					value: format.date(medicalRecord.form.cvcRetDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.totInstDate,
					value: format.date(medicalRecord.form.totInstDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.totRetDate,
					value: format.date(medicalRecord.form.totRetDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.svdInstDate,
					value: format.date(medicalRecord.form.svdInstDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.svdRetDate,
					value: format.date(medicalRecord.form.svdRetDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.isLPPOnAdmission,
					value: componentStrings.isCondition(medicalRecord.form.isLPPOnAdmission),
				},
				{
					isOutline: true,
					label: componentStrings.fields.isLPPDuringHospitalization,
					value: componentStrings.isCondition(medicalRecord.form.isLPPDuringHospitalization),
				},

			]}
		/>
	);
});
