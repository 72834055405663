import React from "react";
import { observer } from "mobx-react-lite";
import { AttributeShelf } from "@startapp/mobx-utils";
import { TextInput, QuestonInput } from "~/components";
import { IProps as IQuestionProps } from "~/components/QuestonInput";
import strings from "~/resources/strings";

interface IProps {
	isLoading: boolean;
	numberAtributeShelf: AttributeShelf<number | null>;
	errorText?: string;
	label?: string;
	questionProps?: IQuestionProps;
	isWatcher?: boolean;
}

export const NumberQuestionInput: React.FC<IProps> = observer((props) => {
	const {
		isLoading,
		numberAtributeShelf,
		label,
		questionProps,
		errorText,
		isWatcher,
	} = props;

	const commonStrings = strings.common;

	return (
		<QuestonInput
			label={label}
			isYesSelected={typeof numberAtributeShelf.value === "number"}
			onClickYes={() => numberAtributeShelf.setValue(1)}
			isNoSelected={typeof numberAtributeShelf.value !== "number"}
			isLoading={isLoading}
			onClickNo={() => numberAtributeShelf.setValue(null)}
			{...questionProps}
		>
			<TextInput
				labelText={commonStrings.fields.amount}
				type="number"
				labelProps={{ fontWeight: "bold", fontSize: "sm", color: "gray.600" }}
				boxProps={{ flexGrow: 1 }}
				isDisabled={isWatcher || typeof numberAtributeShelf.value !== "number"}
				onChange={(e) => {
					numberAtributeShelf.setValue(Number(e.target.value));
				}}
				errorText={errorText}
				value={numberAtributeShelf.value ? numberAtributeShelf.value.toString() : "0"}
			/>
		</QuestonInput>
	);
});

