import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "~/resources/api";
import { useGlobalStore } from "../contexts/useGlobalContext";

export const useAuthRoute = (onSuccess?: (currenAdminUser?: api.AdminUser) => void) => {
	const { authStore } = useGlobalStore();
	const location = useLocation();

	const onError = () => {
		if (location.pathname.includes("/recovery")){
			return ;
		}
		navigate("/login", { replace: true });
	};

	const navigate = useNavigate();
	useEffect(() => {
		authStore.authenticate(onSuccess, onError );
	}, [authStore.loginRefresh.value]);

	return authStore.loader.isLoading;
};
