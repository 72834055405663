import React from "react";
import { Store } from "./store";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CentralizedCard, DetailsRow, Table, RoutineCheckButton } from "~/components";
import {
	IconButton,
	Tooltip,
	Flex,
	Tr,
	Td,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";
import { showErrorToast } from "~/resources/toast";

export const DetailsView: React.FC = observer(() => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.routineManagement.details;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();

	const onClickMedicalRecord = (routineManagement: api.ShowRoutineManagement) => {
		if (routineManagement.isMedicalRecordChecked) {
			history.push(`/admin/medicalRecords/details/${routineManagement.medicalRecord.id}`);
			return;
		}

		history.push(`/admin/medicalRecords/edit/${routineManagement.medicalRecord.id}?check=${routineManagement.id}`);
	};

	const onClickSafetyHuddle = (routineManagement: api.ShowRoutineManagement) => {
		if (!routineManagement.isMedicalRecordChecked) {
			showErrorToast(pageStrings.medicalRecord);
			return;
		}

		if (routineManagement.safetyHuddleId) {
			history.push(`/admin/safetyHuddles/details/${routineManagement.safetyHuddleId}`);
			return;
		}

		history.push("/admin/safetyHuddles/create");
	};

	const onClickMultidisciplinary = (routineManagement: api.ShowRoutineManagement) => {
		if (!routineManagement.safetyHuddleId) {
			showErrorToast(pageStrings.safetyHuddleError);
			return;
		}
		if (!routineManagement.multidisciplinaryVisitId) {
			history.push(`/admin/multidisciplinaryVisit/create/${routineManagement.id}`);
			return;
		}

		history.push(`/admin/multidisciplinaryVisit/details/${routineManagement.multidisciplinaryVisitId}`);
	};

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 2, lg: 16 }}
		>
			<CentralizedCard
				mx={0}
				maxW={{ base:"100%", md: "75%" }}
				cardProps={{
					maxW: "100%",
					p: 8,
				}}
				stackProps={{
					direction: "row",
					spacing: { base: 8, md: 80 },
				}}
				p={0}
				title={{
					text: pageStrings.title,
					props: { maxW: "100%" },
					helper: (
						<Tooltip label={strings.common.edit}>
							<IconButton
								variant="icon"
								aria-label="Voltar"
								size="lg"
							/>
						</Tooltip>
					),
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<DetailsRow
								label={commonStrings.fields.bed}
								value={store.fetchModelShelf.model.value.bed.code}
							/>
							<DetailsRow
								label={commonStrings.fields.id}
								value={store.fetchModelShelf.model.value.code}
							/>
						</>
				}
			</CentralizedCard>

			<Table
				data={store.paginatedListShelf.items}
				loading={store.paginatedListShelf.loader.isLoading}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{strings.format.date(item.date)}</Td>
						<Td>
							<RoutineCheckButton
								label="1"
								onClick={() => onClickMedicalRecord(item)}
								isChecked={item.isMedicalRecordChecked}
							/>
						</Td>
						<Td>
							<RoutineCheckButton
								label="2"
								onClick={() => onClickSafetyHuddle(item)}
								isChecked={!!item.safetyHuddleId}
							/>
						</Td>
						<Td>
							<RoutineCheckButton
								label="3"
								onClick={() => onClickMultidisciplinary(item)}
								isChecked={!!item.multidisciplinaryVisitId}
							/>
						</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginatedListShelf.page}
				prevPage={store.paginatedListShelf.previousPage}
				nextPage={store.paginatedListShelf.nextPage}
				hasNextPage={store.paginatedListShelf.hasNextPage}
			/>
		</Flex>
	);
});
