
import { FirstCard } from "./FirstCard";
import { SecondCard } from "./SecondCard";
import { ThirdCard } from "./ThirdCard";
import { FourthCard } from "./FourthCard";
import { FifthCard } from "./FifthCard";
import { SixthCard } from "./SixthCard";
import { SeventhCard } from "./SeventhCard";
import { EighthCard } from "./EighthCard";
import { NinthCard } from "./NinthCard";
import { TenthCard } from "./TenthCard";
import { EleventhCard } from "./EleventhCard";
import { TwelfthCard } from "./TwelfthCard";
import { ThirteenthCard } from "./ThirteenthCard";
import { FourteenthCard } from "./FourteenthCard";
import { FifteenthCard } from "./FifteenthCard";
import { SixteenthCard } from "./SixteenthCard";

export const MultidisciplinaryVisitCardsDetails = {
	FirstCard,
	SecondCard,
	ThirdCard,
	FourthCard,
	FifthCard,
	SixthCard,
	SeventhCard,
	EighthCard,
	NinthCard,
	TenthCard,
	EleventhCard,
	TwelfthCard,
	ThirteenthCard,
	FourteenthCard,
	FifteenthCard,
	SixteenthCard,
};
