import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { CardDetails } from "~/components/CardDetails";

interface IProps {
	medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient;
}

export const ThirdCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { medicalRecord } = props;

	return (
		<CardDetails
			detailsRows={[
				{
					label: componentStrings.fields.apache2,
					value: medicalRecord.form.apache2,
				},
				{
					label: componentStrings.fields.fragility,
					value: medicalRecord.form.fragility,
				},
				{
					label: componentStrings.fields.charlson,
					value: medicalRecord.form.charlson,
				},
				{
					label: componentStrings.fields.sofa1,
					value: medicalRecord.form.sofa1,
				},
				{
					label: componentStrings.fields.sofa2,
					value: medicalRecord.form.sofa2,
				},
				{
					label: componentStrings.fields.sofa3,
					value: medicalRecord.form.sofa3,
				},
				{
					label: componentStrings.fields.sofaDischarged,
					value: medicalRecord.form.sofaDischarged,
				},
				{
					label: componentStrings.fields.saps3,
					value: medicalRecord.form.saps3,
				},
				{
					isOutline: true,
					label: componentStrings.fields.eag,
					value: componentStrings.isCondition(medicalRecord.form.isEag),
				},
				{
					isOutline: true,
					label: componentStrings.fields.tevAdmission,
					value: medicalRecord.form.tevAdmission,
				},
			]}
		/>
	);
});
