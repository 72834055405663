import React from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";
import Store from "./store";
import { ShowInfoCard } from "~/components";
import strings from "~/resources/strings";

export const AdminHome: React.FC = observer(() => {
	const store = useLocalStore(() => new Store());
	const pageStrings = strings.home;
	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="gray.200"
		>
			<ShowInfoCard
				label={pageStrings.occupacy}
				value={`${store.bedOccupacy.value.toFixed(2)}%`}
			/>
		</Flex>
	);
});

