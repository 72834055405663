import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { CentralizedCard, DetailsQuestionsSection, DetailsRow } from "~/components";
import { PositionSection } from "~/components/SafetyHuddleForm/PositionSection";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import { Store } from "./store";
import format from "~/resources/format";
import { Button } from "@chakra-ui/react";

export const DetailsView: React.FC = observer(() => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const pageStrings = strings.pages.safetyHuddle.details;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
				props: {
					maxW: { base:"100%", md:"80%" },
				},
			}}
			cardProps={{
				p: 10,
				maxW: { base:"100%", md:"80%" },
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSucess}
					variant="outline"
				>
					{commonStrings.buttons.backButton}
				</Button>
			)}
		>
			{
				store.fetchModelShelf.model.value &&
					<>
						<DetailsRow
							label={commonStrings.date}
							value={format.utcDate(store.fetchModelShelf.fetchedModel.date)}
						/>
						<DetailsRow
							label={commonStrings.fields.creator}
							value={store.fetchModelShelf.fetchedModel.creator.name}
						/>
						<PositionSection
							formSafetyHuddle={store.formSafetyHuddle}
							loader={store.loader}
							isDisabled
						/>
						<DetailsQuestionsSection
							detailsSafetyHuddle={store.fetchModelShelf.fetchedModel}
						/>
						<DetailsRow
							label={commonStrings.fields.howToImproveTheQualityOfPatient}
							value={store.fetchModelShelf.fetchedModel.howToImproveTheQualityOfPatient}
						/>
					</>
			}
		</CentralizedCard>
	);
});

