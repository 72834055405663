import React from "react";
import { observer } from "mobx-react-lite";
import { TextInput, TextArea } from "~/components";
import {
	Flex,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import MultidisciplinaryVisitFormShelf from "~/shelves/MultidisciplinaryVisitFormShelf";

interface IProps {
	multidisciplinaryVisit: MultidisciplinaryVisitFormShelf;
	isAdmin?: boolean;
}

export const TenthCard: React.FC<IProps> = observer((props) => {
	const { multidisciplinaryVisit, isAdmin } = props;
	const commonStrings = strings.pages.multidisciplinaryVisit.create;

	return (
		<Flex
			width="100%"
			direction="column"
		>
			<Text
				mb={3}
				fontWeight="bold"
				color="primary.500"
			>
				{commonStrings.titleCard10}
			</Text>
			<Flex direction="column">
				<TextArea
					formControlProps={{ mb: 5 }}
					labelProps={{
						fontWeight: "bold",
					}}
					isDisabled={!isAdmin}
					errorText={multidisciplinaryVisit.formShelf.field("prophylaxisDescription").error}
					{...multidisciplinaryVisit.formShelf.field("prophylaxisDescription")}
				/>
				<Flex
					justifyContent="right"
					gap={5}
					direction={{
						base: "column",
						md: "row",
					}}
				>
					<TextInput
						labelText={commonStrings.fields.dailyGoal}
						labelProps={{
							fontWeight: "bold",
						}}
						type="text"
						errorText={multidisciplinaryVisit.formShelf.field("prophylaxisDailyGoal").error}
						{...multidisciplinaryVisit.formShelf.field("prophylaxisDailyGoal")}
					/>
					<TextInput
						labelText={commonStrings.fields.result}
						labelProps={{
							fontWeight: "bold",
						}}
						type="text"
						errorText={multidisciplinaryVisit.formShelf.field("prophylaxisResults").error}
						{...multidisciplinaryVisit.formShelf.field("prophylaxisResults")}
					/>
				</Flex>

			</Flex>

		</Flex>
	);
});
