import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { CentralizedCard, TextInput, EnumSelect } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";

const CreateOrEdit: React.FC = () => {
	const { adminId } = useParams<{adminId: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(adminId));
	const pageStrings = strings.pages.adminUserTable.createOrEdit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.title(!!adminId) }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditAdmin(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!adminId)}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("email").error}
				{...store.formShelf.field("email")}
			/>
			{!adminId && (
				<>
					<EnumSelect
						items={api.allValuesAdminType()}
						tranlateEnum={api.translateAdminType}
						onChangeEnum={store.adminType.setValue}
						currentValue={store.adminType.value}
						label={commonStrings.fields.function}
					/>
					<TextInput
						labelText={commonStrings.fields.password}
						labelProps={{ fontWeight: "bold" }}
						labelColor="primary"
						type="password"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.field("password").error}
						{...store.formShelf.field("password")}
					/>
				</>
			)}
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
