import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { CardDetails } from "~/components/CardDetails";

interface IProps {
	medicalRecord: api.MedicalRecord | api.MedicalRecordWithoutPatient;
}

export const FifthCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.pages.patients.details;
	const { medicalRecord } = props;

	return (
		<CardDetails
			detailsRows={[
				{
					isOutline: true,
					label: componentStrings.fields.departureDate,
					value: format.date(medicalRecord.form.departureDate),
				},
				{
					isOutline: true,
					label: componentStrings.fields.outputLocation,
					value: medicalRecord.form.exitLocation,
				},
				{
					isOutline: true,
					label: componentStrings.fields.outputUnit,
					value: medicalRecord.form.outputUnit,
				},
				{
					isOutline: true,
					label: componentStrings.fields.death,
					value: format.date(medicalRecord.form.death),
				},
				{
					isOutline: true,
					label: componentStrings.fields.escore,
					value: medicalRecord.form.escore,
				},
				{
					isOutline: true,
					label: componentStrings.fields.nsa,
					value: medicalRecord.form.nsa,
				},
				{
					isOutline: true,
					label: componentStrings.fields.isSurgeryCenter,
					value: componentStrings.isCondition(medicalRecord.form.isSurgeryCenter),
				},
			]}
		/>
	);
});
