import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

const pageStrings = strings.pages.beds.table;

export default class Store {
	public paginetedListShelf: PaginatedListShelf<api.Bed>;

	public loader = new LoaderShelf();

	public code = new AttributeShelf("");

	public getAllBedsByFilterOptions = (page: number) => api.getAllBedsByFilterOptions(page, {code: this.code.value});

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllBedsByFilterOptions,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteBed = async (idCode: string) => {
		this.loader.start();
		try {
			const deletedBed = await api.deleteBed(idCode);
			showSuccessToast(pageStrings.delete(deletedBed.code));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
