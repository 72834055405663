import React from "react";
import { observer } from "mobx-react-lite";
import { CalendarPicker, EnumSelect, TextInput } from "~/components";
import {
	Box,
	Flex,
	Grid,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import MedicalRecordFormShelf from "~/shelves/MedicalRecordFormShelf";

interface IProps {
	medicalRecordFormShelf: MedicalRecordFormShelf;
}

export const SecondCard: React.FC<IProps> = observer((props) => {
	const { medicalRecordFormShelf } = props;
	const commonStrings = strings.pages.patients;

	return (
		<Flex w="100%" direction="column" justifyContent="space-between">
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "repeat(2, 1fr)",
					lg:"repeat(3, 1fr)",
				}}
				alignItems="center"
				gap={5}
				mb={8}
			>
				<Box w="100%">
					<EnumSelect
						items={api.allValuesUnit()}
						tranlateEnum={api.translateUnit}
						onChangeEnum={medicalRecordFormShelf.unit.setValue}
						currentValue={medicalRecordFormShelf.unit.value}
						label={commonStrings.fields.unit}
						isOutiline
					/>
				</Box>

				<CalendarPicker
					label={commonStrings.fields.internmentDateInHospital}
					onChange={medicalRecordFormShelf.hospitalAdmissionDate.setValue}
					value={medicalRecordFormShelf.hospitalAdmissionDate.value}
					isOutiline
				/>

				<CalendarPicker
					label={commonStrings.fields.internmentDateInUTI}
					onChange={medicalRecordFormShelf.utiAdmissionDate.setValue}
					value={medicalRecordFormShelf.utiAdmissionDate.value}
					isOutiline
				/>
			</Grid>
			<Grid
				gridTemplateColumns={{
					base: "1fr",
					md: "1fr 2fr",
				}}
				alignItems="center"
				gap={3}
			>
				<TextInput
					labelText={commonStrings.fields.patientOrigin}
					placeholder={commonStrings.placeholder.patientOrigin}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("patientOrigin").error}
					{...medicalRecordFormShelf.formShelf.field("patientOrigin")}
				/>
				<TextInput
					labelText={commonStrings.fields.reasonForAdmission}
					labelProps={{
						fontWeight: "bold",
						color: "primary.500",
						mb: "10px",
					}}
					type="text"
					errorText={medicalRecordFormShelf.formShelf.field("reasonForUTIAdmission").error}
					{...medicalRecordFormShelf.formShelf.field("reasonForUTIAdmission")}
				/>
			</Grid>
		</Flex>
	);
});
