import React from "react";
import { observer } from "mobx-react-lite";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import strings from "~/resources/strings";

import {
	useDisclosure,
	Text,
	Flex,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	FlexProps,
	TextProps,
	Box,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import { Label } from "../Label";

interface IProps extends Omit<FlexProps, "onChange"> {
	onChange: (dateSelected: Date) => void;
	showValue?: (value?: Date | null) => string;
	isDisabled?: boolean;
	isLoading?: boolean;
	label?: string;
	labelProps?: TextProps;
	value?: Date | null;
	isOutiline?: boolean;
}

export const CalendarPicker: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		value,
		showValue,
		isDisabled,
		isLoading,
		label,
		labelProps,
		isOutiline,
		...rest
	} = props;
	const { isOpen, onClose, onOpen } = useDisclosure();

	const showDefaultDateFormat = (currentValue?: Date | null): string => {
		if (!currentValue) {
			return strings.common.mask.date;
		}
		return strings.format.date(currentValue);
	};

	const isNotEditable = React.useMemo(() => isLoading || isDisabled || false, [isLoading, isDisabled]);

	const onSelectDate = (selectedValue: Date) => {
		onChange(selectedValue);
		onClose();
	};

	return (
		<>
			<Flex
				w="100%"
				flexDirection="column"
				cursor={isNotEditable ? "default" : "pointer"}
				{...rest}
			>
				<Label
					marginBottom={2.5}
					fontSize="sm"
					fontWeight="bold"
					color="primary.500"
					noOfLines={1}
					{...labelProps}
				>
					{label}
				</Label>
				<Box w="100%" h="40px" position="relative">
					<Text
						w="100%"
						borderColor="primary.500"
						bg="#F2F2F2"
						borderWidth={isOutiline ? 0.4 : 0}
						variant="outline"
						fontSize="md"
						color="gray.600"
						h="100%"
						px={4}
						py={1.5}
						borderRadius="md"
						onClick={isNotEditable ? undefined : onOpen}
					>
						{showValue && showValue(value) || showDefaultDateFormat(value)}
					</Text>
					<Image
						src="/calendar2.svg"
						position="absolute"
						right={4}
						top={2}
						w="22px"
						h="22px"
					/>
				</Box>
			</Flex>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="scale"
			>
				<ModalOverlay />
				<ModalContent mx={{ base: 2, md: 0 }}>
					<ModalBody p={0}>
						<Calendar
							className="calender"
							onChange={onSelectDate}
							value={value}
							locale="pt-br"
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
});

