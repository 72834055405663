import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class Store {
	public loader = new LoaderShelf();
	public bedOccupacy = new AttributeShelf(0);
	constructor() {
		makeAutoObservable(this);
		this.getOccupacyPercentage();
	}

	public getOccupacyPercentage = async () => {
		this.loader.start();
		try {
			const percentage = await api.getBedOccupancyPercentage();
			this.bedOccupacy.setValue(percentage);
		} catch (e) {
		} finally {
			this.loader.end();
		}
	};
}
