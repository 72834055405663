import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Button } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { PeriodFilter, SearchInput, Table } from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
interface IProps<DataType> {
	TableCell: React.FC<ITableCellAdmin<DataType>>;
	method: (page: number, filterOptions: api.FilterOptions) => Promise<DataType[]>;
	hasEdit?: boolean;
	hasDetails?: boolean;
}

export interface ITableCellAdmin<DataType> {
	item: DataType;
	index: number;
	onGoToDetails: (id: string) => void;
	onGoToEdit: (id: string) => void;
	hasEdit?: boolean;
	hasDetails?: boolean;
}

interface IEntity extends api.ShowMedicalRecordWithoutPatient{
	id: string;
}

export const TableView = observer( <DataType extends IEntity, >(props: IProps<DataType>) => {
	const { authStore } = useGlobalStore();
	const { TableCell, method, hasDetails, hasEdit } = props;

	const store = useLocalObservable(() => new Store(method));

	const pageStrings = strings.pages.patients.table;

	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;
	const history = useHistory();

	const onGoToDetails = (id: string) => history.push(`details/${id}`);

	const onGoToEdit = (id: string) => history.push(`edit/${id}`);
	const onGoToCreate = ()=> {
		history.push("create");
	};

	const onSuccessExportCSV = (url: string) => {
		window.open(url, "blank");
	};

	const onExportCsv = () => {
		store.exportCsv(onSuccessExportCSV);
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header(isAdmin)}
				headerFilter={
					<>
						<SearchInput
							placeholder={strings.components.searchInput.placeholder}
							select={{
								onSelect: store.typeSearch.setValue,
								optionsValues: ["Leito", "ID"],
								selectedValue: store.typeSearch.value,
							}}
							value={store.searchInput.value}
							onChange={store.searchInput.setValue}
							onClickSearch={()=> store.paginetedListShelf.fetchPage(0)}
						/>
						<PeriodFilter
							selectedStartDate={store.startDate.value}
							onChangeStartDate={store.startDate.setValue}
							selectedFinalDate={store.finalDate.value}
							onChangeFinalDate={store.finalDate.setValue}
						/>
					</>
				}
				renderRow={(item, index) => (
					<TableCell
						item={item}
						index={index}
						onGoToDetails={() => onGoToDetails(item.id)}
						onGoToEdit={() => onGoToEdit(item.id)}
						hasDetails={hasDetails}
						hasEdit={new Date(item.createdAt.toDateString()) >= new Date(new Date().toDateString()) ? hasEdit : false}
					/>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
				exportCsv={
					isAdmin
						? {
							onClick: onExportCsv,
							isLoading: store.loader.isLoading,
						}
						: undefined
				}
			/>
			{
				isAdmin &&
					<Button
						onClick={onGoToCreate}
						mt={10}
						alignSelf="center"
					>
						{strings.actions.createPatient}
					</Button>
			}
		</Flex>
	);
});
