import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.MedicalRecord>;
	public paginatedListShelf: PaginatedListShelf<api.ShowRoutineManagement>;
	public loader = new LoaderShelf();

	public code = new AttributeShelf("");

	constructor(id: string) {
		makeAutoObservable(this);

		this.paginatedListShelf = new PaginatedListShelf(
			(page) => api.getAllRoutineManagementByFilterOptions(page, this.filterOptions),
			{
				onFetchError,
			},
		);

		this.fetchModelShelf = new FetchModelShelf(id, api.getMedicalRecordById,
			{
				fetchOnConstructor: true,
				onAfterFetch: (item) => {
					this.code.setValue(item.code);
					this.paginatedListShelf.fetchPage(0);
				},
				onFetchError,
			},
		) ;
	}

	public get filterOptions(): api.FilterOptions{
		return {
			startDate: null,
			endDate: null,
			bedNumber: null,
			code: this.code.value,
		};
	}
}
