import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";

export default class Store<DataType> {
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<DataType>;
	public startDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public finalDate = new AttributeShelf<Date | null>(null, () => this.paginetedListShelf.fetchPage(0));
	public searchInput = new AttributeShelf<string>("");
	public typeSearch = new AttributeShelf<"Leito" | "ID">("Leito");

	constructor(getMultidisciplinary: (page: number, filterOptions: api.FilterOptions) => Promise<DataType[]>) {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => getMultidisciplinary(page, this.filterOptions),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public get filterOptions(): api.FilterOptions {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
			bedNumber: this.typeSearch.value === "Leito" ? this.searchInput.value : null,
			code: this.typeSearch.value === "ID" ? this.searchInput.value : null,
		};
	}

	public deleteMultidisciplinaryVisit = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteMultidisciplinaryVisitById(id);
			showSuccessToast(strings.pages.multidisciplinaryVisit.table.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public exportCsv = async (onSuccess: (url: string) => void) => {
		this.loader.tryStart();
		try {
			const csvUrl = await api.exportMultidisciplinaryVisit(this.filterOptions);
			onSuccess(csvUrl);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
