import React from "react";
import { observer } from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";
import { DetailsRow, QuestionDetails } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	detailsSafetyHuddle: api.SafetyHuddle;
}

export const DetailsQuestionsSection: React.FC<IProps> = observer((props) => {
	const { detailsSafetyHuddle } = props;
	const componentsStrings = strings.components.questionsSection;

	return (
		<Flex
			justifyContent={{ md: "space-between" }}
			alignItems="center"
			flexWrap="wrap"
		>
			<Flex
				w="100%"
				justifyContent="space-between"
				flexDirection={{ base: "column", lg: "row"}}
				py={10}
			>
				<QuestionDetails
					label={componentsStrings.labels.amountOfwatcherPatientsOnTheUnit}
					question={strings.components.questionsDetails.isConditionForAmount(detailsSafetyHuddle.amountOfwatcherPatientsOnTheUnit)}
				/>
				<DetailsRow
					label={strings.common.fields.which}
					value={detailsSafetyHuddle.watcherPatients}
					isOutline
					labelProps={{ fontWeight: "bold", fontSize: "sm", color: "gray.600" }}
					boxProps={{
						w: { md: "100%", lg: "45%" },
						position: "relative",
						top: 2,
						display: detailsSafetyHuddle.amountOfwatcherPatientsOnTheUnit ? "block" : "none",
						ml: { md: 0, lg: 20 },
						mt: { md: 5, lg: 0 },
					}}
				/>
			</Flex>

			<QuestionDetails
				label={componentsStrings.labels.amountOfSeverePatientInTheUnit}
				question={detailsSafetyHuddle.amountOfSeverePatientInTheUnit}
			/>

			<QuestionDetails
				label={componentsStrings.labels.newtherapiesimplementedInTheUti}
				question={detailsSafetyHuddle.newtherapiesimplementedInTheUti}
			/>

			<QuestionDetails
				label={componentsStrings.labels.amountOfDischargedPatientsThatDidnNotLeave}
				question={detailsSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave}
			/>

			<QuestionDetails
				label={componentsStrings.labels.amountOfPatientsWithOrganDysfunction24Hours}
				question={detailsSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours}
			/>

			<QuestionDetails
				label={componentsStrings.labels.amountOfInvasiveProceduresThatDidNotHappenYesterday}
				question={detailsSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday}
			/>

			<QuestionDetails
				label={componentsStrings.labels.amountOfCommunicationProblemsWithyFamilyOrUti}
				question={detailsSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti}
			/>

			<QuestionDetails
				label={componentsStrings.labels.amountOfImagingOrLabTestsThatDidNotHappen}
				question={detailsSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen}
			/>
			<QuestionDetails
				label={componentsStrings.labels.amountOfAdverseEventsInTheLast24Hours}
				question={detailsSafetyHuddle.amountOfAdverseEventsInTheLast24Hours}
			/>
		</Flex>
	);
});

