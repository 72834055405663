import React from "react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import { CardDetails } from "~/components/CardDetails";
import strings from "~/resources/strings";
import format from "~/resources/format";

interface IProps {
	twelfthCard: api.MultidisciplinaryVisitWithoutPatient;
}

export const TwelfthCard: React.FC<IProps> = observer((props) => {
	const { twelfthCard } = props;
	const componentStrings = strings.components.cardDetails;

	const isNotCondition = strings.components.multidisciplinaryVisit.isCondition(false);

	return (
		<CardDetails
			position
			labelProps={{ position: "absolute", top: -2}}
			label={strings.components.multidisciplinaryVisit.labels.devices}
			detailsRows={[
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesCvcDate ? format.date(twelfthCard.form.devicesCvcDate) : isNotCondition,
					prefix: componentStrings.placeholders.cvc,
				},
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesDailyGoal,
					prefix: componentStrings.placeholders.dailyGoal,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesResults,
					prefix: componentStrings.placeholders.result,
					isCyanBorder: true,
				},
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesSvdDate ? format.date(twelfthCard.form.devicesSvdDate) : isNotCondition,
					prefix: componentStrings.placeholders.svd,
				},
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesPhdDate ? format.date(twelfthCard.form.devicesPhdDate) : isNotCondition,
					prefix: componentStrings.placeholders.phd,
				},
				{
					isOutline: true,
					label: null,
					value: twelfthCard.form.devicesPamiDate ? format.date(twelfthCard.form.devicesPamiDate) : isNotCondition,
					prefix: componentStrings.placeholders.pami,
				},
				{
					isOutline: true,
					label: null,
					value: strings.components.multidisciplinaryVisit.isCondition(twelfthCard.form.devicesRemove),
					prefix: componentStrings.placeholders.deviceRemove,
				},
			]}
		/>
	);
});
