import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import { AdminHome, MedicalRecords, MultidisciplinaryVisit, SafetyHuddle } from "~/pages";
import { useAuthAdminSupervisorRoute } from "~/hooks/useAuthAdminSupervisorRoute";
import api from "~/resources/api";
import { TableCellMedicalRecord, TableCellMultidisciplinary } from "~/components";

const SupervisorRoutes = observer(() => {
	useAuthAdminSupervisorRoute();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<AdminHome />}
				/>
				<Route path="medicalRecords">
					<Route
						index
						element={
							<MedicalRecords.TableView
								TableCell={TableCellMedicalRecord}
								method={api.getAllMedicalRecordsByFilterOptionsForNonAdmin}
								hasDetails
							/>
						}
					/>
					<Route
						path="details/:id"
						element={<MedicalRecords.DetailsView />}
					/>
				</Route>
				<Route path="safetyHuddles">
					<Route
						index
						element={<SafetyHuddle.TableView />}
					/>
					<Route
						path="details/:id"
						element={<SafetyHuddle.DetailsView />}
					/>
				</Route>

				<Route path="multidisciplinaryVisit">
					<Route
						index
						element={
							<MultidisciplinaryVisit.TableView
								method={api.getAllMultidisciplinaryVisitByFilterOptionsForNonAdmin}
								TableCell={TableCellMultidisciplinary}
								hasDetails
							/>
						}
					/>
					<Route
						path="details/:id"
						element={<MultidisciplinaryVisit.DetailsView />}
					/>
				</Route>
			</Routes>
		</MainLayout>
	);
});

export default SupervisorRoutes;
