import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CardBuilder, MultidisciplinaryVisitCards } from "~/components";
import {
	Button,
	Flex,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import Store from "./store";
import { useParams } from "react-router-dom";
import { useHistory } from "~/hooks/useHistory";
import { showSuccessToast } from "~/resources/toast";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

export const Create: React.FC = observer(() => {
	const pageStrings = strings.pages.patients;
	const { id } = useParams();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();
	const { authStore } = useGlobalStore();
	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;
	const onSuccess = () => {
		showSuccessToast(pageStrings.create.success);
		history.goBack();
	};

	const save = () => {
		store.createOrEditMultidisciplinaryVisit(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 3, sm: 16 }}
		>
			<CardBuilder
				cards={[
					{
						content: (
							<MultidisciplinaryVisitCards.FirstCard
								showMedicalRecord={store.fetchModelShelf.model.value?.medicalRecord || null}
								date={store.fetchModelShelf.model.value?.date}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.SecondCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.ThirdCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.ForthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.FifthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.SixthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.SeventhCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.Eighth
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.NinthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.TenthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.EleventhCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.TwelfthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.ThirteenthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.FourteenthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.FifteenthCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MultidisciplinaryVisitCards.SixteenCard
								multidisciplinaryVisit={store.multidisciplinaryVisit}
								isAdmin={isAdmin}
							/>
						),
					},
				]}
			/>

			<Button
				minW="150px"
				my={25}
				onClick={save}
			>
				{strings.actions.save}
			</Button>
		</Flex>
	);
});
