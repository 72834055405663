import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { PeriodFilter, SearchInput, Table } from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";

interface IProps<DataType> {
	TableCell: React.FC<ITableCellAdmin<DataType>>;
	method: (page: number, filterOptions: api.FilterOptions) => Promise<DataType[]>;
	hasEdit?: boolean;
	hasDetails?: boolean;
	hasDelete?: boolean;
}

export interface ITableCellAdmin<DataType> {
	item: DataType;
	index: number;
	openDialog: (patient: api.ShowMultidisciplinaryVisitWithoutPatient) => void;
	onGoToDetails: (id: string) => void;
	onGoToEdit: (id: string) => void;
	hasEdit?: boolean;
	hasDetails?: boolean;
	hasDelete?: boolean;
}

interface IEntity extends api.ShowMultidisciplinaryVisitWithoutPatient{
	id: string;
}

export const TableView = observer( <DataType extends IEntity, >(props: IProps<DataType>) => {
	const { dialog, authStore } = useGlobalStore();
	const { TableCell, method, hasDelete, hasDetails, hasEdit } = props;
	const store = useLocalObservable(() => new Store(method));
	const pageStrings = strings.pages.multidisciplinaryVisit.table;
	const history = useHistory();

	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;

	const onGoToDetails = (id: string) => history.push(`details/${id}`);
	const onGoToEdit = (id: string) => history.push(`edit/${id}`);
	const onGoToRotineManagement = () => {
		history.push("/admin/routineManagement");
	};

	const openDialog = (multidisciplinaryVisit: api.ShowMultidisciplinaryVisitWithoutPatient) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: pageStrings.modalDescription(multidisciplinaryVisit.medicalRecord.code),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteMultidisciplinaryVisit(multidisciplinaryVisit.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSuccessExportCSV = (url: string) => {
		window.open(url, "blank");
	};

	const onExportCsv = () => {
		store.exportCsv(onSuccessExportCSV);
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header(!!isAdmin)}
				onAdd={isAdmin ? onGoToRotineManagement : undefined}
				headerFilter={
					<>
						<SearchInput
							placeholder={strings.components.searchInput.placeholder}
							select={{
								onSelect: store.typeSearch.setValue,
								optionsValues: ["Leito", "ID"],
								selectedValue: store.typeSearch.value,
							}}
							value={store.searchInput.value}
							onChange={store.searchInput.setValue}
							onClickSearch={()=> store.paginetedListShelf.fetchPage(0)}
						/>
						<PeriodFilter
							selectedStartDate={store.startDate.value}
							onChangeStartDate={store.startDate.setValue}
							selectedFinalDate={store.finalDate.value}
							onChangeFinalDate={store.finalDate.setValue}
						/>
					</>
				}
				renderRow={(item, index) => (
					<TableCell
						item={item}
						index={index}
						onGoToDetails={() => onGoToDetails(item.id)}
						onGoToEdit={() => onGoToEdit(item.id)}
						openDialog={() => openDialog(item)}
						hasDelete={hasDelete}
						hasDetails={hasDetails}
						hasEdit={hasEdit}
					/>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
				exportCsv={
					isAdmin
						? {
							onClick: onExportCsv,
							isLoading: store.loader.isLoading,
						}
						: undefined
				}
			/>
		</Flex>
	);
});
