import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	AdminUsers,
	AdminHome,
	Beds,
	AcessTable,
	MedicalRecords,
	SafetyHuddle,
	MultidisciplinaryVisit,
} from "~/pages";
import { useAuthAdminMasterRoute } from "~/hooks/useAuthAdminMasterRoute";
import { TableCellMedicalRecord, TableCellMultidisciplinary } from "~/components";
import api from "~/resources/api";
import { RoutineManagement } from "~/pages/RoutineManagement";

const AdminRoutes = observer(() => {
	useAuthAdminMasterRoute();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<AdminHome />}
				/>
				<Route path="medicalRecords">

					<Route
						index
						element={
							<MedicalRecords.TableView
								TableCell={TableCellMedicalRecord}
								method={api.getAllMedicalRecordsByFilterOptions}
								hasDetails
								hasEdit
							/>
						}
					/>
					<Route
						path="details/:id"
						element={<MedicalRecords.DetailsView />}
					/>
					<Route
						path="create"
						element={<MedicalRecords.Create />}
					/>
					<Route
						path="edit/:id"
						element={<MedicalRecords.Edit />}
					/>
				</Route>
				<Route path="routineManagement">

					<Route
						index
						element={<RoutineManagement.TableView />}
					/>
					<Route
						path="details/:id"
						element={<RoutineManagement.DetailsView />}
					/>
				</Route>

				<Route
					path="acessLogs"
					element={<AcessTable />}
				/>
				<Route path="adminUsers">
					<Route
						index
						element={<AdminUsers.TableView />}
					/>
					<Route
						path="create"
						element={<AdminUsers.CreateOrEdit />}
					/>
					<Route
						path="edit/:adminId"
						element={<AdminUsers.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<AdminUsers.Details />}
					/>
				</Route>
				<Route path="beds">
					<Route
						index
						element={<Beds.TableView />}
					/>
					<Route
						path="create"
						element={<Beds.Create />}
					/>
				</Route>

				<Route path="safetyHuddles">
					<Route
						index
						element={<SafetyHuddle.TableView />}
					/>
					<Route
						path="create"
						element={<SafetyHuddle.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<SafetyHuddle.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<SafetyHuddle.DetailsView />}
					/>
				</Route>

				<Route path="multidisciplinaryVisit">
					<Route
						index
						element={
							<MultidisciplinaryVisit.TableView
								method={api.getAllMultidisciplinaryVisitByFilterOptions}
								TableCell={TableCellMultidisciplinary}
								hasEdit
								hasDelete
								hasDetails
							/>
						}
					/>
					<Route
						path="create/:id"
						element={<MultidisciplinaryVisit.Create />}
					/>
					<Route
						path="edit/:id"
						element={<MultidisciplinaryVisit.Edit />}
					/>
					<Route
						path="details/:id"
						element={<MultidisciplinaryVisit.DetailsView />}
					/>
				</Route>

			</Routes>
		</MainLayout>
	);
});

export default AdminRoutes;
