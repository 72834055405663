import React from "react";
import { Store } from "./store";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CardBuilder } from "~/components";
import { Button, Flex} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import { MultidisciplinaryVisitCardsDetails } from "~/components/MultidisciplinaryVisitCardsDetails";
import { useGlobalStore } from "~/contexts/useGlobalContext";

export const DetailsView: React.FC = observer(() => {
	const pageStrings = strings.pages.multidisciplinaryVisit.details;
	const { authStore } = useGlobalStore();
	const currentUser = authStore.currentAdminUser?.adminType;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || "", currentUser));
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};
	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 2, sm: 16 }}
		>
			{store.modelItem && (
				<>
					<CardBuilder
						title={pageStrings.title}
						cards={[
							{
								content: (
									<MultidisciplinaryVisitCardsDetails.FirstCard
										firstCard={store.modelItem}
										currentUser={currentUser}
									/>
								),
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.SecondCard secondCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.ThirdCard thirdCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.FourthCard fourthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.FifthCard fifthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.SixthCard sixthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.SeventhCard seventhCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.EighthCard eighthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.NinthCard ninthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.TenthCard tenthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.EleventhCard eleventhCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.TwelfthCard twelfthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.ThirteenthCard thirteenthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.FourteenthCard fourteenthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.FifteenthCard fifteenthCard={store.modelItem} />,
							},
							{
								content: <MultidisciplinaryVisitCardsDetails.SixteenthCard sixteenthCard={store.modelItem} />,
							},
						]}
					/>
				</>
			)}
			<Button
				onClick={() => onSuccess()}
				w={{
					base: "50%",
					md: "15%",
				}}
				my={5}
				variant="outline"

			>
				{strings.common.buttons.backButton}
			</Button>
		</Flex>
	);
});
