import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CardBuilder } from "~/components";
import {
	Button,
	Flex,
	useToast,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import Store from "./store";
import { useParams, useSearchParams } from "react-router-dom";
import { MedicalRecordEditCards } from "~/components/MedicalRecordEditCards";
import { useHistory } from "~/hooks/useHistory";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

export const Edit: React.FC = observer(() => {
	const pageStrings = strings.pages.patients;
	const { authStore } = useGlobalStore();
	const { id } = useParams<{id: string}>();
	const [searchParams] = useSearchParams();
	const checkParam = searchParams.get("check");

	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;

	const store = useLocalObservable(() => new Store(id, isAdmin, checkParam));
	const toast = useToast();
	const history = useHistory();

	const onSuccess = () => {
		toast({status: "success", title: pageStrings.edit.success});
		history.goBack();
	};
	const save = () => {
		store.editMedicalRecord(onSuccess);
	};

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
			p={{ base: 3, sm: 16 }}
		>
			<CardBuilder
				title={pageStrings.create.title}
				cards={[
					{
						content: (
							<MedicalRecordEditCards.FirstCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
								isAdmin={isAdmin}
							/>
						),
					},
					{
						content: (
							<MedicalRecordEditCards.SecondCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordEditCards.ThirdCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordEditCards.ForthCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},
					{
						content: (
							<MedicalRecordEditCards.FifthCard
								medicalRecordFormShelf={store.medicalRecordFormShelf}
							/>
						),
					},

				]}
			/>

			<Button
				minW="150px"
				my={25}
				onClick={save}
			>
				{strings.common.buttons.saveOrEdit(!!id)}
			</Button>
		</Flex>
	);
});
