import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { CentralizedCard, TextArea, CalendarPicker, DetailsRow } from "~/components";
import { PositionSection } from "~/components/SafetyHuddleForm/PositionSection";
import { QuestionsSection } from "~/components/SafetyHuddleForm/QuestionsSection";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

export const CreateOrEdit: React.FC = observer(() => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const pageStrings = strings.pages.safetyHuddle.createOrEdit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
				props: {
					maxW: { base:"100%", md:"80%" },
				},
			}}
			isTable
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditSafetyHuddle(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
			cardProps={{
				p: 10,
				maxW: { base:"100%", md:"80%" },
			}}
		>
			<CalendarPicker
				onChange={store.formSafetyHuddle.date.setValue}
				value={store.formSafetyHuddle.date.value}
				isOutiline
				isDisabled={!!id}
				label={commonStrings.fields.date}
				w="200px"
			/>

			{!!id && (
				<DetailsRow
					label={commonStrings.fields.creator}
					value={store.formSafetyHuddle.creatorName.value}
				/>
			)}

			<PositionSection
				formSafetyHuddle={store.formSafetyHuddle}
				loader={store.loader}
			/>
			<QuestionsSection
				formSafetyHuddle={store.formSafetyHuddle}
				loader={store.loader}
			/>
			<TextArea
				labelText={commonStrings.fields.howToImproveTheQualityOfPatient}
				labelProps={{
					color:"primary.500",
					fontWeight: "bold",
				}}
				{...store.formSafetyHuddle.formShelf.field("howToImproveTheQualityOfPatient")}
			/>
		</CentralizedCard>
	);
});

