import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.MedicalRecord | api.MedicalRecordWithoutPatient>;

	public loader = new LoaderShelf();

	constructor(id: string, isAdmin?: boolean) {
		makeAutoObservable(this);

		this.fetchModelShelf = new FetchModelShelf(id, () => this.getMedicalRecord(id, isAdmin),
			{
				onFetchError,
			},
		);

		if (id) {
			this.fetchModelShelf.fetchModel();
		}
	}

	public getMedicalRecord = (id: string, isAdmin?: boolean) => {
		if (isAdmin) {
			return api.getMedicalRecordById(id);
		}
		return api.getMedicalRecordByIdForNonAdmin(id);
	};

	public get modelItem() {
		return this.fetchModelShelf.model.value;
	}
}
