import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Tr, Td } from "@chakra-ui/react";
import Store from "./store";
import strings from "~/resources/strings";
import { PeriodFilter, Table } from "~/components";
import format from "~/resources/format";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";

export const AcessTable: React.FC = observer(() => {
	const store = useLocalObservable(() => new Store());
	const { authStore } = useGlobalStore();
	const pageStrings = strings.pages.adminUserTable.logAcess.table;
	const isAdmin = authStore.currentAdminUser?.adminType === api.AdminType.master;

	const onSuccessExportCSV = (url: string) => {
		window.open(url, "blank");
	};

	const onExportCsv = () => {
		store.exportCsv(onSuccessExportCSV);
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				headers={pageStrings.header}
				headerFilter={
					<PeriodFilter
						selectedStartDate={store.startDate.value}
						onChangeStartDate={store.startDate.setValue}
						selectedFinalDate={store.finalDate.value}
						onChangeFinalDate={store.finalDate.setValue}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{format.date(item.createdAt)}</Td>
						<Td>{format.hour(item.createdAt)}</Td>
						<Td>{item.adminUser.name}</Td>
						<Td>{item.description}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
				exportCsv={
					isAdmin
						? {
							onClick: onExportCsv,
							isLoading: store.loader.isLoading,
						}
						: undefined
				}
			/>
		</Flex>
	);
});

