import React from "react";
import { observer } from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";
import FormSafetyHuddle from "~/pages/SafetyHuddle/CreateOrEdit/shelves/formSafetyHuddle";
import { LoaderShelf } from "@startapp/mobx-utils";
import { NumberQuestionInput } from "~/components";
import strings from "~/resources/strings";
import { QuestonInput } from "~/components/QuestonInput";
import { TextInput } from "~/components/TextInput";

interface IProps {
	loader: LoaderShelf;
	formSafetyHuddle: FormSafetyHuddle;
}

export const QuestionsSection: React.FC<IProps> = observer((props) => {
	const { formSafetyHuddle, loader } = props;

	const componentsStrings = strings.components.questionsSection;

	return (
		<Flex
			justifyContent={{ md: "space-between" }}
			alignItems="center"
			flexWrap="wrap"
		>

			<Flex
				w="100%"
				flexDirection={{ base: "column", md: "row" }}
				justifyContent="space-between"
			>
				<NumberQuestionInput
					questionProps={{
						w: { base: "100%", md: "45%" },
						mb: 5,
					}}
					label={componentsStrings.labels.amountOfwatcherPatientsOnTheUnit}
					numberAtributeShelf={formSafetyHuddle.amountOfwatcherPatientsOnTheUnit}
					isLoading={loader.isLoading}
					errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfwatcherPatientsOnTheUnit") || ""}
					isWatcher
				/>

				<TextInput
					labelText={strings.common.fields.which}
					labelProps={{ fontWeight: "bold", fontSize: "sm", color: "gray.600" }}
					boxProps={{
						w: { base: "100%", md: "45%" },
						mb: 5,
						alignSelf: "flex-end",
					}}
					isDisabled={typeof formSafetyHuddle.amountOfwatcherPatientsOnTheUnit.value !== "number"}
					onChange={(e) => {
						formSafetyHuddle.watcherPatients.setValue(e.target.value);
					}}
					value={formSafetyHuddle.watcherPatients.value || ""}
					errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("watcherPatients") || ""}
				/>
			</Flex>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfSeverePatientInTheUnit}
				numberAtributeShelf={formSafetyHuddle.amountOfSeverePatientInTheUnit}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfSeverePatientInTheUnit") || ""}
			/>

			<QuestonInput
				label={componentsStrings.labels.newtherapiesimplementedInTheUti}
				isYesSelected={typeof formSafetyHuddle.newtherapiesimplementedInTheUti.value === "string"}
				onClickYes={() => formSafetyHuddle.newtherapiesimplementedInTheUti.setValue("")}
				isNoSelected={typeof formSafetyHuddle.newtherapiesimplementedInTheUti.value !== "string"}
				isLoading={loader.isLoading}
				onClickNo={() => formSafetyHuddle.newtherapiesimplementedInTheUti.setValue(null)}
				w={{ base: "100%", md: "45%" }}
				mb={5}
			>
				<TextInput
					labelText={strings.common.fields.which}
					labelProps={{ fontWeight: "bold", fontSize: "sm", color: "gray.600" }}
					boxProps={{ flexGrow: 1 }}
					isDisabled={typeof formSafetyHuddle.newtherapiesimplementedInTheUti.value !== "string"}
					onChange={(e) => {
						formSafetyHuddle.newtherapiesimplementedInTheUti.setValue(e.target.value);
					}}
					value={formSafetyHuddle.newtherapiesimplementedInTheUti.value || ""}
					errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("newtherapiesimplementedInTheUti") || ""}
				/>
			</QuestonInput>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfDischargedPatientsThatDidnNotLeave}
				numberAtributeShelf={formSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfDischargedPatientsThatDidnNotLeave") || ""}
			/>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfPatientsWithOrganDysfunction24Hours}
				numberAtributeShelf={formSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfPatientsWithOrganDysfunction24Hours") || ""}
			/>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfInvasiveProceduresThatDidNotHappenYesterday}
				numberAtributeShelf={formSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfInvasiveProceduresThatDidNotHappenYesterday") || ""}
			/>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfCommunicationProblemsWithyFamilyOrUti}
				numberAtributeShelf={formSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfCommunicationProblemsWithyFamilyOrUti") || ""}
			/>

			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfImagingOrLabTestsThatDidNotHappen}
				numberAtributeShelf={formSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfImagingOrLabTestsThatDidNotHappen") || ""}
			/>
			<NumberQuestionInput
				questionProps={{ w: { base: "100%", md: "45%" }, mb: 5 }}
				label={componentsStrings.labels.amountOfAdverseEventsInTheLast24Hours}
				numberAtributeShelf={formSafetyHuddle.amountOfAdverseEventsInTheLast24Hours}
				isLoading={loader.isLoading}
				errorText={formSafetyHuddle.formShelf.fieldError.getFieldError("amountOfAdverseEventsInTheLast24Hours") || ""}
			/>
		</Flex>
	);
});

