import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Phone {
    ddd: string;
    number: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface FilterByPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface FilterOptions {
    bedNumber: string | null;
    code: string | null;
    startDate: Date | null;
    endDate: Date | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface AdminUser {
    id: string;
    createdAt: Date;
    adminType: AdminType;
    name: string;
    email: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
}

export interface NewAdminUser {
    password: string;
    adminType: AdminType;
    name: string;
    email: string;
}

export interface NewBed {
    code: string;
}

export interface Bed {
    id: string;
    available: boolean;
    createdAt: Date;
    updatedAt: Date;
    code: string;
}

export interface BedFilterOptions {
    code: string | null;
}

export interface Log {
    id: string;
    adminUser: AdminUser;
    description: string;
    createdAt: Date;
}

export interface NewPatient {
    fullname: string;
    age: number;
    birthdate: Date;
    genre: Genre;
    documentNumber: string;
}

export interface EditPatient {
    id: string;
    fullname: string;
    age: number;
    birthdate: Date;
    genre: Genre;
}

export interface Patient {
    id: string;
    fullname: string;
    age: number;
    birthdate: Date;
    genre: Genre;
    documentNumber: string;
}

export interface PatientAndCurrentMedicalRecord {
    currentMedicalId: string | null;
    id: string;
    fullname: string;
    age: number;
    birthdate: Date;
    genre: Genre;
    documentNumber: string;
}

export interface EditOrCreatePatient {
    id: string | null;
    fullname: string;
    age: number;
    birthdate: Date;
    genre: Genre;
    documentNumber: string;
}

export interface MedicalRecordForm {
    plan: Plan;
    unit: Unit;
    hospitalAdmissionDate: Date;
    utiAdmissionDate: Date;
    patientOrigin: string;
    reasonForUTIAdmission: string;
    apache2: string;
    fragility: string;
    charlson: string;
    sofa1: string;
    sofa2: string;
    sofa3: string;
    sofaDischarged: string;
    saps3: string;
    isEag: boolean;
    tevAdmission: TevAdmission;
    watcher: string;
    palliation: boolean;
    protocolOpeningDate: Date;
    openingUnitSepsisProtocol: Unit;
    isLPPOnAdmission: boolean;
    isLPPDuringHospitalization: boolean;
    departureDate: Date | null;
    outputUnit: Unit;
    isSurgeryCenter: boolean;
    death: Date | null;
    nsa: number;
    escore: string;
    cvcInstDate: Date | null;
    cvcRetDate: Date | null;
    totInstDate: Date | null;
    totRetDate: Date | null;
    svdInstDate: Date | null;
    svdRetDate: Date | null;
    exitLocation: ExitLocation;
}

export interface NewMedicalRecord {
    patient: EditOrCreatePatient;
    bedId: string;
    form: MedicalRecordForm;
}

export interface EditMedicalRecordForAdmin {
    patient: EditOrCreatePatient;
    form: MedicalRecordForm;
}

export interface EditMedicalRecord {
    form: MedicalRecordForm;
}

export interface MedicalRecord {
    id: string;
    code: string;
    patient: Patient;
    bed: Bed;
    form: MedicalRecordForm;
    createdAt: Date;
    updatedAt: Date;
    isHospitalized: boolean;
}

export interface ShowMedicalRecord {
    id: string;
    code: string;
    patient: Patient;
    bed: Bed;
    createdAt: Date;
    updatedAt: Date;
    isHospitalized: boolean;
}

export interface ShowMedicalRecordWithoutPatient {
    id: string;
    code: string;
    bed: Bed;
    createdAt: Date;
    updatedAt: Date;
    isHospitalized: boolean;
}

export interface MedicalRecordWithoutPatient {
    id: string;
    code: string;
    bed: Bed;
    form: MedicalRecordForm;
    createdAt: Date;
    updatedAt: Date;
    isHospitalized: boolean;
}

export interface MultidisciplinaryVisitForm {
    whatNeedsToBeDischargedDailyGoal: string;
    whatNeedsToBeDischargedResults: string;
    sedationDeliriumRassTarget: string;
    sedationDeliriumDailyGoal: string;
    sedationDeliriumResults: string;
    cardiovascularLactate: string;
    cardiovascularHemodynamicMonitoring: boolean;
    cardiovascularDailyGoal: string;
    cardiovascularResults: string;
    cardiovascularPam: string;
    cardiovascularPas: string;
    cardiovascularPad: string;
    pulmonaryCe30: boolean;
    pulmonaryO2SupportActs: O2SupportAct;
    pulmonaryDailyGoal: string;
    pulmonaryResults: string;
    pulmonaryDisarm: boolean;
    pulmonaryTargetO2Saturation: string;
    mobilizationReleased: boolean;
    mobilizationDailyGoal: string;
    mobilizationResults: string;
    renalhd: boolean;
    renalDiuretic: boolean;
    renalBhLast24Hours: boolean;
    renalBhTarget: string;
    renalDailyGoal: string;
    renalResults: string;
    hematousFever: boolean;
    hematousDoseOfAtb: boolean;
    hematousHbFall: boolean;
    hematousDailyGoal: string;
    hematousResults: string;
    glucose140To180: boolean;
    glucoseLiberatedDiet: boolean;
    glucoseDailyGoal: string;
    glucoseResults: string;
    prophylaxisDescription: string;
    prophylaxisDailyGoal: string;
    prophylaxisResults: string;
    skinIntegrityDescription: string;
    skinIntegrityDailyGoal: string;
    skinIntegrityResults: string;
    devicesRemove: boolean;
    devicesCvcDate: Date | null;
    devicesPamiDate: Date | null;
    devicesSvdDate: Date | null;
    devicesPhdDate: Date | null;
    devicesDailyGoal: string;
    devicesResults: string;
    consultationsDescription: string;
    consultationsDailyGoal: string;
    consultationsResults: string;
    medicalExamsDescription: string;
    medicalExamsDailyGoal: string;
    medicalExamsResults: string;
    familyCommunicationDescription: string;
    familyCommunicationHomeCare: boolean;
    familyCommunicationPalliation: boolean;
    familyCommunicationDailyGoal: string;
    familyCommunicationResults: string;
    isPatientWatcher: boolean;
}

export interface NewMultidisciplinaryVisit {
    medicalRecordId: string;
    form: MultidisciplinaryVisitForm;
}

export interface EditMultidisciplinaryVisit {
    whatNeedsToBeDischargedDailyGoal: string;
    whatNeedsToBeDischargedResults: string;
    sedationDeliriumRassTarget: string;
    sedationDeliriumDailyGoal: string;
    sedationDeliriumResults: string;
    cardiovascularLactate: string;
    cardiovascularHemodynamicMonitoring: boolean;
    cardiovascularDailyGoal: string;
    cardiovascularResults: string;
    cardiovascularPam: string;
    cardiovascularPas: string;
    cardiovascularPad: string;
    pulmonaryCe30: boolean;
    pulmonaryO2SupportActs: O2SupportAct;
    pulmonaryDailyGoal: string;
    pulmonaryResults: string;
    pulmonaryDisarm: boolean;
    pulmonaryTargetO2Saturation: string;
    mobilizationReleased: boolean;
    mobilizationDailyGoal: string;
    mobilizationResults: string;
    renalhd: boolean;
    renalDiuretic: boolean;
    renalBhLast24Hours: boolean;
    renalBhTarget: string;
    renalDailyGoal: string;
    renalResults: string;
    hematousFever: boolean;
    hematousDoseOfAtb: boolean;
    hematousHbFall: boolean;
    hematousDailyGoal: string;
    hematousResults: string;
    glucose140To180: boolean;
    glucoseLiberatedDiet: boolean;
    glucoseDailyGoal: string;
    glucoseResults: string;
    prophylaxisDescription: string;
    prophylaxisDailyGoal: string;
    prophylaxisResults: string;
    skinIntegrityDescription: string;
    skinIntegrityDailyGoal: string;
    skinIntegrityResults: string;
    devicesRemove: boolean;
    devicesCvcDate: Date | null;
    devicesPamiDate: Date | null;
    devicesSvdDate: Date | null;
    devicesPhdDate: Date | null;
    devicesDailyGoal: string;
    devicesResults: string;
    consultationsDescription: string;
    consultationsDailyGoal: string;
    consultationsResults: string;
    medicalExamsDescription: string;
    medicalExamsDailyGoal: string;
    medicalExamsResults: string;
    familyCommunicationDescription: string;
    familyCommunicationHomeCare: boolean;
    familyCommunicationPalliation: boolean;
    familyCommunicationDailyGoal: string;
    familyCommunicationResults: string;
    isPatientWatcher: boolean;
}

export interface EditMultidisciplinaryVisitForNonAdmin {
    whatNeedsToBeDischargedDailyGoal: string;
    whatNeedsToBeDischargedResults: string;
    sedationDeliriumDailyGoal: string;
    sedationDeliriumResults: string;
    cardiovascularDailyGoal: string;
    cardiovascularResults: string;
    pulmonaryDailyGoal: string;
    pulmonaryResults: string;
    mobilizationDailyGoal: string;
    mobilizationResults: string;
    renalDailyGoal: string;
    renalResults: string;
    hematousDailyGoal: string;
    hematousResults: string;
    glucoseDailyGoal: string;
    glucoseResults: string;
    prophylaxisDailyGoal: string;
    prophylaxisResults: string;
    skinIntegrityDailyGoal: string;
    skinIntegrityResults: string;
    devicesDailyGoal: string;
    devicesResults: string;
    consultationsDailyGoal: string;
    consultationsResults: string;
    medicalExamsDailyGoal: string;
    medicalExamsResults: string;
    familyCommunicationDailyGoal: string;
    familyCommunicationResults: string;
}

export interface MultidisciplinaryVisit {
    id: string;
    medicalRecord: ShowMedicalRecord;
    form: MultidisciplinaryVisitForm;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface MultidisciplinaryVisitWithoutMedicalRecord {
    id: string;
    form: MultidisciplinaryVisitForm;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface ShowMultidisciplinaryVisit {
    id: string;
    medicalRecord: ShowMedicalRecord;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface ShowMultidisciplinaryVisitWithoutPatient {
    id: string;
    medicalRecord: ShowMedicalRecordWithoutPatient;
    date: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface MultidisciplinaryVisitWithoutPatient {
    id: string;
    medicalRecord: ShowMedicalRecordWithoutPatient;
    form: MultidisciplinaryVisitForm;
    createdAt: Date;
    updatedAt: Date;
}

export interface RoutineManagement {
    id: string;
    date: Date | null;
    medicalRecord: MedicalRecord;
    isMedicalRecordChecked: boolean;
    safetyHuddleId: string | null;
    multidisciplinaryVisitId: string | null;
    createdAt: Date;
}

export interface ShowRoutineManagement {
    id: string;
    date: Date | null;
    medicalRecord: ShowMedicalRecordWithoutPatient;
    isMedicalRecordChecked: boolean;
    safetyHuddleId: string | null;
    multidisciplinaryVisitId: string | null;
}

export interface NewSafetyHuddle {
    date: Date;
    positionDayLaborer: string;
    positionNurses: string;
    positionPsychologist: string;
    positionOnDuty: string;
    positionPhysicalTherapists: string;
    positionPharmacy: string;
    positionTechnical: string;
    positionAdm: string;
    positionOthers: string;
    amountOfwatcherPatientsOnTheUnit: number | null;
    watcherPatients: string | null;
    amountOfSeverePatientInTheUnit: number | null;
    newtherapiesimplementedInTheUti: string | null;
    amountOfDischargedPatientsThatDidnNotLeave: number | null;
    amountOfPatientsWithOrganDysfunction24Hours: number | null;
    amountOfInvasiveProceduresThatDidNotHappenYesterday: number | null;
    amountOfCommunicationProblemsWithyFamilyOrUti: number | null;
    amountOfImagingOrLabTestsThatDidNotHappen: number | null;
    amountOfAdverseEventsInTheLast24Hours: number | null;
    howToImproveTheQualityOfPatient: string;
}

export interface EditSafetyHuddle {
    positionDayLaborer: string;
    positionNurses: string;
    positionPsychologist: string;
    positionOnDuty: string;
    positionPhysicalTherapists: string;
    positionPharmacy: string;
    positionTechnical: string;
    positionAdm: string;
    positionOthers: string;
    amountOfwatcherPatientsOnTheUnit: number | null;
    watcherPatients: string | null;
    amountOfSeverePatientInTheUnit: number | null;
    newtherapiesimplementedInTheUti: string | null;
    amountOfDischargedPatientsThatDidnNotLeave: number | null;
    amountOfPatientsWithOrganDysfunction24Hours: number | null;
    amountOfInvasiveProceduresThatDidNotHappenYesterday: number | null;
    amountOfCommunicationProblemsWithyFamilyOrUti: number | null;
    amountOfImagingOrLabTestsThatDidNotHappen: number | null;
    amountOfAdverseEventsInTheLast24Hours: number | null;
    howToImproveTheQualityOfPatient: string;
}

export interface SafetyHuddle {
    id: string;
    creator: AdminUser;
    createdAt: Date;
    date: Date;
    positionDayLaborer: string;
    positionNurses: string;
    positionPsychologist: string;
    positionOnDuty: string;
    positionPhysicalTherapists: string;
    positionPharmacy: string;
    positionTechnical: string;
    positionAdm: string;
    positionOthers: string;
    amountOfwatcherPatientsOnTheUnit: number | null;
    watcherPatients: string | null;
    amountOfSeverePatientInTheUnit: number | null;
    newtherapiesimplementedInTheUti: string | null;
    amountOfDischargedPatientsThatDidnNotLeave: number | null;
    amountOfPatientsWithOrganDysfunction24Hours: number | null;
    amountOfInvasiveProceduresThatDidNotHappenYesterday: number | null;
    amountOfCommunicationProblemsWithyFamilyOrUti: number | null;
    amountOfImagingOrLabTestsThatDidNotHappen: number | null;
    amountOfAdverseEventsInTheLast24Hours: number | null;
    howToImproveTheQualityOfPatient: string;
}

export interface ShowSafetyHuddle {
    id: string;
    date: Date;
    creator: AdminUser;
    createdAt: Date;
}

export enum AdminType {
    master = "master",
    employee = "employee",
    supervisor = "supervisor",
}

export function translateAdminType(enumAdminType: AdminType): string {
    switch (enumAdminType) {
        case AdminType.master: {
            return strings ? strings["enum"]["AdminType"]["master"] || AdminType.master : AdminType.master;
        }
        case AdminType.employee: {
            return strings ? strings["enum"]["AdminType"]["employee"] || AdminType.employee : AdminType.employee;
        }
        case AdminType.supervisor: {
            return strings ? strings["enum"]["AdminType"]["supervisor"] || AdminType.supervisor : AdminType.supervisor;
        }
    }
    return "";
}

export function allValuesAdminType(): AdminType[] {
    return [
        AdminType.master,
        AdminType.employee,
        AdminType.supervisor,
    ];
}

export function allTranslatedValuesAdminType(): string[] {
    return [
        translateAdminType(AdminType.master),
        translateAdminType(AdminType.employee),
        translateAdminType(AdminType.supervisor),
    ];
}

export function allDisplayableValuesAdminType(): string[] {
    return allTranslatedValuesAdminType().sort();
}

export function valueFromTranslationAdminType(translation: string): AdminType {
    const index = allTranslatedValuesAdminType().indexOf(translation);
    return allValuesAdminType()[index] || AdminType.master;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum PixType {
    Phone = "Phone",
    email = "email",
    cpf = "cpf",
}

export function translatePixType(enumPixType: PixType): string {
    switch (enumPixType) {
        case PixType.Phone: {
            return strings ? strings["enum"]["PixType"]["Phone"] || PixType.Phone : PixType.Phone;
        }
        case PixType.email: {
            return strings ? strings["enum"]["PixType"]["email"] || PixType.email : PixType.email;
        }
        case PixType.cpf: {
            return strings ? strings["enum"]["PixType"]["cpf"] || PixType.cpf : PixType.cpf;
        }
    }
    return "";
}

export function allValuesPixType(): PixType[] {
    return [
        PixType.Phone,
        PixType.email,
        PixType.cpf,
    ];
}

export function allTranslatedValuesPixType(): string[] {
    return [
        translatePixType(PixType.Phone),
        translatePixType(PixType.email),
        translatePixType(PixType.cpf),
    ];
}

export function allDisplayableValuesPixType(): string[] {
    return allTranslatedValuesPixType().sort();
}

export function valueFromTranslationPixType(translation: string): PixType {
    const index = allTranslatedValuesPixType().indexOf(translation);
    return allValuesPixType()[index] || PixType.Phone;
}

export enum Genre {
    male = "male",
    female = "female",
}

export function translateGenre(enumGenre: Genre): string {
    switch (enumGenre) {
        case Genre.male: {
            return strings ? strings["enum"]["Genre"]["male"] || Genre.male : Genre.male;
        }
        case Genre.female: {
            return strings ? strings["enum"]["Genre"]["female"] || Genre.female : Genre.female;
        }
    }
    return "";
}

export function allValuesGenre(): Genre[] {
    return [
        Genre.male,
        Genre.female,
    ];
}

export function allTranslatedValuesGenre(): string[] {
    return [
        translateGenre(Genre.male),
        translateGenre(Genre.female),
    ];
}

export function allDisplayableValuesGenre(): string[] {
    return allTranslatedValuesGenre().sort();
}

export function valueFromTranslationGenre(translation: string): Genre {
    const index = allTranslatedValuesGenre().indexOf(translation);
    return allValuesGenre()[index] || Genre.male;
}

export enum Plan {
    planservQuali = "planservQuali",
    cnuUnimed = "cnuUnimed",
    cassi = "cassi",
    geapSaude = "geapSaude",
    sulamerica = "sulamerica",
    amilInter = "amilInter",
    postalsaudeEct = "postalsaudeEct",
    petrobrasAps = "petrobrasAps",
    apubSsind = "apubSsind",
}

export function translatePlan(enumPlan: Plan): string {
    switch (enumPlan) {
        case Plan.planservQuali: {
            return strings ? strings["enum"]["Plan"]["planservQuali"] || Plan.planservQuali : Plan.planservQuali;
        }
        case Plan.cnuUnimed: {
            return strings ? strings["enum"]["Plan"]["cnuUnimed"] || Plan.cnuUnimed : Plan.cnuUnimed;
        }
        case Plan.cassi: {
            return strings ? strings["enum"]["Plan"]["cassi"] || Plan.cassi : Plan.cassi;
        }
        case Plan.geapSaude: {
            return strings ? strings["enum"]["Plan"]["geapSaude"] || Plan.geapSaude : Plan.geapSaude;
        }
        case Plan.sulamerica: {
            return strings ? strings["enum"]["Plan"]["sulamerica"] || Plan.sulamerica : Plan.sulamerica;
        }
        case Plan.amilInter: {
            return strings ? strings["enum"]["Plan"]["amilInter"] || Plan.amilInter : Plan.amilInter;
        }
        case Plan.postalsaudeEct: {
            return strings ? strings["enum"]["Plan"]["postalsaudeEct"] || Plan.postalsaudeEct : Plan.postalsaudeEct;
        }
        case Plan.petrobrasAps: {
            return strings ? strings["enum"]["Plan"]["petrobrasAps"] || Plan.petrobrasAps : Plan.petrobrasAps;
        }
        case Plan.apubSsind: {
            return strings ? strings["enum"]["Plan"]["apubSsind"] || Plan.apubSsind : Plan.apubSsind;
        }
    }
    return "";
}

export function allValuesPlan(): Plan[] {
    return [
        Plan.planservQuali,
        Plan.cnuUnimed,
        Plan.cassi,
        Plan.geapSaude,
        Plan.sulamerica,
        Plan.amilInter,
        Plan.postalsaudeEct,
        Plan.petrobrasAps,
        Plan.apubSsind,
    ];
}

export function allTranslatedValuesPlan(): string[] {
    return [
        translatePlan(Plan.planservQuali),
        translatePlan(Plan.cnuUnimed),
        translatePlan(Plan.cassi),
        translatePlan(Plan.geapSaude),
        translatePlan(Plan.sulamerica),
        translatePlan(Plan.amilInter),
        translatePlan(Plan.postalsaudeEct),
        translatePlan(Plan.petrobrasAps),
        translatePlan(Plan.apubSsind),
    ];
}

export function allDisplayableValuesPlan(): string[] {
    return allTranslatedValuesPlan().sort();
}

export function valueFromTranslationPlan(translation: string): Plan {
    const index = allTranslatedValuesPlan().indexOf(translation);
    return allValuesPlan()[index] || Plan.planservQuali;
}

export enum Unit {
    none = "none",
    internacao2D = "internacao2D",
    internacao6D = "internacao6D",
    semiIntensiva6C = "semiIntensiva6C",
    semiIntensiva7C = "semiIntensiva7C",
    semiIntensiva8C = "semiIntensiva8C",
    emergencia = "emergencia",
    utiAdulto1B = "utiAdulto1B",
    utiAdulto1C = "utiAdulto1C",
    utiAdulto2B = "utiAdulto2B",
    utiAdulto2C = "utiAdulto2C",
    utiAdulto3C = "utiAdulto3C",
}

export function translateUnit(enumUnit: Unit): string {
    switch (enumUnit) {
        case Unit.none: {
            return strings ? strings["enum"]["Unit"]["none"] || Unit.none : Unit.none;
        }
        case Unit.internacao2D: {
            return strings ? strings["enum"]["Unit"]["internacao2D"] || Unit.internacao2D : Unit.internacao2D;
        }
        case Unit.internacao6D: {
            return strings ? strings["enum"]["Unit"]["internacao6D"] || Unit.internacao6D : Unit.internacao6D;
        }
        case Unit.semiIntensiva6C: {
            return strings ? strings["enum"]["Unit"]["semiIntensiva6C"] || Unit.semiIntensiva6C : Unit.semiIntensiva6C;
        }
        case Unit.semiIntensiva7C: {
            return strings ? strings["enum"]["Unit"]["semiIntensiva7C"] || Unit.semiIntensiva7C : Unit.semiIntensiva7C;
        }
        case Unit.semiIntensiva8C: {
            return strings ? strings["enum"]["Unit"]["semiIntensiva8C"] || Unit.semiIntensiva8C : Unit.semiIntensiva8C;
        }
        case Unit.emergencia: {
            return strings ? strings["enum"]["Unit"]["emergencia"] || Unit.emergencia : Unit.emergencia;
        }
        case Unit.utiAdulto1B: {
            return strings ? strings["enum"]["Unit"]["utiAdulto1B"] || Unit.utiAdulto1B : Unit.utiAdulto1B;
        }
        case Unit.utiAdulto1C: {
            return strings ? strings["enum"]["Unit"]["utiAdulto1C"] || Unit.utiAdulto1C : Unit.utiAdulto1C;
        }
        case Unit.utiAdulto2B: {
            return strings ? strings["enum"]["Unit"]["utiAdulto2B"] || Unit.utiAdulto2B : Unit.utiAdulto2B;
        }
        case Unit.utiAdulto2C: {
            return strings ? strings["enum"]["Unit"]["utiAdulto2C"] || Unit.utiAdulto2C : Unit.utiAdulto2C;
        }
        case Unit.utiAdulto3C: {
            return strings ? strings["enum"]["Unit"]["utiAdulto3C"] || Unit.utiAdulto3C : Unit.utiAdulto3C;
        }
    }
    return "";
}

export function allValuesUnit(): Unit[] {
    return [
        Unit.none,
        Unit.internacao2D,
        Unit.internacao6D,
        Unit.semiIntensiva6C,
        Unit.semiIntensiva7C,
        Unit.semiIntensiva8C,
        Unit.emergencia,
        Unit.utiAdulto1B,
        Unit.utiAdulto1C,
        Unit.utiAdulto2B,
        Unit.utiAdulto2C,
        Unit.utiAdulto3C,
    ];
}

export function allTranslatedValuesUnit(): string[] {
    return [
        translateUnit(Unit.none),
        translateUnit(Unit.internacao2D),
        translateUnit(Unit.internacao6D),
        translateUnit(Unit.semiIntensiva6C),
        translateUnit(Unit.semiIntensiva7C),
        translateUnit(Unit.semiIntensiva8C),
        translateUnit(Unit.emergencia),
        translateUnit(Unit.utiAdulto1B),
        translateUnit(Unit.utiAdulto1C),
        translateUnit(Unit.utiAdulto2B),
        translateUnit(Unit.utiAdulto2C),
        translateUnit(Unit.utiAdulto3C),
    ];
}

export function allDisplayableValuesUnit(): string[] {
    return allTranslatedValuesUnit().sort();
}

export function valueFromTranslationUnit(translation: string): Unit {
    const index = allTranslatedValuesUnit().indexOf(translation);
    return allValuesUnit()[index] || Unit.none;
}

export enum TevAdmission {
    highRisk = "highRisk",
    moderate = "moderate",
    lowRisk = "lowRisk",
}

export function translateTevAdmission(enumTevAdmission: TevAdmission): string {
    switch (enumTevAdmission) {
        case TevAdmission.highRisk: {
            return strings ? strings["enum"]["TevAdmission"]["highRisk"] || TevAdmission.highRisk : TevAdmission.highRisk;
        }
        case TevAdmission.moderate: {
            return strings ? strings["enum"]["TevAdmission"]["moderate"] || TevAdmission.moderate : TevAdmission.moderate;
        }
        case TevAdmission.lowRisk: {
            return strings ? strings["enum"]["TevAdmission"]["lowRisk"] || TevAdmission.lowRisk : TevAdmission.lowRisk;
        }
    }
    return "";
}

export function allValuesTevAdmission(): TevAdmission[] {
    return [
        TevAdmission.highRisk,
        TevAdmission.moderate,
        TevAdmission.lowRisk,
    ];
}

export function allTranslatedValuesTevAdmission(): string[] {
    return [
        translateTevAdmission(TevAdmission.highRisk),
        translateTevAdmission(TevAdmission.moderate),
        translateTevAdmission(TevAdmission.lowRisk),
    ];
}

export function allDisplayableValuesTevAdmission(): string[] {
    return allTranslatedValuesTevAdmission().sort();
}

export function valueFromTranslationTevAdmission(translation: string): TevAdmission {
    const index = allTranslatedValuesTevAdmission().indexOf(translation);
    return allValuesTevAdmission()[index] || TevAdmission.highRisk;
}

export enum O2SupportAct {
    AA = "AA",
    O2 = "O2",
    VNI = "VNI",
    CNAF = "CNAF",
    VMI = "VMI",
}

export function translateO2SupportAct(enumO2SupportAct: O2SupportAct): string {
    switch (enumO2SupportAct) {
        case O2SupportAct.AA: {
            return strings ? strings["enum"]["O2SupportAct"]["AA"] || O2SupportAct.AA : O2SupportAct.AA;
        }
        case O2SupportAct.O2: {
            return strings ? strings["enum"]["O2SupportAct"]["O2"] || O2SupportAct.O2 : O2SupportAct.O2;
        }
        case O2SupportAct.VNI: {
            return strings ? strings["enum"]["O2SupportAct"]["VNI"] || O2SupportAct.VNI : O2SupportAct.VNI;
        }
        case O2SupportAct.CNAF: {
            return strings ? strings["enum"]["O2SupportAct"]["CNAF"] || O2SupportAct.CNAF : O2SupportAct.CNAF;
        }
        case O2SupportAct.VMI: {
            return strings ? strings["enum"]["O2SupportAct"]["VMI"] || O2SupportAct.VMI : O2SupportAct.VMI;
        }
    }
    return "";
}

export function allValuesO2SupportAct(): O2SupportAct[] {
    return [
        O2SupportAct.AA,
        O2SupportAct.O2,
        O2SupportAct.VNI,
        O2SupportAct.CNAF,
        O2SupportAct.VMI,
    ];
}

export function allTranslatedValuesO2SupportAct(): string[] {
    return [
        translateO2SupportAct(O2SupportAct.AA),
        translateO2SupportAct(O2SupportAct.O2),
        translateO2SupportAct(O2SupportAct.VNI),
        translateO2SupportAct(O2SupportAct.CNAF),
        translateO2SupportAct(O2SupportAct.VMI),
    ];
}

export function allDisplayableValuesO2SupportAct(): string[] {
    return allTranslatedValuesO2SupportAct().sort();
}

export function valueFromTranslationO2SupportAct(translation: string): O2SupportAct {
    const index = allTranslatedValuesO2SupportAct().indexOf(translation);
    return allValuesO2SupportAct()[index] || O2SupportAct.AA;
}

export enum ExitLocation {
    none = "none",
    dischargedUi = "dischargedUi",
    dischargedHospital = "dischargedHospital",
    dischargedSemi = "dischargedSemi",
}

export function translateExitLocation(enumExitLocation: ExitLocation): string {
    switch (enumExitLocation) {
        case ExitLocation.none: {
            return strings ? strings["enum"]["ExitLocation"]["none"] || ExitLocation.none : ExitLocation.none;
        }
        case ExitLocation.dischargedUi: {
            return strings ? strings["enum"]["ExitLocation"]["dischargedUi"] || ExitLocation.dischargedUi : ExitLocation.dischargedUi;
        }
        case ExitLocation.dischargedHospital: {
            return strings ? strings["enum"]["ExitLocation"]["dischargedHospital"] || ExitLocation.dischargedHospital : ExitLocation.dischargedHospital;
        }
        case ExitLocation.dischargedSemi: {
            return strings ? strings["enum"]["ExitLocation"]["dischargedSemi"] || ExitLocation.dischargedSemi : ExitLocation.dischargedSemi;
        }
    }
    return "";
}

export function allValuesExitLocation(): ExitLocation[] {
    return [
        ExitLocation.none,
        ExitLocation.dischargedUi,
        ExitLocation.dischargedHospital,
        ExitLocation.dischargedSemi,
    ];
}

export function allTranslatedValuesExitLocation(): string[] {
    return [
        translateExitLocation(ExitLocation.none),
        translateExitLocation(ExitLocation.dischargedUi),
        translateExitLocation(ExitLocation.dischargedHospital),
        translateExitLocation(ExitLocation.dischargedSemi),
    ];
}

export function allDisplayableValuesExitLocation(): string[] {
    return allTranslatedValuesExitLocation().sort();
}

export function valueFromTranslationExitLocation(translation: string): ExitLocation {
    const index = allTranslatedValuesExitLocation().indexOf(translation);
    return allValuesExitLocation()[index] || ExitLocation.none;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    WaitingApproval = "WaitingApproval",
    RequestDenied = "RequestDenied",
    NotAvailable = "NotAvailable",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    CNPJAlreadyRegistered = "CNPJAlreadyRegistered",
    DepositAlreadyApproved = "DepositAlreadyApproved",
    NotLoggedIn = "NotLoggedIn",
    InvalidAccessToken = "InvalidAccessToken",
    AlreadyBlocked = "AlreadyBlocked",
    AlreadyUnblocked = "AlreadyUnblocked",
    AlreadyRequested = "AlreadyRequested",
    AlreadyHospitalized = "AlreadyHospitalized",
    LimitHasBeenReached = "LimitHasBeenReached",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.WaitingApproval: {
            return strings ? strings["enum"]["ErrorType"]["WaitingApproval"] || ErrorType.WaitingApproval : ErrorType.WaitingApproval;
        }
        case ErrorType.RequestDenied: {
            return strings ? strings["enum"]["ErrorType"]["RequestDenied"] || ErrorType.RequestDenied : ErrorType.RequestDenied;
        }
        case ErrorType.NotAvailable: {
            return strings ? strings["enum"]["ErrorType"]["NotAvailable"] || ErrorType.NotAvailable : ErrorType.NotAvailable;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.CNPJAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CNPJAlreadyRegistered"] || ErrorType.CNPJAlreadyRegistered : ErrorType.CNPJAlreadyRegistered;
        }
        case ErrorType.DepositAlreadyApproved: {
            return strings ? strings["enum"]["ErrorType"]["DepositAlreadyApproved"] || ErrorType.DepositAlreadyApproved : ErrorType.DepositAlreadyApproved;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.InvalidAccessToken: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccessToken"] || ErrorType.InvalidAccessToken : ErrorType.InvalidAccessToken;
        }
        case ErrorType.AlreadyBlocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyBlocked"] || ErrorType.AlreadyBlocked : ErrorType.AlreadyBlocked;
        }
        case ErrorType.AlreadyUnblocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyUnblocked"] || ErrorType.AlreadyUnblocked : ErrorType.AlreadyUnblocked;
        }
        case ErrorType.AlreadyRequested: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRequested"] || ErrorType.AlreadyRequested : ErrorType.AlreadyRequested;
        }
        case ErrorType.AlreadyHospitalized: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyHospitalized"] || ErrorType.AlreadyHospitalized : ErrorType.AlreadyHospitalized;
        }
        case ErrorType.LimitHasBeenReached: {
            return strings ? strings["enum"]["ErrorType"]["LimitHasBeenReached"] || ErrorType.LimitHasBeenReached : ErrorType.LimitHasBeenReached;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.WaitingApproval,
        ErrorType.RequestDenied,
        ErrorType.NotAvailable,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.CNPJAlreadyRegistered,
        ErrorType.DepositAlreadyApproved,
        ErrorType.NotLoggedIn,
        ErrorType.InvalidAccessToken,
        ErrorType.AlreadyBlocked,
        ErrorType.AlreadyUnblocked,
        ErrorType.AlreadyRequested,
        ErrorType.AlreadyHospitalized,
        ErrorType.LimitHasBeenReached,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.WaitingApproval),
        translateErrorType(ErrorType.RequestDenied),
        translateErrorType(ErrorType.NotAvailable),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.CNPJAlreadyRegistered),
        translateErrorType(ErrorType.DepositAlreadyApproved),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.InvalidAccessToken),
        translateErrorType(ErrorType.AlreadyBlocked),
        translateErrorType(ErrorType.AlreadyUnblocked),
        translateErrorType(ErrorType.AlreadyRequested),
        translateErrorType(ErrorType.AlreadyHospitalized),
        translateErrorType(ErrorType.LimitHasBeenReached),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        adminType: e.adminType,
        name: e.name,
        email: e.email,
    }));
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            adminType: newAdminUser.adminType,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getAllBedsByFilterOptions(pageOffSet: number, filterOptions: BedFilterOptions, progress?: (progress: number) => void): Promise<Bed[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        filterOptions: {
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
        },
    };
    const ret = await makeRequest({name: "getAllBedsByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        available: !!e.available,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        code: e.code,
    }));
}

export async function getAllAvailableBeds(pageOffSet: number, progress?: (progress: number) => void): Promise<Bed[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllAvailableBeds", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        available: !!e.available,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        code: e.code,
    }));
}

export async function getBedById(id: string, progress?: (progress: number) => void): Promise<Bed> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getBedById", args, progress});
    return {
        id: ret.id,
        available: !!ret.available,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        code: ret.code,
    };
}

export async function createANewBed(newBed: NewBed, progress?: (progress: number) => void): Promise<Bed> {
    const args = {
        newBed: {
            code: newBed.code,
        },
    };
    const ret = await makeRequest({name: "createANewBed", args, progress});
    return {
        id: ret.id,
        available: !!ret.available,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        code: ret.code,
    };
}

export async function deleteBed(id: string, progress?: (progress: number) => void): Promise<Bed> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteBed", args, progress});
    return {
        id: ret.id,
        available: !!ret.available,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        code: ret.code,
    };
}

export async function getBedOccupancyPercentage(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getBedOccupancyPercentage", args: {}, progress});
    return ret || 0;
}

export async function getAllLogs(pageOffSet: number, filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<Log[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllLogs", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        adminUser: {
            id: e.adminUser.id,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            adminType: e.adminUser.adminType,
            name: e.adminUser.name,
            email: e.adminUser.email,
        },
        description: e.description,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getLogById(id: string, progress?: (progress: number) => void): Promise<Log> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getLogById", args, progress});
    return {
        id: ret.id,
        adminUser: {
            id: ret.adminUser.id,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            adminType: ret.adminUser.adminType,
            name: ret.adminUser.name,
            email: ret.adminUser.email,
        },
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getExportCsvLogs(filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getExportCsvLogs", args, progress});
    return ret;
}

export async function getPatientByDocumentNumber(documentNumber: string, progress?: (progress: number) => void): Promise<Patient> {
    const args = {
        documentNumber: documentNumber,
    };
    const ret = await makeRequest({name: "getPatientByDocumentNumber", args, progress});
    return {
        id: ret.id,
        fullname: ret.fullname,
        age: ret.age || 0,
        birthdate: new Date(ret.birthdate + "Z"),
        genre: ret.genre,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllPatients(pageOffset: number, progress?: (progress: number) => void): Promise<Patient[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPatients", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        fullname: e.fullname,
        age: e.age || 0,
        birthdate: new Date(e.birthdate + "Z"),
        genre: e.genre,
        documentNumber: e.documentNumber,
    }));
}

export async function getPatient(id: string, progress?: (progress: number) => void): Promise<Patient> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPatient", args, progress});
    return {
        id: ret.id,
        fullname: ret.fullname,
        age: ret.age || 0,
        birthdate: new Date(ret.birthdate + "Z"),
        genre: ret.genre,
        documentNumber: ret.documentNumber,
    };
}

export async function deletePatient(id: string, progress?: (progress: number) => void): Promise<Patient> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePatient", args, progress});
    return {
        id: ret.id,
        fullname: ret.fullname,
        age: ret.age || 0,
        birthdate: new Date(ret.birthdate + "Z"),
        genre: ret.genre,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllMedicalRecordsByFilterOptions(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowMedicalRecord[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllMedicalRecordsByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        code: e.code,
        patient: {
            id: e.patient.id,
            fullname: e.patient.fullname,
            age: e.patient.age || 0,
            birthdate: new Date(e.patient.birthdate + "Z"),
            genre: e.patient.genre,
            documentNumber: e.patient.documentNumber,
        },
        bed: {
            id: e.bed.id,
            available: !!e.bed.available,
            createdAt: new Date(e.bed.createdAt + "Z"),
            updatedAt: new Date(e.bed.updatedAt + "Z"),
            code: e.bed.code,
        },
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        isHospitalized: !!e.isHospitalized,
    }));
}

export async function getMedicalRecordById(id: string, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMedicalRecordById", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function getAllMedicalRecordsOfPatient(pageOffset: number, patientId: string, progress?: (progress: number) => void): Promise<ShowMedicalRecord[]> {
    const args = {
        pageOffset: pageOffset || 0,
        patientId: patientId,
    };
    const ret = await makeRequest({name: "getAllMedicalRecordsOfPatient", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        code: e.code,
        patient: {
            id: e.patient.id,
            fullname: e.patient.fullname,
            age: e.patient.age || 0,
            birthdate: new Date(e.patient.birthdate + "Z"),
            genre: e.patient.genre,
            documentNumber: e.patient.documentNumber,
        },
        bed: {
            id: e.bed.id,
            available: !!e.bed.available,
            createdAt: new Date(e.bed.createdAt + "Z"),
            updatedAt: new Date(e.bed.updatedAt + "Z"),
            code: e.bed.code,
        },
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        isHospitalized: !!e.isHospitalized,
    }));
}

export async function deleteMedicalRecordById(id: string, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteMedicalRecordById", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function createMedicalRecord(newMedicalRecord: NewMedicalRecord, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        newMedicalRecord: {
            patient: {
                id: newMedicalRecord.patient.id === null || newMedicalRecord.patient.id === undefined ? null : newMedicalRecord.patient.id,
                fullname: newMedicalRecord.patient.fullname,
                age: newMedicalRecord.patient.age || 0,
                birthdate: (typeof newMedicalRecord.patient.birthdate === "string" && (newMedicalRecord.patient.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.patient.birthdate as any).replace("Z", "") : newMedicalRecord.patient.birthdate.toISOString().replace("Z", "")),
                genre: newMedicalRecord.patient.genre,
                documentNumber: newMedicalRecord.patient.documentNumber,
            },
            bedId: newMedicalRecord.bedId,
            form: {
                plan: newMedicalRecord.form.plan,
                unit: newMedicalRecord.form.unit,
                hospitalAdmissionDate: (typeof newMedicalRecord.form.hospitalAdmissionDate === "string" && (newMedicalRecord.form.hospitalAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.hospitalAdmissionDate as any).replace("Z", "") : newMedicalRecord.form.hospitalAdmissionDate.toISOString().replace("Z", "")),
                utiAdmissionDate: (typeof newMedicalRecord.form.utiAdmissionDate === "string" && (newMedicalRecord.form.utiAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.utiAdmissionDate as any).replace("Z", "") : newMedicalRecord.form.utiAdmissionDate.toISOString().replace("Z", "")),
                patientOrigin: newMedicalRecord.form.patientOrigin,
                reasonForUTIAdmission: newMedicalRecord.form.reasonForUTIAdmission,
                apache2: newMedicalRecord.form.apache2,
                fragility: newMedicalRecord.form.fragility,
                charlson: newMedicalRecord.form.charlson,
                sofa1: newMedicalRecord.form.sofa1,
                sofa2: newMedicalRecord.form.sofa2,
                sofa3: newMedicalRecord.form.sofa3,
                sofaDischarged: newMedicalRecord.form.sofaDischarged,
                saps3: newMedicalRecord.form.saps3,
                isEag: !!newMedicalRecord.form.isEag,
                tevAdmission: newMedicalRecord.form.tevAdmission,
                watcher: newMedicalRecord.form.watcher,
                palliation: !!newMedicalRecord.form.palliation,
                protocolOpeningDate: (typeof newMedicalRecord.form.protocolOpeningDate === "string" && (newMedicalRecord.form.protocolOpeningDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.protocolOpeningDate as any).replace("Z", "") : newMedicalRecord.form.protocolOpeningDate.toISOString().replace("Z", "")),
                openingUnitSepsisProtocol: newMedicalRecord.form.openingUnitSepsisProtocol,
                isLPPOnAdmission: !!newMedicalRecord.form.isLPPOnAdmission,
                isLPPDuringHospitalization: !!newMedicalRecord.form.isLPPDuringHospitalization,
                departureDate: newMedicalRecord.form.departureDate === null || newMedicalRecord.form.departureDate === undefined ? null : (typeof newMedicalRecord.form.departureDate === "string" && (newMedicalRecord.form.departureDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.departureDate as any).replace("Z", "") : newMedicalRecord.form.departureDate.toISOString().replace("Z", "")),
                outputUnit: newMedicalRecord.form.outputUnit,
                isSurgeryCenter: !!newMedicalRecord.form.isSurgeryCenter,
                death: newMedicalRecord.form.death === null || newMedicalRecord.form.death === undefined ? null : (typeof newMedicalRecord.form.death === "string" && (newMedicalRecord.form.death as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.death as any).replace("Z", "") : newMedicalRecord.form.death.toISOString().replace("Z", "")),
                nsa: newMedicalRecord.form.nsa || 0,
                escore: newMedicalRecord.form.escore,
                cvcInstDate: newMedicalRecord.form.cvcInstDate === null || newMedicalRecord.form.cvcInstDate === undefined ? null : (typeof newMedicalRecord.form.cvcInstDate === "string" && (newMedicalRecord.form.cvcInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.cvcInstDate as any).replace("Z", "") : newMedicalRecord.form.cvcInstDate.toISOString().replace("Z", "")),
                cvcRetDate: newMedicalRecord.form.cvcRetDate === null || newMedicalRecord.form.cvcRetDate === undefined ? null : (typeof newMedicalRecord.form.cvcRetDate === "string" && (newMedicalRecord.form.cvcRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.cvcRetDate as any).replace("Z", "") : newMedicalRecord.form.cvcRetDate.toISOString().replace("Z", "")),
                totInstDate: newMedicalRecord.form.totInstDate === null || newMedicalRecord.form.totInstDate === undefined ? null : (typeof newMedicalRecord.form.totInstDate === "string" && (newMedicalRecord.form.totInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.totInstDate as any).replace("Z", "") : newMedicalRecord.form.totInstDate.toISOString().replace("Z", "")),
                totRetDate: newMedicalRecord.form.totRetDate === null || newMedicalRecord.form.totRetDate === undefined ? null : (typeof newMedicalRecord.form.totRetDate === "string" && (newMedicalRecord.form.totRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.totRetDate as any).replace("Z", "") : newMedicalRecord.form.totRetDate.toISOString().replace("Z", "")),
                svdInstDate: newMedicalRecord.form.svdInstDate === null || newMedicalRecord.form.svdInstDate === undefined ? null : (typeof newMedicalRecord.form.svdInstDate === "string" && (newMedicalRecord.form.svdInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.svdInstDate as any).replace("Z", "") : newMedicalRecord.form.svdInstDate.toISOString().replace("Z", "")),
                svdRetDate: newMedicalRecord.form.svdRetDate === null || newMedicalRecord.form.svdRetDate === undefined ? null : (typeof newMedicalRecord.form.svdRetDate === "string" && (newMedicalRecord.form.svdRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.svdRetDate as any).replace("Z", "") : newMedicalRecord.form.svdRetDate.toISOString().replace("Z", "")),
                exitLocation: newMedicalRecord.form.exitLocation,
            },
        },
    };
    const ret = await makeRequest({name: "createMedicalRecord", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function editMedicalRecord(id: string, newMedicalRecord: EditMedicalRecordForAdmin, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        id: id,
        newMedicalRecord: {
            patient: {
                id: newMedicalRecord.patient.id === null || newMedicalRecord.patient.id === undefined ? null : newMedicalRecord.patient.id,
                fullname: newMedicalRecord.patient.fullname,
                age: newMedicalRecord.patient.age || 0,
                birthdate: (typeof newMedicalRecord.patient.birthdate === "string" && (newMedicalRecord.patient.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.patient.birthdate as any).replace("Z", "") : newMedicalRecord.patient.birthdate.toISOString().replace("Z", "")),
                genre: newMedicalRecord.patient.genre,
                documentNumber: newMedicalRecord.patient.documentNumber,
            },
            form: {
                plan: newMedicalRecord.form.plan,
                unit: newMedicalRecord.form.unit,
                hospitalAdmissionDate: (typeof newMedicalRecord.form.hospitalAdmissionDate === "string" && (newMedicalRecord.form.hospitalAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.hospitalAdmissionDate as any).replace("Z", "") : newMedicalRecord.form.hospitalAdmissionDate.toISOString().replace("Z", "")),
                utiAdmissionDate: (typeof newMedicalRecord.form.utiAdmissionDate === "string" && (newMedicalRecord.form.utiAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.utiAdmissionDate as any).replace("Z", "") : newMedicalRecord.form.utiAdmissionDate.toISOString().replace("Z", "")),
                patientOrigin: newMedicalRecord.form.patientOrigin,
                reasonForUTIAdmission: newMedicalRecord.form.reasonForUTIAdmission,
                apache2: newMedicalRecord.form.apache2,
                fragility: newMedicalRecord.form.fragility,
                charlson: newMedicalRecord.form.charlson,
                sofa1: newMedicalRecord.form.sofa1,
                sofa2: newMedicalRecord.form.sofa2,
                sofa3: newMedicalRecord.form.sofa3,
                sofaDischarged: newMedicalRecord.form.sofaDischarged,
                saps3: newMedicalRecord.form.saps3,
                isEag: !!newMedicalRecord.form.isEag,
                tevAdmission: newMedicalRecord.form.tevAdmission,
                watcher: newMedicalRecord.form.watcher,
                palliation: !!newMedicalRecord.form.palliation,
                protocolOpeningDate: (typeof newMedicalRecord.form.protocolOpeningDate === "string" && (newMedicalRecord.form.protocolOpeningDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.protocolOpeningDate as any).replace("Z", "") : newMedicalRecord.form.protocolOpeningDate.toISOString().replace("Z", "")),
                openingUnitSepsisProtocol: newMedicalRecord.form.openingUnitSepsisProtocol,
                isLPPOnAdmission: !!newMedicalRecord.form.isLPPOnAdmission,
                isLPPDuringHospitalization: !!newMedicalRecord.form.isLPPDuringHospitalization,
                departureDate: newMedicalRecord.form.departureDate === null || newMedicalRecord.form.departureDate === undefined ? null : (typeof newMedicalRecord.form.departureDate === "string" && (newMedicalRecord.form.departureDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.departureDate as any).replace("Z", "") : newMedicalRecord.form.departureDate.toISOString().replace("Z", "")),
                outputUnit: newMedicalRecord.form.outputUnit,
                isSurgeryCenter: !!newMedicalRecord.form.isSurgeryCenter,
                death: newMedicalRecord.form.death === null || newMedicalRecord.form.death === undefined ? null : (typeof newMedicalRecord.form.death === "string" && (newMedicalRecord.form.death as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.death as any).replace("Z", "") : newMedicalRecord.form.death.toISOString().replace("Z", "")),
                nsa: newMedicalRecord.form.nsa || 0,
                escore: newMedicalRecord.form.escore,
                cvcInstDate: newMedicalRecord.form.cvcInstDate === null || newMedicalRecord.form.cvcInstDate === undefined ? null : (typeof newMedicalRecord.form.cvcInstDate === "string" && (newMedicalRecord.form.cvcInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.cvcInstDate as any).replace("Z", "") : newMedicalRecord.form.cvcInstDate.toISOString().replace("Z", "")),
                cvcRetDate: newMedicalRecord.form.cvcRetDate === null || newMedicalRecord.form.cvcRetDate === undefined ? null : (typeof newMedicalRecord.form.cvcRetDate === "string" && (newMedicalRecord.form.cvcRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.cvcRetDate as any).replace("Z", "") : newMedicalRecord.form.cvcRetDate.toISOString().replace("Z", "")),
                totInstDate: newMedicalRecord.form.totInstDate === null || newMedicalRecord.form.totInstDate === undefined ? null : (typeof newMedicalRecord.form.totInstDate === "string" && (newMedicalRecord.form.totInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.totInstDate as any).replace("Z", "") : newMedicalRecord.form.totInstDate.toISOString().replace("Z", "")),
                totRetDate: newMedicalRecord.form.totRetDate === null || newMedicalRecord.form.totRetDate === undefined ? null : (typeof newMedicalRecord.form.totRetDate === "string" && (newMedicalRecord.form.totRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.totRetDate as any).replace("Z", "") : newMedicalRecord.form.totRetDate.toISOString().replace("Z", "")),
                svdInstDate: newMedicalRecord.form.svdInstDate === null || newMedicalRecord.form.svdInstDate === undefined ? null : (typeof newMedicalRecord.form.svdInstDate === "string" && (newMedicalRecord.form.svdInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.svdInstDate as any).replace("Z", "") : newMedicalRecord.form.svdInstDate.toISOString().replace("Z", "")),
                svdRetDate: newMedicalRecord.form.svdRetDate === null || newMedicalRecord.form.svdRetDate === undefined ? null : (typeof newMedicalRecord.form.svdRetDate === "string" && (newMedicalRecord.form.svdRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMedicalRecord.form.svdRetDate as any).replace("Z", "") : newMedicalRecord.form.svdRetDate.toISOString().replace("Z", "")),
                exitLocation: newMedicalRecord.form.exitLocation,
            },
        },
    };
    const ret = await makeRequest({name: "editMedicalRecord", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function exportCsvMedicalRecord(filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "exportCsvMedicalRecord", args, progress});
    return ret;
}

export async function getAllMedicalRecordsByFilterOptionsForNonAdmin(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowMedicalRecordWithoutPatient[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllMedicalRecordsByFilterOptionsForNonAdmin", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        code: e.code,
        bed: {
            id: e.bed.id,
            available: !!e.bed.available,
            createdAt: new Date(e.bed.createdAt + "Z"),
            updatedAt: new Date(e.bed.updatedAt + "Z"),
            code: e.bed.code,
        },
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        isHospitalized: !!e.isHospitalized,
    }));
}

export async function getMedicalRecordByIdForNonAdmin(id: string, progress?: (progress: number) => void): Promise<MedicalRecordWithoutPatient> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMedicalRecordByIdForNonAdmin", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function editMedicalRecordForEmployee(id: string, editMedicalRecord: EditMedicalRecord, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        id: id,
        editMedicalRecord: {
            form: {
                plan: editMedicalRecord.form.plan,
                unit: editMedicalRecord.form.unit,
                hospitalAdmissionDate: (typeof editMedicalRecord.form.hospitalAdmissionDate === "string" && (editMedicalRecord.form.hospitalAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.hospitalAdmissionDate as any).replace("Z", "") : editMedicalRecord.form.hospitalAdmissionDate.toISOString().replace("Z", "")),
                utiAdmissionDate: (typeof editMedicalRecord.form.utiAdmissionDate === "string" && (editMedicalRecord.form.utiAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.utiAdmissionDate as any).replace("Z", "") : editMedicalRecord.form.utiAdmissionDate.toISOString().replace("Z", "")),
                patientOrigin: editMedicalRecord.form.patientOrigin,
                reasonForUTIAdmission: editMedicalRecord.form.reasonForUTIAdmission,
                apache2: editMedicalRecord.form.apache2,
                fragility: editMedicalRecord.form.fragility,
                charlson: editMedicalRecord.form.charlson,
                sofa1: editMedicalRecord.form.sofa1,
                sofa2: editMedicalRecord.form.sofa2,
                sofa3: editMedicalRecord.form.sofa3,
                sofaDischarged: editMedicalRecord.form.sofaDischarged,
                saps3: editMedicalRecord.form.saps3,
                isEag: !!editMedicalRecord.form.isEag,
                tevAdmission: editMedicalRecord.form.tevAdmission,
                watcher: editMedicalRecord.form.watcher,
                palliation: !!editMedicalRecord.form.palliation,
                protocolOpeningDate: (typeof editMedicalRecord.form.protocolOpeningDate === "string" && (editMedicalRecord.form.protocolOpeningDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.protocolOpeningDate as any).replace("Z", "") : editMedicalRecord.form.protocolOpeningDate.toISOString().replace("Z", "")),
                openingUnitSepsisProtocol: editMedicalRecord.form.openingUnitSepsisProtocol,
                isLPPOnAdmission: !!editMedicalRecord.form.isLPPOnAdmission,
                isLPPDuringHospitalization: !!editMedicalRecord.form.isLPPDuringHospitalization,
                departureDate: editMedicalRecord.form.departureDate === null || editMedicalRecord.form.departureDate === undefined ? null : (typeof editMedicalRecord.form.departureDate === "string" && (editMedicalRecord.form.departureDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.departureDate as any).replace("Z", "") : editMedicalRecord.form.departureDate.toISOString().replace("Z", "")),
                outputUnit: editMedicalRecord.form.outputUnit,
                isSurgeryCenter: !!editMedicalRecord.form.isSurgeryCenter,
                death: editMedicalRecord.form.death === null || editMedicalRecord.form.death === undefined ? null : (typeof editMedicalRecord.form.death === "string" && (editMedicalRecord.form.death as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.death as any).replace("Z", "") : editMedicalRecord.form.death.toISOString().replace("Z", "")),
                nsa: editMedicalRecord.form.nsa || 0,
                escore: editMedicalRecord.form.escore,
                cvcInstDate: editMedicalRecord.form.cvcInstDate === null || editMedicalRecord.form.cvcInstDate === undefined ? null : (typeof editMedicalRecord.form.cvcInstDate === "string" && (editMedicalRecord.form.cvcInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.cvcInstDate as any).replace("Z", "") : editMedicalRecord.form.cvcInstDate.toISOString().replace("Z", "")),
                cvcRetDate: editMedicalRecord.form.cvcRetDate === null || editMedicalRecord.form.cvcRetDate === undefined ? null : (typeof editMedicalRecord.form.cvcRetDate === "string" && (editMedicalRecord.form.cvcRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.cvcRetDate as any).replace("Z", "") : editMedicalRecord.form.cvcRetDate.toISOString().replace("Z", "")),
                totInstDate: editMedicalRecord.form.totInstDate === null || editMedicalRecord.form.totInstDate === undefined ? null : (typeof editMedicalRecord.form.totInstDate === "string" && (editMedicalRecord.form.totInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.totInstDate as any).replace("Z", "") : editMedicalRecord.form.totInstDate.toISOString().replace("Z", "")),
                totRetDate: editMedicalRecord.form.totRetDate === null || editMedicalRecord.form.totRetDate === undefined ? null : (typeof editMedicalRecord.form.totRetDate === "string" && (editMedicalRecord.form.totRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.totRetDate as any).replace("Z", "") : editMedicalRecord.form.totRetDate.toISOString().replace("Z", "")),
                svdInstDate: editMedicalRecord.form.svdInstDate === null || editMedicalRecord.form.svdInstDate === undefined ? null : (typeof editMedicalRecord.form.svdInstDate === "string" && (editMedicalRecord.form.svdInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.svdInstDate as any).replace("Z", "") : editMedicalRecord.form.svdInstDate.toISOString().replace("Z", "")),
                svdRetDate: editMedicalRecord.form.svdRetDate === null || editMedicalRecord.form.svdRetDate === undefined ? null : (typeof editMedicalRecord.form.svdRetDate === "string" && (editMedicalRecord.form.svdRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.svdRetDate as any).replace("Z", "") : editMedicalRecord.form.svdRetDate.toISOString().replace("Z", "")),
                exitLocation: editMedicalRecord.form.exitLocation,
            },
        },
    };
    const ret = await makeRequest({name: "editMedicalRecordForEmployee", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function checkMedicalRecordRoutine(id: string, editMedicalRecord: EditMedicalRecord, routineId: string, progress?: (progress: number) => void): Promise<MedicalRecord> {
    const args = {
        id: id,
        editMedicalRecord: {
            form: {
                plan: editMedicalRecord.form.plan,
                unit: editMedicalRecord.form.unit,
                hospitalAdmissionDate: (typeof editMedicalRecord.form.hospitalAdmissionDate === "string" && (editMedicalRecord.form.hospitalAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.hospitalAdmissionDate as any).replace("Z", "") : editMedicalRecord.form.hospitalAdmissionDate.toISOString().replace("Z", "")),
                utiAdmissionDate: (typeof editMedicalRecord.form.utiAdmissionDate === "string" && (editMedicalRecord.form.utiAdmissionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.utiAdmissionDate as any).replace("Z", "") : editMedicalRecord.form.utiAdmissionDate.toISOString().replace("Z", "")),
                patientOrigin: editMedicalRecord.form.patientOrigin,
                reasonForUTIAdmission: editMedicalRecord.form.reasonForUTIAdmission,
                apache2: editMedicalRecord.form.apache2,
                fragility: editMedicalRecord.form.fragility,
                charlson: editMedicalRecord.form.charlson,
                sofa1: editMedicalRecord.form.sofa1,
                sofa2: editMedicalRecord.form.sofa2,
                sofa3: editMedicalRecord.form.sofa3,
                sofaDischarged: editMedicalRecord.form.sofaDischarged,
                saps3: editMedicalRecord.form.saps3,
                isEag: !!editMedicalRecord.form.isEag,
                tevAdmission: editMedicalRecord.form.tevAdmission,
                watcher: editMedicalRecord.form.watcher,
                palliation: !!editMedicalRecord.form.palliation,
                protocolOpeningDate: (typeof editMedicalRecord.form.protocolOpeningDate === "string" && (editMedicalRecord.form.protocolOpeningDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.protocolOpeningDate as any).replace("Z", "") : editMedicalRecord.form.protocolOpeningDate.toISOString().replace("Z", "")),
                openingUnitSepsisProtocol: editMedicalRecord.form.openingUnitSepsisProtocol,
                isLPPOnAdmission: !!editMedicalRecord.form.isLPPOnAdmission,
                isLPPDuringHospitalization: !!editMedicalRecord.form.isLPPDuringHospitalization,
                departureDate: editMedicalRecord.form.departureDate === null || editMedicalRecord.form.departureDate === undefined ? null : (typeof editMedicalRecord.form.departureDate === "string" && (editMedicalRecord.form.departureDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.departureDate as any).replace("Z", "") : editMedicalRecord.form.departureDate.toISOString().replace("Z", "")),
                outputUnit: editMedicalRecord.form.outputUnit,
                isSurgeryCenter: !!editMedicalRecord.form.isSurgeryCenter,
                death: editMedicalRecord.form.death === null || editMedicalRecord.form.death === undefined ? null : (typeof editMedicalRecord.form.death === "string" && (editMedicalRecord.form.death as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.death as any).replace("Z", "") : editMedicalRecord.form.death.toISOString().replace("Z", "")),
                nsa: editMedicalRecord.form.nsa || 0,
                escore: editMedicalRecord.form.escore,
                cvcInstDate: editMedicalRecord.form.cvcInstDate === null || editMedicalRecord.form.cvcInstDate === undefined ? null : (typeof editMedicalRecord.form.cvcInstDate === "string" && (editMedicalRecord.form.cvcInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.cvcInstDate as any).replace("Z", "") : editMedicalRecord.form.cvcInstDate.toISOString().replace("Z", "")),
                cvcRetDate: editMedicalRecord.form.cvcRetDate === null || editMedicalRecord.form.cvcRetDate === undefined ? null : (typeof editMedicalRecord.form.cvcRetDate === "string" && (editMedicalRecord.form.cvcRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.cvcRetDate as any).replace("Z", "") : editMedicalRecord.form.cvcRetDate.toISOString().replace("Z", "")),
                totInstDate: editMedicalRecord.form.totInstDate === null || editMedicalRecord.form.totInstDate === undefined ? null : (typeof editMedicalRecord.form.totInstDate === "string" && (editMedicalRecord.form.totInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.totInstDate as any).replace("Z", "") : editMedicalRecord.form.totInstDate.toISOString().replace("Z", "")),
                totRetDate: editMedicalRecord.form.totRetDate === null || editMedicalRecord.form.totRetDate === undefined ? null : (typeof editMedicalRecord.form.totRetDate === "string" && (editMedicalRecord.form.totRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.totRetDate as any).replace("Z", "") : editMedicalRecord.form.totRetDate.toISOString().replace("Z", "")),
                svdInstDate: editMedicalRecord.form.svdInstDate === null || editMedicalRecord.form.svdInstDate === undefined ? null : (typeof editMedicalRecord.form.svdInstDate === "string" && (editMedicalRecord.form.svdInstDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.svdInstDate as any).replace("Z", "") : editMedicalRecord.form.svdInstDate.toISOString().replace("Z", "")),
                svdRetDate: editMedicalRecord.form.svdRetDate === null || editMedicalRecord.form.svdRetDate === undefined ? null : (typeof editMedicalRecord.form.svdRetDate === "string" && (editMedicalRecord.form.svdRetDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMedicalRecord.form.svdRetDate as any).replace("Z", "") : editMedicalRecord.form.svdRetDate.toISOString().replace("Z", "")),
                exitLocation: editMedicalRecord.form.exitLocation,
            },
        },
        routineId: routineId,
    };
    const ret = await makeRequest({name: "checkMedicalRecordRoutine", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        patient: {
            id: ret.patient.id,
            fullname: ret.patient.fullname,
            age: ret.patient.age || 0,
            birthdate: new Date(ret.patient.birthdate + "Z"),
            genre: ret.patient.genre,
            documentNumber: ret.patient.documentNumber,
        },
        bed: {
            id: ret.bed.id,
            available: !!ret.bed.available,
            createdAt: new Date(ret.bed.createdAt + "Z"),
            updatedAt: new Date(ret.bed.updatedAt + "Z"),
            code: ret.bed.code,
        },
        form: {
            plan: ret.form.plan,
            unit: ret.form.unit,
            hospitalAdmissionDate: new Date(ret.form.hospitalAdmissionDate + "Z"),
            utiAdmissionDate: new Date(ret.form.utiAdmissionDate + "Z"),
            patientOrigin: ret.form.patientOrigin,
            reasonForUTIAdmission: ret.form.reasonForUTIAdmission,
            apache2: ret.form.apache2,
            fragility: ret.form.fragility,
            charlson: ret.form.charlson,
            sofa1: ret.form.sofa1,
            sofa2: ret.form.sofa2,
            sofa3: ret.form.sofa3,
            sofaDischarged: ret.form.sofaDischarged,
            saps3: ret.form.saps3,
            isEag: !!ret.form.isEag,
            tevAdmission: ret.form.tevAdmission,
            watcher: ret.form.watcher,
            palliation: !!ret.form.palliation,
            protocolOpeningDate: new Date(ret.form.protocolOpeningDate + "Z"),
            openingUnitSepsisProtocol: ret.form.openingUnitSepsisProtocol,
            isLPPOnAdmission: !!ret.form.isLPPOnAdmission,
            isLPPDuringHospitalization: !!ret.form.isLPPDuringHospitalization,
            departureDate: ret.form.departureDate === null || ret.form.departureDate === undefined ? null : new Date(ret.form.departureDate + "Z"),
            outputUnit: ret.form.outputUnit,
            isSurgeryCenter: !!ret.form.isSurgeryCenter,
            death: ret.form.death === null || ret.form.death === undefined ? null : new Date(ret.form.death + "Z"),
            nsa: ret.form.nsa || 0,
            escore: ret.form.escore,
            cvcInstDate: ret.form.cvcInstDate === null || ret.form.cvcInstDate === undefined ? null : new Date(ret.form.cvcInstDate + "Z"),
            cvcRetDate: ret.form.cvcRetDate === null || ret.form.cvcRetDate === undefined ? null : new Date(ret.form.cvcRetDate + "Z"),
            totInstDate: ret.form.totInstDate === null || ret.form.totInstDate === undefined ? null : new Date(ret.form.totInstDate + "Z"),
            totRetDate: ret.form.totRetDate === null || ret.form.totRetDate === undefined ? null : new Date(ret.form.totRetDate + "Z"),
            svdInstDate: ret.form.svdInstDate === null || ret.form.svdInstDate === undefined ? null : new Date(ret.form.svdInstDate + "Z"),
            svdRetDate: ret.form.svdRetDate === null || ret.form.svdRetDate === undefined ? null : new Date(ret.form.svdRetDate + "Z"),
            exitLocation: ret.form.exitLocation,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        isHospitalized: !!ret.isHospitalized,
    };
}

export async function getMultidisciplinaryVisitById(id: string, progress?: (progress: number) => void): Promise<MultidisciplinaryVisit> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMultidisciplinaryVisitById", args, progress});
    return {
        id: ret.id,
        medicalRecord: {
            id: ret.medicalRecord.id,
            code: ret.medicalRecord.code,
            patient: {
                id: ret.medicalRecord.patient.id,
                fullname: ret.medicalRecord.patient.fullname,
                age: ret.medicalRecord.patient.age || 0,
                birthdate: new Date(ret.medicalRecord.patient.birthdate + "Z"),
                genre: ret.medicalRecord.patient.genre,
                documentNumber: ret.medicalRecord.patient.documentNumber,
            },
            bed: {
                id: ret.medicalRecord.bed.id,
                available: !!ret.medicalRecord.bed.available,
                createdAt: new Date(ret.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(ret.medicalRecord.bed.updatedAt + "Z"),
                code: ret.medicalRecord.bed.code,
            },
            createdAt: new Date(ret.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(ret.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!ret.medicalRecord.isHospitalized,
        },
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        date: new Date(ret.date + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function getAllMultidisciplinaryVisitByFilterOptions(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowMultidisciplinaryVisit[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllMultidisciplinaryVisitByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        medicalRecord: {
            id: e.medicalRecord.id,
            code: e.medicalRecord.code,
            patient: {
                id: e.medicalRecord.patient.id,
                fullname: e.medicalRecord.patient.fullname,
                age: e.medicalRecord.patient.age || 0,
                birthdate: new Date(e.medicalRecord.patient.birthdate + "Z"),
                genre: e.medicalRecord.patient.genre,
                documentNumber: e.medicalRecord.patient.documentNumber,
            },
            bed: {
                id: e.medicalRecord.bed.id,
                available: !!e.medicalRecord.bed.available,
                createdAt: new Date(e.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(e.medicalRecord.bed.updatedAt + "Z"),
                code: e.medicalRecord.bed.code,
            },
            createdAt: new Date(e.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(e.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!e.medicalRecord.isHospitalized,
        },
        date: new Date(e.date + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
    }));
}

export async function createMultidisciplinaryVisit(newMultidisciplinaryVisit: NewMultidisciplinaryVisit, routineId: string, progress?: (progress: number) => void): Promise<MultidisciplinaryVisit> {
    const args = {
        newMultidisciplinaryVisit: {
            medicalRecordId: newMultidisciplinaryVisit.medicalRecordId,
            form: {
                whatNeedsToBeDischargedDailyGoal: newMultidisciplinaryVisit.form.whatNeedsToBeDischargedDailyGoal,
                whatNeedsToBeDischargedResults: newMultidisciplinaryVisit.form.whatNeedsToBeDischargedResults,
                sedationDeliriumRassTarget: newMultidisciplinaryVisit.form.sedationDeliriumRassTarget,
                sedationDeliriumDailyGoal: newMultidisciplinaryVisit.form.sedationDeliriumDailyGoal,
                sedationDeliriumResults: newMultidisciplinaryVisit.form.sedationDeliriumResults,
                cardiovascularLactate: newMultidisciplinaryVisit.form.cardiovascularLactate,
                cardiovascularHemodynamicMonitoring: !!newMultidisciplinaryVisit.form.cardiovascularHemodynamicMonitoring,
                cardiovascularDailyGoal: newMultidisciplinaryVisit.form.cardiovascularDailyGoal,
                cardiovascularResults: newMultidisciplinaryVisit.form.cardiovascularResults,
                cardiovascularPam: newMultidisciplinaryVisit.form.cardiovascularPam,
                cardiovascularPas: newMultidisciplinaryVisit.form.cardiovascularPas,
                cardiovascularPad: newMultidisciplinaryVisit.form.cardiovascularPad,
                pulmonaryCe30: !!newMultidisciplinaryVisit.form.pulmonaryCe30,
                pulmonaryO2SupportActs: newMultidisciplinaryVisit.form.pulmonaryO2SupportActs,
                pulmonaryDailyGoal: newMultidisciplinaryVisit.form.pulmonaryDailyGoal,
                pulmonaryResults: newMultidisciplinaryVisit.form.pulmonaryResults,
                pulmonaryDisarm: !!newMultidisciplinaryVisit.form.pulmonaryDisarm,
                pulmonaryTargetO2Saturation: newMultidisciplinaryVisit.form.pulmonaryTargetO2Saturation,
                mobilizationReleased: !!newMultidisciplinaryVisit.form.mobilizationReleased,
                mobilizationDailyGoal: newMultidisciplinaryVisit.form.mobilizationDailyGoal,
                mobilizationResults: newMultidisciplinaryVisit.form.mobilizationResults,
                renalhd: !!newMultidisciplinaryVisit.form.renalhd,
                renalDiuretic: !!newMultidisciplinaryVisit.form.renalDiuretic,
                renalBhLast24Hours: !!newMultidisciplinaryVisit.form.renalBhLast24Hours,
                renalBhTarget: newMultidisciplinaryVisit.form.renalBhTarget,
                renalDailyGoal: newMultidisciplinaryVisit.form.renalDailyGoal,
                renalResults: newMultidisciplinaryVisit.form.renalResults,
                hematousFever: !!newMultidisciplinaryVisit.form.hematousFever,
                hematousDoseOfAtb: !!newMultidisciplinaryVisit.form.hematousDoseOfAtb,
                hematousHbFall: !!newMultidisciplinaryVisit.form.hematousHbFall,
                hematousDailyGoal: newMultidisciplinaryVisit.form.hematousDailyGoal,
                hematousResults: newMultidisciplinaryVisit.form.hematousResults,
                glucose140To180: !!newMultidisciplinaryVisit.form.glucose140To180,
                glucoseLiberatedDiet: !!newMultidisciplinaryVisit.form.glucoseLiberatedDiet,
                glucoseDailyGoal: newMultidisciplinaryVisit.form.glucoseDailyGoal,
                glucoseResults: newMultidisciplinaryVisit.form.glucoseResults,
                prophylaxisDescription: newMultidisciplinaryVisit.form.prophylaxisDescription,
                prophylaxisDailyGoal: newMultidisciplinaryVisit.form.prophylaxisDailyGoal,
                prophylaxisResults: newMultidisciplinaryVisit.form.prophylaxisResults,
                skinIntegrityDescription: newMultidisciplinaryVisit.form.skinIntegrityDescription,
                skinIntegrityDailyGoal: newMultidisciplinaryVisit.form.skinIntegrityDailyGoal,
                skinIntegrityResults: newMultidisciplinaryVisit.form.skinIntegrityResults,
                devicesRemove: !!newMultidisciplinaryVisit.form.devicesRemove,
                devicesCvcDate: newMultidisciplinaryVisit.form.devicesCvcDate === null || newMultidisciplinaryVisit.form.devicesCvcDate === undefined ? null : (typeof newMultidisciplinaryVisit.form.devicesCvcDate === "string" && (newMultidisciplinaryVisit.form.devicesCvcDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMultidisciplinaryVisit.form.devicesCvcDate as any).replace("Z", "") : newMultidisciplinaryVisit.form.devicesCvcDate.toISOString().replace("Z", "")),
                devicesPamiDate: newMultidisciplinaryVisit.form.devicesPamiDate === null || newMultidisciplinaryVisit.form.devicesPamiDate === undefined ? null : (typeof newMultidisciplinaryVisit.form.devicesPamiDate === "string" && (newMultidisciplinaryVisit.form.devicesPamiDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMultidisciplinaryVisit.form.devicesPamiDate as any).replace("Z", "") : newMultidisciplinaryVisit.form.devicesPamiDate.toISOString().replace("Z", "")),
                devicesSvdDate: newMultidisciplinaryVisit.form.devicesSvdDate === null || newMultidisciplinaryVisit.form.devicesSvdDate === undefined ? null : (typeof newMultidisciplinaryVisit.form.devicesSvdDate === "string" && (newMultidisciplinaryVisit.form.devicesSvdDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMultidisciplinaryVisit.form.devicesSvdDate as any).replace("Z", "") : newMultidisciplinaryVisit.form.devicesSvdDate.toISOString().replace("Z", "")),
                devicesPhdDate: newMultidisciplinaryVisit.form.devicesPhdDate === null || newMultidisciplinaryVisit.form.devicesPhdDate === undefined ? null : (typeof newMultidisciplinaryVisit.form.devicesPhdDate === "string" && (newMultidisciplinaryVisit.form.devicesPhdDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newMultidisciplinaryVisit.form.devicesPhdDate as any).replace("Z", "") : newMultidisciplinaryVisit.form.devicesPhdDate.toISOString().replace("Z", "")),
                devicesDailyGoal: newMultidisciplinaryVisit.form.devicesDailyGoal,
                devicesResults: newMultidisciplinaryVisit.form.devicesResults,
                consultationsDescription: newMultidisciplinaryVisit.form.consultationsDescription,
                consultationsDailyGoal: newMultidisciplinaryVisit.form.consultationsDailyGoal,
                consultationsResults: newMultidisciplinaryVisit.form.consultationsResults,
                medicalExamsDescription: newMultidisciplinaryVisit.form.medicalExamsDescription,
                medicalExamsDailyGoal: newMultidisciplinaryVisit.form.medicalExamsDailyGoal,
                medicalExamsResults: newMultidisciplinaryVisit.form.medicalExamsResults,
                familyCommunicationDescription: newMultidisciplinaryVisit.form.familyCommunicationDescription,
                familyCommunicationHomeCare: !!newMultidisciplinaryVisit.form.familyCommunicationHomeCare,
                familyCommunicationPalliation: !!newMultidisciplinaryVisit.form.familyCommunicationPalliation,
                familyCommunicationDailyGoal: newMultidisciplinaryVisit.form.familyCommunicationDailyGoal,
                familyCommunicationResults: newMultidisciplinaryVisit.form.familyCommunicationResults,
                isPatientWatcher: !!newMultidisciplinaryVisit.form.isPatientWatcher,
            },
        },
        routineId: routineId,
    };
    const ret = await makeRequest({name: "createMultidisciplinaryVisit", args, progress});
    return {
        id: ret.id,
        medicalRecord: {
            id: ret.medicalRecord.id,
            code: ret.medicalRecord.code,
            patient: {
                id: ret.medicalRecord.patient.id,
                fullname: ret.medicalRecord.patient.fullname,
                age: ret.medicalRecord.patient.age || 0,
                birthdate: new Date(ret.medicalRecord.patient.birthdate + "Z"),
                genre: ret.medicalRecord.patient.genre,
                documentNumber: ret.medicalRecord.patient.documentNumber,
            },
            bed: {
                id: ret.medicalRecord.bed.id,
                available: !!ret.medicalRecord.bed.available,
                createdAt: new Date(ret.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(ret.medicalRecord.bed.updatedAt + "Z"),
                code: ret.medicalRecord.bed.code,
            },
            createdAt: new Date(ret.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(ret.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!ret.medicalRecord.isHospitalized,
        },
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        date: new Date(ret.date + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function deleteMultidisciplinaryVisitById(id: string, progress?: (progress: number) => void): Promise<MultidisciplinaryVisitWithoutMedicalRecord> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteMultidisciplinaryVisitById", args, progress});
    return {
        id: ret.id,
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        date: new Date(ret.date + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function editMultidisciplinaryVisit(id: string, editMultidisciplinaryVisit: EditMultidisciplinaryVisit, progress?: (progress: number) => void): Promise<MultidisciplinaryVisitWithoutMedicalRecord> {
    const args = {
        id: id,
        editMultidisciplinaryVisit: {
            whatNeedsToBeDischargedDailyGoal: editMultidisciplinaryVisit.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: editMultidisciplinaryVisit.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: editMultidisciplinaryVisit.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: editMultidisciplinaryVisit.sedationDeliriumDailyGoal,
            sedationDeliriumResults: editMultidisciplinaryVisit.sedationDeliriumResults,
            cardiovascularLactate: editMultidisciplinaryVisit.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!editMultidisciplinaryVisit.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: editMultidisciplinaryVisit.cardiovascularDailyGoal,
            cardiovascularResults: editMultidisciplinaryVisit.cardiovascularResults,
            cardiovascularPam: editMultidisciplinaryVisit.cardiovascularPam,
            cardiovascularPas: editMultidisciplinaryVisit.cardiovascularPas,
            cardiovascularPad: editMultidisciplinaryVisit.cardiovascularPad,
            pulmonaryCe30: !!editMultidisciplinaryVisit.pulmonaryCe30,
            pulmonaryO2SupportActs: editMultidisciplinaryVisit.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: editMultidisciplinaryVisit.pulmonaryDailyGoal,
            pulmonaryResults: editMultidisciplinaryVisit.pulmonaryResults,
            pulmonaryDisarm: !!editMultidisciplinaryVisit.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: editMultidisciplinaryVisit.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!editMultidisciplinaryVisit.mobilizationReleased,
            mobilizationDailyGoal: editMultidisciplinaryVisit.mobilizationDailyGoal,
            mobilizationResults: editMultidisciplinaryVisit.mobilizationResults,
            renalhd: !!editMultidisciplinaryVisit.renalhd,
            renalDiuretic: !!editMultidisciplinaryVisit.renalDiuretic,
            renalBhLast24Hours: !!editMultidisciplinaryVisit.renalBhLast24Hours,
            renalBhTarget: editMultidisciplinaryVisit.renalBhTarget,
            renalDailyGoal: editMultidisciplinaryVisit.renalDailyGoal,
            renalResults: editMultidisciplinaryVisit.renalResults,
            hematousFever: !!editMultidisciplinaryVisit.hematousFever,
            hematousDoseOfAtb: !!editMultidisciplinaryVisit.hematousDoseOfAtb,
            hematousHbFall: !!editMultidisciplinaryVisit.hematousHbFall,
            hematousDailyGoal: editMultidisciplinaryVisit.hematousDailyGoal,
            hematousResults: editMultidisciplinaryVisit.hematousResults,
            glucose140To180: !!editMultidisciplinaryVisit.glucose140To180,
            glucoseLiberatedDiet: !!editMultidisciplinaryVisit.glucoseLiberatedDiet,
            glucoseDailyGoal: editMultidisciplinaryVisit.glucoseDailyGoal,
            glucoseResults: editMultidisciplinaryVisit.glucoseResults,
            prophylaxisDescription: editMultidisciplinaryVisit.prophylaxisDescription,
            prophylaxisDailyGoal: editMultidisciplinaryVisit.prophylaxisDailyGoal,
            prophylaxisResults: editMultidisciplinaryVisit.prophylaxisResults,
            skinIntegrityDescription: editMultidisciplinaryVisit.skinIntegrityDescription,
            skinIntegrityDailyGoal: editMultidisciplinaryVisit.skinIntegrityDailyGoal,
            skinIntegrityResults: editMultidisciplinaryVisit.skinIntegrityResults,
            devicesRemove: !!editMultidisciplinaryVisit.devicesRemove,
            devicesCvcDate: editMultidisciplinaryVisit.devicesCvcDate === null || editMultidisciplinaryVisit.devicesCvcDate === undefined ? null : (typeof editMultidisciplinaryVisit.devicesCvcDate === "string" && (editMultidisciplinaryVisit.devicesCvcDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMultidisciplinaryVisit.devicesCvcDate as any).replace("Z", "") : editMultidisciplinaryVisit.devicesCvcDate.toISOString().replace("Z", "")),
            devicesPamiDate: editMultidisciplinaryVisit.devicesPamiDate === null || editMultidisciplinaryVisit.devicesPamiDate === undefined ? null : (typeof editMultidisciplinaryVisit.devicesPamiDate === "string" && (editMultidisciplinaryVisit.devicesPamiDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMultidisciplinaryVisit.devicesPamiDate as any).replace("Z", "") : editMultidisciplinaryVisit.devicesPamiDate.toISOString().replace("Z", "")),
            devicesSvdDate: editMultidisciplinaryVisit.devicesSvdDate === null || editMultidisciplinaryVisit.devicesSvdDate === undefined ? null : (typeof editMultidisciplinaryVisit.devicesSvdDate === "string" && (editMultidisciplinaryVisit.devicesSvdDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMultidisciplinaryVisit.devicesSvdDate as any).replace("Z", "") : editMultidisciplinaryVisit.devicesSvdDate.toISOString().replace("Z", "")),
            devicesPhdDate: editMultidisciplinaryVisit.devicesPhdDate === null || editMultidisciplinaryVisit.devicesPhdDate === undefined ? null : (typeof editMultidisciplinaryVisit.devicesPhdDate === "string" && (editMultidisciplinaryVisit.devicesPhdDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editMultidisciplinaryVisit.devicesPhdDate as any).replace("Z", "") : editMultidisciplinaryVisit.devicesPhdDate.toISOString().replace("Z", "")),
            devicesDailyGoal: editMultidisciplinaryVisit.devicesDailyGoal,
            devicesResults: editMultidisciplinaryVisit.devicesResults,
            consultationsDescription: editMultidisciplinaryVisit.consultationsDescription,
            consultationsDailyGoal: editMultidisciplinaryVisit.consultationsDailyGoal,
            consultationsResults: editMultidisciplinaryVisit.consultationsResults,
            medicalExamsDescription: editMultidisciplinaryVisit.medicalExamsDescription,
            medicalExamsDailyGoal: editMultidisciplinaryVisit.medicalExamsDailyGoal,
            medicalExamsResults: editMultidisciplinaryVisit.medicalExamsResults,
            familyCommunicationDescription: editMultidisciplinaryVisit.familyCommunicationDescription,
            familyCommunicationHomeCare: !!editMultidisciplinaryVisit.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!editMultidisciplinaryVisit.familyCommunicationPalliation,
            familyCommunicationDailyGoal: editMultidisciplinaryVisit.familyCommunicationDailyGoal,
            familyCommunicationResults: editMultidisciplinaryVisit.familyCommunicationResults,
            isPatientWatcher: !!editMultidisciplinaryVisit.isPatientWatcher,
        },
    };
    const ret = await makeRequest({name: "editMultidisciplinaryVisit", args, progress});
    return {
        id: ret.id,
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        date: new Date(ret.date + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function exportMultidisciplinaryVisit(filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "exportMultidisciplinaryVisit", args, progress});
    return ret;
}

export async function getAllMultidisciplinaryVisitByFilterOptionsForNonAdmin(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowMultidisciplinaryVisitWithoutPatient[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllMultidisciplinaryVisitByFilterOptionsForNonAdmin", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        medicalRecord: {
            id: e.medicalRecord.id,
            code: e.medicalRecord.code,
            bed: {
                id: e.medicalRecord.bed.id,
                available: !!e.medicalRecord.bed.available,
                createdAt: new Date(e.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(e.medicalRecord.bed.updatedAt + "Z"),
                code: e.medicalRecord.bed.code,
            },
            createdAt: new Date(e.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(e.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!e.medicalRecord.isHospitalized,
        },
        date: new Date(e.date + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
    }));
}

export async function getMultidisciplinaryVisitByIdForNonAdmin(id: string, progress?: (progress: number) => void): Promise<MultidisciplinaryVisitWithoutPatient> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMultidisciplinaryVisitByIdForNonAdmin", args, progress});
    return {
        id: ret.id,
        medicalRecord: {
            id: ret.medicalRecord.id,
            code: ret.medicalRecord.code,
            bed: {
                id: ret.medicalRecord.bed.id,
                available: !!ret.medicalRecord.bed.available,
                createdAt: new Date(ret.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(ret.medicalRecord.bed.updatedAt + "Z"),
                code: ret.medicalRecord.bed.code,
            },
            createdAt: new Date(ret.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(ret.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!ret.medicalRecord.isHospitalized,
        },
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function editMultidisciplinaryVisitForNonAdmin(id: string, editMultidisciplinaryVisit: EditMultidisciplinaryVisitForNonAdmin, progress?: (progress: number) => void): Promise<MultidisciplinaryVisitWithoutMedicalRecord> {
    const args = {
        id: id,
        editMultidisciplinaryVisit: {
            whatNeedsToBeDischargedDailyGoal: editMultidisciplinaryVisit.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: editMultidisciplinaryVisit.whatNeedsToBeDischargedResults,
            sedationDeliriumDailyGoal: editMultidisciplinaryVisit.sedationDeliriumDailyGoal,
            sedationDeliriumResults: editMultidisciplinaryVisit.sedationDeliriumResults,
            cardiovascularDailyGoal: editMultidisciplinaryVisit.cardiovascularDailyGoal,
            cardiovascularResults: editMultidisciplinaryVisit.cardiovascularResults,
            pulmonaryDailyGoal: editMultidisciplinaryVisit.pulmonaryDailyGoal,
            pulmonaryResults: editMultidisciplinaryVisit.pulmonaryResults,
            mobilizationDailyGoal: editMultidisciplinaryVisit.mobilizationDailyGoal,
            mobilizationResults: editMultidisciplinaryVisit.mobilizationResults,
            renalDailyGoal: editMultidisciplinaryVisit.renalDailyGoal,
            renalResults: editMultidisciplinaryVisit.renalResults,
            hematousDailyGoal: editMultidisciplinaryVisit.hematousDailyGoal,
            hematousResults: editMultidisciplinaryVisit.hematousResults,
            glucoseDailyGoal: editMultidisciplinaryVisit.glucoseDailyGoal,
            glucoseResults: editMultidisciplinaryVisit.glucoseResults,
            prophylaxisDailyGoal: editMultidisciplinaryVisit.prophylaxisDailyGoal,
            prophylaxisResults: editMultidisciplinaryVisit.prophylaxisResults,
            skinIntegrityDailyGoal: editMultidisciplinaryVisit.skinIntegrityDailyGoal,
            skinIntegrityResults: editMultidisciplinaryVisit.skinIntegrityResults,
            devicesDailyGoal: editMultidisciplinaryVisit.devicesDailyGoal,
            devicesResults: editMultidisciplinaryVisit.devicesResults,
            consultationsDailyGoal: editMultidisciplinaryVisit.consultationsDailyGoal,
            consultationsResults: editMultidisciplinaryVisit.consultationsResults,
            medicalExamsDailyGoal: editMultidisciplinaryVisit.medicalExamsDailyGoal,
            medicalExamsResults: editMultidisciplinaryVisit.medicalExamsResults,
            familyCommunicationDailyGoal: editMultidisciplinaryVisit.familyCommunicationDailyGoal,
            familyCommunicationResults: editMultidisciplinaryVisit.familyCommunicationResults,
        },
    };
    const ret = await makeRequest({name: "editMultidisciplinaryVisitForNonAdmin", args, progress});
    return {
        id: ret.id,
        form: {
            whatNeedsToBeDischargedDailyGoal: ret.form.whatNeedsToBeDischargedDailyGoal,
            whatNeedsToBeDischargedResults: ret.form.whatNeedsToBeDischargedResults,
            sedationDeliriumRassTarget: ret.form.sedationDeliriumRassTarget,
            sedationDeliriumDailyGoal: ret.form.sedationDeliriumDailyGoal,
            sedationDeliriumResults: ret.form.sedationDeliriumResults,
            cardiovascularLactate: ret.form.cardiovascularLactate,
            cardiovascularHemodynamicMonitoring: !!ret.form.cardiovascularHemodynamicMonitoring,
            cardiovascularDailyGoal: ret.form.cardiovascularDailyGoal,
            cardiovascularResults: ret.form.cardiovascularResults,
            cardiovascularPam: ret.form.cardiovascularPam,
            cardiovascularPas: ret.form.cardiovascularPas,
            cardiovascularPad: ret.form.cardiovascularPad,
            pulmonaryCe30: !!ret.form.pulmonaryCe30,
            pulmonaryO2SupportActs: ret.form.pulmonaryO2SupportActs,
            pulmonaryDailyGoal: ret.form.pulmonaryDailyGoal,
            pulmonaryResults: ret.form.pulmonaryResults,
            pulmonaryDisarm: !!ret.form.pulmonaryDisarm,
            pulmonaryTargetO2Saturation: ret.form.pulmonaryTargetO2Saturation,
            mobilizationReleased: !!ret.form.mobilizationReleased,
            mobilizationDailyGoal: ret.form.mobilizationDailyGoal,
            mobilizationResults: ret.form.mobilizationResults,
            renalhd: !!ret.form.renalhd,
            renalDiuretic: !!ret.form.renalDiuretic,
            renalBhLast24Hours: !!ret.form.renalBhLast24Hours,
            renalBhTarget: ret.form.renalBhTarget,
            renalDailyGoal: ret.form.renalDailyGoal,
            renalResults: ret.form.renalResults,
            hematousFever: !!ret.form.hematousFever,
            hematousDoseOfAtb: !!ret.form.hematousDoseOfAtb,
            hematousHbFall: !!ret.form.hematousHbFall,
            hematousDailyGoal: ret.form.hematousDailyGoal,
            hematousResults: ret.form.hematousResults,
            glucose140To180: !!ret.form.glucose140To180,
            glucoseLiberatedDiet: !!ret.form.glucoseLiberatedDiet,
            glucoseDailyGoal: ret.form.glucoseDailyGoal,
            glucoseResults: ret.form.glucoseResults,
            prophylaxisDescription: ret.form.prophylaxisDescription,
            prophylaxisDailyGoal: ret.form.prophylaxisDailyGoal,
            prophylaxisResults: ret.form.prophylaxisResults,
            skinIntegrityDescription: ret.form.skinIntegrityDescription,
            skinIntegrityDailyGoal: ret.form.skinIntegrityDailyGoal,
            skinIntegrityResults: ret.form.skinIntegrityResults,
            devicesRemove: !!ret.form.devicesRemove,
            devicesCvcDate: ret.form.devicesCvcDate === null || ret.form.devicesCvcDate === undefined ? null : new Date(ret.form.devicesCvcDate + "Z"),
            devicesPamiDate: ret.form.devicesPamiDate === null || ret.form.devicesPamiDate === undefined ? null : new Date(ret.form.devicesPamiDate + "Z"),
            devicesSvdDate: ret.form.devicesSvdDate === null || ret.form.devicesSvdDate === undefined ? null : new Date(ret.form.devicesSvdDate + "Z"),
            devicesPhdDate: ret.form.devicesPhdDate === null || ret.form.devicesPhdDate === undefined ? null : new Date(ret.form.devicesPhdDate + "Z"),
            devicesDailyGoal: ret.form.devicesDailyGoal,
            devicesResults: ret.form.devicesResults,
            consultationsDescription: ret.form.consultationsDescription,
            consultationsDailyGoal: ret.form.consultationsDailyGoal,
            consultationsResults: ret.form.consultationsResults,
            medicalExamsDescription: ret.form.medicalExamsDescription,
            medicalExamsDailyGoal: ret.form.medicalExamsDailyGoal,
            medicalExamsResults: ret.form.medicalExamsResults,
            familyCommunicationDescription: ret.form.familyCommunicationDescription,
            familyCommunicationHomeCare: !!ret.form.familyCommunicationHomeCare,
            familyCommunicationPalliation: !!ret.form.familyCommunicationPalliation,
            familyCommunicationDailyGoal: ret.form.familyCommunicationDailyGoal,
            familyCommunicationResults: ret.form.familyCommunicationResults,
            isPatientWatcher: !!ret.form.isPatientWatcher,
        },
        date: new Date(ret.date + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getAllRoutineManagementByFilterOptions(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowRoutineManagement[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllRoutineManagementByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        medicalRecord: {
            id: e.medicalRecord.id,
            code: e.medicalRecord.code,
            bed: {
                id: e.medicalRecord.bed.id,
                available: !!e.medicalRecord.bed.available,
                createdAt: new Date(e.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(e.medicalRecord.bed.updatedAt + "Z"),
                code: e.medicalRecord.bed.code,
            },
            createdAt: new Date(e.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(e.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!e.medicalRecord.isHospitalized,
        },
        isMedicalRecordChecked: !!e.isMedicalRecordChecked,
        safetyHuddleId: e.safetyHuddleId === null || e.safetyHuddleId === undefined ? null : e.safetyHuddleId,
        multidisciplinaryVisitId: e.multidisciplinaryVisitId === null || e.multidisciplinaryVisitId === undefined ? null : e.multidisciplinaryVisitId,
    }));
}

export async function getRoutineManagementById(id: string, progress?: (progress: number) => void): Promise<ShowRoutineManagement> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRoutineManagementById", args, progress});
    return {
        id: ret.id,
        date: ret.date === null || ret.date === undefined ? null : new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        medicalRecord: {
            id: ret.medicalRecord.id,
            code: ret.medicalRecord.code,
            bed: {
                id: ret.medicalRecord.bed.id,
                available: !!ret.medicalRecord.bed.available,
                createdAt: new Date(ret.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(ret.medicalRecord.bed.updatedAt + "Z"),
                code: ret.medicalRecord.bed.code,
            },
            createdAt: new Date(ret.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(ret.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!ret.medicalRecord.isHospitalized,
        },
        isMedicalRecordChecked: !!ret.isMedicalRecordChecked,
        safetyHuddleId: ret.safetyHuddleId === null || ret.safetyHuddleId === undefined ? null : ret.safetyHuddleId,
        multidisciplinaryVisitId: ret.multidisciplinaryVisitId === null || ret.multidisciplinaryVisitId === undefined ? null : ret.multidisciplinaryVisitId,
    };
}

export async function getAllNotCompleteRoutineManagementByFilterOptions(pageOffset: number, filterOptions: FilterOptions, progress?: (progress: number) => void): Promise<ShowRoutineManagement[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            bedNumber: filterOptions.bedNumber === null || filterOptions.bedNumber === undefined ? null : filterOptions.bedNumber,
            code: filterOptions.code === null || filterOptions.code === undefined ? null : filterOptions.code,
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllNotCompleteRoutineManagementByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        medicalRecord: {
            id: e.medicalRecord.id,
            code: e.medicalRecord.code,
            bed: {
                id: e.medicalRecord.bed.id,
                available: !!e.medicalRecord.bed.available,
                createdAt: new Date(e.medicalRecord.bed.createdAt + "Z"),
                updatedAt: new Date(e.medicalRecord.bed.updatedAt + "Z"),
                code: e.medicalRecord.bed.code,
            },
            createdAt: new Date(e.medicalRecord.createdAt + "Z"),
            updatedAt: new Date(e.medicalRecord.updatedAt + "Z"),
            isHospitalized: !!e.medicalRecord.isHospitalized,
        },
        isMedicalRecordChecked: !!e.isMedicalRecordChecked,
        safetyHuddleId: e.safetyHuddleId === null || e.safetyHuddleId === undefined ? null : e.safetyHuddleId,
        multidisciplinaryVisitId: e.multidisciplinaryVisitId === null || e.multidisciplinaryVisitId === undefined ? null : e.multidisciplinaryVisitId,
    }));
}

export async function getAllSafetyHuddlesByFilterOptions(pageOffset: number, filterByPeriod: FilterByPeriod, progress?: (progress: number) => void): Promise<ShowSafetyHuddle[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByPeriod: {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllSafetyHuddlesByFilterOptions", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        date: new Date(e.date + "Z"),
        creator: {
            id: e.creator.id,
            createdAt: new Date(e.creator.createdAt + "Z"),
            adminType: e.creator.adminType,
            name: e.creator.name,
            email: e.creator.email,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getSafetyHuddleById(id: string, progress?: (progress: number) => void): Promise<SafetyHuddle> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getSafetyHuddleById", args, progress});
    return {
        id: ret.id,
        creator: {
            id: ret.creator.id,
            createdAt: new Date(ret.creator.createdAt + "Z"),
            adminType: ret.creator.adminType,
            name: ret.creator.name,
            email: ret.creator.email,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        date: new Date(ret.date + "Z"),
        positionDayLaborer: ret.positionDayLaborer,
        positionNurses: ret.positionNurses,
        positionPsychologist: ret.positionPsychologist,
        positionOnDuty: ret.positionOnDuty,
        positionPhysicalTherapists: ret.positionPhysicalTherapists,
        positionPharmacy: ret.positionPharmacy,
        positionTechnical: ret.positionTechnical,
        positionAdm: ret.positionAdm,
        positionOthers: ret.positionOthers,
        amountOfwatcherPatientsOnTheUnit: ret.amountOfwatcherPatientsOnTheUnit === null || ret.amountOfwatcherPatientsOnTheUnit === undefined ? null : ret.amountOfwatcherPatientsOnTheUnit || 0,
        watcherPatients: ret.watcherPatients === null || ret.watcherPatients === undefined ? null : ret.watcherPatients,
        amountOfSeverePatientInTheUnit: ret.amountOfSeverePatientInTheUnit === null || ret.amountOfSeverePatientInTheUnit === undefined ? null : ret.amountOfSeverePatientInTheUnit || 0,
        newtherapiesimplementedInTheUti: ret.newtherapiesimplementedInTheUti === null || ret.newtherapiesimplementedInTheUti === undefined ? null : ret.newtherapiesimplementedInTheUti,
        amountOfDischargedPatientsThatDidnNotLeave: ret.amountOfDischargedPatientsThatDidnNotLeave === null || ret.amountOfDischargedPatientsThatDidnNotLeave === undefined ? null : ret.amountOfDischargedPatientsThatDidnNotLeave || 0,
        amountOfPatientsWithOrganDysfunction24Hours: ret.amountOfPatientsWithOrganDysfunction24Hours === null || ret.amountOfPatientsWithOrganDysfunction24Hours === undefined ? null : ret.amountOfPatientsWithOrganDysfunction24Hours || 0,
        amountOfInvasiveProceduresThatDidNotHappenYesterday: ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === null || ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === undefined ? null : ret.amountOfInvasiveProceduresThatDidNotHappenYesterday || 0,
        amountOfCommunicationProblemsWithyFamilyOrUti: ret.amountOfCommunicationProblemsWithyFamilyOrUti === null || ret.amountOfCommunicationProblemsWithyFamilyOrUti === undefined ? null : ret.amountOfCommunicationProblemsWithyFamilyOrUti || 0,
        amountOfImagingOrLabTestsThatDidNotHappen: ret.amountOfImagingOrLabTestsThatDidNotHappen === null || ret.amountOfImagingOrLabTestsThatDidNotHappen === undefined ? null : ret.amountOfImagingOrLabTestsThatDidNotHappen || 0,
        amountOfAdverseEventsInTheLast24Hours: ret.amountOfAdverseEventsInTheLast24Hours === null || ret.amountOfAdverseEventsInTheLast24Hours === undefined ? null : ret.amountOfAdverseEventsInTheLast24Hours || 0,
        howToImproveTheQualityOfPatient: ret.howToImproveTheQualityOfPatient,
    };
}

export async function createSafetyHuddle(newSafetyHuddle: NewSafetyHuddle, progress?: (progress: number) => void): Promise<SafetyHuddle> {
    const args = {
        newSafetyHuddle: {
            date: (typeof newSafetyHuddle.date === "string" && (newSafetyHuddle.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newSafetyHuddle.date as any).replace("Z", "") : newSafetyHuddle.date.toISOString().replace("Z", "")),
            positionDayLaborer: newSafetyHuddle.positionDayLaborer,
            positionNurses: newSafetyHuddle.positionNurses,
            positionPsychologist: newSafetyHuddle.positionPsychologist,
            positionOnDuty: newSafetyHuddle.positionOnDuty,
            positionPhysicalTherapists: newSafetyHuddle.positionPhysicalTherapists,
            positionPharmacy: newSafetyHuddle.positionPharmacy,
            positionTechnical: newSafetyHuddle.positionTechnical,
            positionAdm: newSafetyHuddle.positionAdm,
            positionOthers: newSafetyHuddle.positionOthers,
            amountOfwatcherPatientsOnTheUnit: newSafetyHuddle.amountOfwatcherPatientsOnTheUnit === null || newSafetyHuddle.amountOfwatcherPatientsOnTheUnit === undefined ? null : newSafetyHuddle.amountOfwatcherPatientsOnTheUnit || 0,
            watcherPatients: newSafetyHuddle.watcherPatients === null || newSafetyHuddle.watcherPatients === undefined ? null : newSafetyHuddle.watcherPatients,
            amountOfSeverePatientInTheUnit: newSafetyHuddle.amountOfSeverePatientInTheUnit === null || newSafetyHuddle.amountOfSeverePatientInTheUnit === undefined ? null : newSafetyHuddle.amountOfSeverePatientInTheUnit || 0,
            newtherapiesimplementedInTheUti: newSafetyHuddle.newtherapiesimplementedInTheUti === null || newSafetyHuddle.newtherapiesimplementedInTheUti === undefined ? null : newSafetyHuddle.newtherapiesimplementedInTheUti,
            amountOfDischargedPatientsThatDidnNotLeave: newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave === null || newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave === undefined ? null : newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave || 0,
            amountOfPatientsWithOrganDysfunction24Hours: newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours === null || newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours === undefined ? null : newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours || 0,
            amountOfInvasiveProceduresThatDidNotHappenYesterday: newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday === null || newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday === undefined ? null : newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday || 0,
            amountOfCommunicationProblemsWithyFamilyOrUti: newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti === null || newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti === undefined ? null : newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti || 0,
            amountOfImagingOrLabTestsThatDidNotHappen: newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen === null || newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen === undefined ? null : newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen || 0,
            amountOfAdverseEventsInTheLast24Hours: newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours === null || newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours === undefined ? null : newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours || 0,
            howToImproveTheQualityOfPatient: newSafetyHuddle.howToImproveTheQualityOfPatient,
        },
    };
    const ret = await makeRequest({name: "createSafetyHuddle", args, progress});
    return {
        id: ret.id,
        creator: {
            id: ret.creator.id,
            createdAt: new Date(ret.creator.createdAt + "Z"),
            adminType: ret.creator.adminType,
            name: ret.creator.name,
            email: ret.creator.email,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        date: new Date(ret.date + "Z"),
        positionDayLaborer: ret.positionDayLaborer,
        positionNurses: ret.positionNurses,
        positionPsychologist: ret.positionPsychologist,
        positionOnDuty: ret.positionOnDuty,
        positionPhysicalTherapists: ret.positionPhysicalTherapists,
        positionPharmacy: ret.positionPharmacy,
        positionTechnical: ret.positionTechnical,
        positionAdm: ret.positionAdm,
        positionOthers: ret.positionOthers,
        amountOfwatcherPatientsOnTheUnit: ret.amountOfwatcherPatientsOnTheUnit === null || ret.amountOfwatcherPatientsOnTheUnit === undefined ? null : ret.amountOfwatcherPatientsOnTheUnit || 0,
        watcherPatients: ret.watcherPatients === null || ret.watcherPatients === undefined ? null : ret.watcherPatients,
        amountOfSeverePatientInTheUnit: ret.amountOfSeverePatientInTheUnit === null || ret.amountOfSeverePatientInTheUnit === undefined ? null : ret.amountOfSeverePatientInTheUnit || 0,
        newtherapiesimplementedInTheUti: ret.newtherapiesimplementedInTheUti === null || ret.newtherapiesimplementedInTheUti === undefined ? null : ret.newtherapiesimplementedInTheUti,
        amountOfDischargedPatientsThatDidnNotLeave: ret.amountOfDischargedPatientsThatDidnNotLeave === null || ret.amountOfDischargedPatientsThatDidnNotLeave === undefined ? null : ret.amountOfDischargedPatientsThatDidnNotLeave || 0,
        amountOfPatientsWithOrganDysfunction24Hours: ret.amountOfPatientsWithOrganDysfunction24Hours === null || ret.amountOfPatientsWithOrganDysfunction24Hours === undefined ? null : ret.amountOfPatientsWithOrganDysfunction24Hours || 0,
        amountOfInvasiveProceduresThatDidNotHappenYesterday: ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === null || ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === undefined ? null : ret.amountOfInvasiveProceduresThatDidNotHappenYesterday || 0,
        amountOfCommunicationProblemsWithyFamilyOrUti: ret.amountOfCommunicationProblemsWithyFamilyOrUti === null || ret.amountOfCommunicationProblemsWithyFamilyOrUti === undefined ? null : ret.amountOfCommunicationProblemsWithyFamilyOrUti || 0,
        amountOfImagingOrLabTestsThatDidNotHappen: ret.amountOfImagingOrLabTestsThatDidNotHappen === null || ret.amountOfImagingOrLabTestsThatDidNotHappen === undefined ? null : ret.amountOfImagingOrLabTestsThatDidNotHappen || 0,
        amountOfAdverseEventsInTheLast24Hours: ret.amountOfAdverseEventsInTheLast24Hours === null || ret.amountOfAdverseEventsInTheLast24Hours === undefined ? null : ret.amountOfAdverseEventsInTheLast24Hours || 0,
        howToImproveTheQualityOfPatient: ret.howToImproveTheQualityOfPatient,
    };
}

export async function editSafetyHuddle(id: string, newSafetyHuddle: NewSafetyHuddle, progress?: (progress: number) => void): Promise<SafetyHuddle> {
    const args = {
        id: id,
        newSafetyHuddle: {
            date: (typeof newSafetyHuddle.date === "string" && (newSafetyHuddle.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newSafetyHuddle.date as any).replace("Z", "") : newSafetyHuddle.date.toISOString().replace("Z", "")),
            positionDayLaborer: newSafetyHuddle.positionDayLaborer,
            positionNurses: newSafetyHuddle.positionNurses,
            positionPsychologist: newSafetyHuddle.positionPsychologist,
            positionOnDuty: newSafetyHuddle.positionOnDuty,
            positionPhysicalTherapists: newSafetyHuddle.positionPhysicalTherapists,
            positionPharmacy: newSafetyHuddle.positionPharmacy,
            positionTechnical: newSafetyHuddle.positionTechnical,
            positionAdm: newSafetyHuddle.positionAdm,
            positionOthers: newSafetyHuddle.positionOthers,
            amountOfwatcherPatientsOnTheUnit: newSafetyHuddle.amountOfwatcherPatientsOnTheUnit === null || newSafetyHuddle.amountOfwatcherPatientsOnTheUnit === undefined ? null : newSafetyHuddle.amountOfwatcherPatientsOnTheUnit || 0,
            watcherPatients: newSafetyHuddle.watcherPatients === null || newSafetyHuddle.watcherPatients === undefined ? null : newSafetyHuddle.watcherPatients,
            amountOfSeverePatientInTheUnit: newSafetyHuddle.amountOfSeverePatientInTheUnit === null || newSafetyHuddle.amountOfSeverePatientInTheUnit === undefined ? null : newSafetyHuddle.amountOfSeverePatientInTheUnit || 0,
            newtherapiesimplementedInTheUti: newSafetyHuddle.newtherapiesimplementedInTheUti === null || newSafetyHuddle.newtherapiesimplementedInTheUti === undefined ? null : newSafetyHuddle.newtherapiesimplementedInTheUti,
            amountOfDischargedPatientsThatDidnNotLeave: newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave === null || newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave === undefined ? null : newSafetyHuddle.amountOfDischargedPatientsThatDidnNotLeave || 0,
            amountOfPatientsWithOrganDysfunction24Hours: newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours === null || newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours === undefined ? null : newSafetyHuddle.amountOfPatientsWithOrganDysfunction24Hours || 0,
            amountOfInvasiveProceduresThatDidNotHappenYesterday: newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday === null || newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday === undefined ? null : newSafetyHuddle.amountOfInvasiveProceduresThatDidNotHappenYesterday || 0,
            amountOfCommunicationProblemsWithyFamilyOrUti: newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti === null || newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti === undefined ? null : newSafetyHuddle.amountOfCommunicationProblemsWithyFamilyOrUti || 0,
            amountOfImagingOrLabTestsThatDidNotHappen: newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen === null || newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen === undefined ? null : newSafetyHuddle.amountOfImagingOrLabTestsThatDidNotHappen || 0,
            amountOfAdverseEventsInTheLast24Hours: newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours === null || newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours === undefined ? null : newSafetyHuddle.amountOfAdverseEventsInTheLast24Hours || 0,
            howToImproveTheQualityOfPatient: newSafetyHuddle.howToImproveTheQualityOfPatient,
        },
    };
    const ret = await makeRequest({name: "editSafetyHuddle", args, progress});
    return {
        id: ret.id,
        creator: {
            id: ret.creator.id,
            createdAt: new Date(ret.creator.createdAt + "Z"),
            adminType: ret.creator.adminType,
            name: ret.creator.name,
            email: ret.creator.email,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        date: new Date(ret.date + "Z"),
        positionDayLaborer: ret.positionDayLaborer,
        positionNurses: ret.positionNurses,
        positionPsychologist: ret.positionPsychologist,
        positionOnDuty: ret.positionOnDuty,
        positionPhysicalTherapists: ret.positionPhysicalTherapists,
        positionPharmacy: ret.positionPharmacy,
        positionTechnical: ret.positionTechnical,
        positionAdm: ret.positionAdm,
        positionOthers: ret.positionOthers,
        amountOfwatcherPatientsOnTheUnit: ret.amountOfwatcherPatientsOnTheUnit === null || ret.amountOfwatcherPatientsOnTheUnit === undefined ? null : ret.amountOfwatcherPatientsOnTheUnit || 0,
        watcherPatients: ret.watcherPatients === null || ret.watcherPatients === undefined ? null : ret.watcherPatients,
        amountOfSeverePatientInTheUnit: ret.amountOfSeverePatientInTheUnit === null || ret.amountOfSeverePatientInTheUnit === undefined ? null : ret.amountOfSeverePatientInTheUnit || 0,
        newtherapiesimplementedInTheUti: ret.newtherapiesimplementedInTheUti === null || ret.newtherapiesimplementedInTheUti === undefined ? null : ret.newtherapiesimplementedInTheUti,
        amountOfDischargedPatientsThatDidnNotLeave: ret.amountOfDischargedPatientsThatDidnNotLeave === null || ret.amountOfDischargedPatientsThatDidnNotLeave === undefined ? null : ret.amountOfDischargedPatientsThatDidnNotLeave || 0,
        amountOfPatientsWithOrganDysfunction24Hours: ret.amountOfPatientsWithOrganDysfunction24Hours === null || ret.amountOfPatientsWithOrganDysfunction24Hours === undefined ? null : ret.amountOfPatientsWithOrganDysfunction24Hours || 0,
        amountOfInvasiveProceduresThatDidNotHappenYesterday: ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === null || ret.amountOfInvasiveProceduresThatDidNotHappenYesterday === undefined ? null : ret.amountOfInvasiveProceduresThatDidNotHappenYesterday || 0,
        amountOfCommunicationProblemsWithyFamilyOrUti: ret.amountOfCommunicationProblemsWithyFamilyOrUti === null || ret.amountOfCommunicationProblemsWithyFamilyOrUti === undefined ? null : ret.amountOfCommunicationProblemsWithyFamilyOrUti || 0,
        amountOfImagingOrLabTestsThatDidNotHappen: ret.amountOfImagingOrLabTestsThatDidNotHappen === null || ret.amountOfImagingOrLabTestsThatDidNotHappen === undefined ? null : ret.amountOfImagingOrLabTestsThatDidNotHappen || 0,
        amountOfAdverseEventsInTheLast24Hours: ret.amountOfAdverseEventsInTheLast24Hours === null || ret.amountOfAdverseEventsInTheLast24Hours === undefined ? null : ret.amountOfAdverseEventsInTheLast24Hours || 0,
        howToImproveTheQualityOfPatient: ret.howToImproveTheQualityOfPatient,
    };
}

export async function exportCsvSafetyHuddle(filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : (typeof filterOptions.startDate === "string" && (filterOptions.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.startDate as any).replace("Z", "") : filterOptions.startDate.toISOString().replace("Z", "")),
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : (typeof filterOptions.endDate === "string" && (filterOptions.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterOptions.endDate as any).replace("Z", "") : filterOptions.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "exportCsvSafetyHuddle", args, progress});
    return ret;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
