import React from "react";
import { Store } from "./store";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CentralizedCard, DetailsRow } from "~/components";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const buttonBack = strings.actions.back;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();
	const onGoBack = () => history.goBack();

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<>
				<CentralizedCard
					title={{
						text: commonStrings.detailsTitle,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value &&
							<>
								<DetailsRow
									label={commonStrings.fields.id}
									value={store.fetchModelShelf.model.value.id}
								/>
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.model.value.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.fetchModelShelf.model.value.email}
								/>
								<DetailsRow
									label={commonStrings.fields.function}
									value={api.translateAdminType(store.fetchModelShelf.model.value.adminType)}
								/>
							</>
					}
				</CentralizedCard>
				<Button
					onClick={() => onGoBack()}
					w={{
						base: "50%",
						md: "15%",
					}}
					mb={5}
					variant="outline"
					leftIcon={
						<ArrowBackIcon />
					}
				>
					{buttonBack}
				</Button>
			</>
		</Flex>
	);
};

export default observer(Details);
